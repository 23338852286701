import { AnyAction } from "redux";
import { EmployeeNFT } from "../../../core/nfts/employee";
import { FactoryNFT } from "../../../core/nfts/factories";
import { AppActions, AppReducer } from "../../types";

export const relationsGameKeyKey = AppReducer.RELATIONS_GFAME;

export interface RelationsGameState {
  selectedFactory: FactoryNFT | undefined;
  employeeSelection: EmployeeNFT[];
}

export const initialState: RelationsGameState = {
  selectedFactory: undefined,
  employeeSelection: [],
};

export const relationsGameReducer = (
  state: RelationsGameState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AppActions.GAME_CHANGE_SELECTED_FACTORY:
      return {
        ...state,
        selectedFactory: action.factory,
      };
    case AppActions.GAME_REMOVE_EMPLOYEE_FROM_SELECTION:
      let selection = [...state.employeeSelection];

      for (let i = 0; i < selection.length; i++) {
        if (
          selection[i].id === (action.employee as EmployeeNFT).id &&
          selection[i].isMultiEmployee ===
            (action.employee as EmployeeNFT).isMultiEmployee
        ) {
          selection.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        employeeSelection: selection,
      };
    case AppActions.GAME_RESET_EMPLOYEE_SELECTION:
      return {
        ...state,
        employeeSelection: [],
      };
    case AppActions.GAME_ADD_EMPLOYEE_TO_SELECTION:
      return {
        ...state,
        employeeSelection: [
          ...state.employeeSelection,
          action.employee as EmployeeNFT,
        ],
      };
    default:
      return state;
  }
};
