import { AppErrorCode } from "../app";
import { CitiesDeployer } from "../contracts/citiesDeployer";
import { UtilsHelpers } from "../helpers/utils";

export class CityDeployerController {
  data: {} = {};
  universityPrice: number = 100000;
  townHallPrice: number = 100000;

  constructor(
    public contract: CitiesDeployer // public multiEmployees: MultiEmployeeDeployer
  ) {}

  public mintCity(
    name: string,
    factories: number[],
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Mint city");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger(
        "Try Mint NFT (" + this.contract.selectedAccount + ")"
      );

      if (callback) this.contract.mintCity(name, factories, callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }

  public addFactoryPoints(
    city: number,
    factories: number[],
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Mint city");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger(
        "Try add factory points NFT (" + this.contract.selectedAccount + ")"
      );

      if (callback) this.contract.addFactoryPoints(city, factories, callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }

  public addUniversity(
    city: number,
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Add university");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger(
        "Try to add university (" + this.contract.selectedAccount + ")"
      );

      if (callback) this.contract.addUniversity(city, callback);
    } else {
      UtilsHelpers.debugger("Invalid University addition.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }

  public addTownHall(
    city: number,
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Add townhall");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger(
        "Try to add townhall (" + this.contract.selectedAccount + ")"
      );

      if (callback) this.contract.addTownHall(city, callback);
    } else {
      UtilsHelpers.debugger("Invalid TownHall addition.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }

  public changeName(
    city: number,
    name: number,
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Add townhall");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger(
        "Try to add townhall (" + this.contract.selectedAccount + ")"
      );

      if (callback) this.contract.addTownHall(city, callback);
    } else {
      UtilsHelpers.debugger("Invalid TownHall addition.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }
}
