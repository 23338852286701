import React from "react";
import { AppErrorCode } from "../../core/app";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { UtilsHelpers } from "../../core/helpers/utils";
import { CityDeployerController } from "../../core/modules/citiesDeployer";
import { CitiesController } from "../../core/modules/cities";
import { CityNFT } from "../../core/nfts/cities";
import { BuilderCitiesGrid } from "../organisms/builder/cities/grid";
import { FactoryNFT } from "../../core/nfts/factories";
import { FactoriesController } from "../../core/modules/factories";
import { BuilderFactoriesGrid } from "../organisms/builder/factories/grid";
import Web3 from "web3";
import { TeamLeader } from "../organisms/teamLeader";

interface UpgradeCitiesComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface UpgradeCitiesComponentState {
  cityDeployer: null | CityDeployerController;
  cities: null | CitiesController;
  factories: null | FactoriesController;
  citiesLoader: boolean;
  factoriesLoader: boolean;
  pageCities: number;
  pageFactories: number;
  approvedTokens: number;
  selectedCity: CityNFT[];
  selectedFactories: FactoryNFT[];
  cityName: string;
}

export class UpgradeCitiesPage extends React.PureComponent<
  UpgradeCitiesComponentProps,
  UpgradeCitiesComponentState
> {
  constructor(props: UpgradeCitiesComponentProps) {
    super(props);

    this.state = {
      cities: null,
      factories: null,
      cityDeployer: null,
      citiesLoader: false,
      factoriesLoader: false,
      pageCities: 0,
      pageFactories: 0,
      selectedCity: [],
      approvedTokens: 0,
      selectedFactories: [],
      cityName: "",
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetCitiesController();
    this.loadAndSetFactoriesController();
    this.props.onToggleLoader(false);
  }

  async preloadControllers(closeLoader: boolean = false) {
    const cityDeployer = await this.preloadCityDeployerController();
    let approvedTokens = 0;

    if (
      this.props.blockChain.controller?.token &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.blockChain.controller.citiesDeployer
    ) {
      approvedTokens = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token.allowance(
            this.props.blockChain.controller.selectedAccount,
            this.props.blockChain.controller.citiesDeployer?.address
          ),
          "ether"
        )
      );
    }

    this.setState(
      {
        cityDeployer,
        approvedTokens,
        citiesLoader: false,
      },
      () => {
        if (closeLoader) this.props.onToggleLoader(false);
      }
    );
  }

  async preloadCitiesController() {
    let cities = null;

    UtilsHelpers.debugger("Preload cities controller.");

    if (
      this.props.blockChain.controller?.cities &&
      this.props.blockChain.controller?.cityRelationsStorage &&
      this.props.appState.appData
    ) {
      cities = new CitiesController(
        this.props.blockChain.controller.cities,
        this.props.blockChain.controller.cityRelationsStorage,
        this.props.appState.appData
      );

      await cities.loadCitiesData();
    }

    UtilsHelpers.debugger("Finish Preload cities controller.");

    return cities;
  }

  async preloadFactoriesController() {
    let factories = null;

    UtilsHelpers.debugger("Preload factories controller.");

    if (
      this.props.blockChain.controller?.factories &&
      this.props.blockChain.controller?.cityRelationsStorage &&
      this.props.appState.appData
    ) {
      factories = new FactoriesController(
        this.props.blockChain.controller.factories,
        this.props.blockChain.controller.cityRelationsStorage,
        this.props.appState.appData
      );

      await factories.loadFactoriesData();
    }

    UtilsHelpers.debugger("Finish Preload factories controller.");

    return factories;
  }

  async preloadCityDeployerController() {
    let deployer = null;

    UtilsHelpers.debugger("Preload deployer controller.");

    if (
      this.props.blockChain.controller?.cities &&
      this.props.blockChain.controller?.citiesDeployer &&
      this.props.appState.appData
    ) {
      deployer = new CityDeployerController(
        this.props.blockChain.controller.citiesDeployer
      );
    }

    UtilsHelpers.debugger("Finish Preload deployer controller.");

    return deployer;
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    await this.preloadControllers();
    this.loadAndSetCitiesController();
    this.loadAndSetFactoriesController();
    this.props.onLoadCustomerData(true);
  }

  async loadAndSetCitiesController() {
    this.setState({ citiesLoader: true }, async () => {
      const cities = await this.preloadCitiesController();
      this.setState({ cities, citiesLoader: false });
    });
  }

  async loadAndSetFactoriesController() {
    this.setState({ factoriesLoader: true }, async () => {
      const factories = await this.preloadFactoriesController();
      this.setState({ factories, factoriesLoader: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? (
          <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} />
        ) : (
          ""
        )}
        <div className="ct-max-container ct-builder-page">
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>CITY UPGRADING</h1>
                <small>Use your factories to upgrade your city</small>
                {this.state.selectedCity.length >= 1 ? (
                  <React.Fragment>
                    <h4 className="ct-mt-10">City information</h4>
                    <small>Name: {this.state.selectedCity[0].name}</small>
                    <small>Points: {this.state.selectedCity[0].points}</small>
                    <small>Lands: {this.state.selectedCity[0].lands}</small>
                    <small>
                      Has University:{" "}
                      {(!!this.state.selectedCity[0].university).toString()}
                    </small>
                    <small>
                      Has Town Hall:{" "}
                      {(!!this.state.selectedCity[0].townHall).toString()}
                    </small>
                    <h4 className="ct-mt-10">Next city Information</h4>
                    <small>
                      Points:{" "}
                      {this.state.selectedCity[0].points +
                        (!!this.state.selectedCity[1]
                          ? this.state.selectedCity[1].points
                          : 0) +
                        this.state.selectedFactories.reduce(
                          (points, nft) => (points += nft.points),
                          0
                        )}
                    </small>
                    <small>
                      Name:{" "}
                      {!!this.state.cityName
                        ? this.state.cityName
                        : this.state.selectedCity[0].name}
                    </small>
                    <small>
                      Lands:{" "}
                      {Math.floor(
                        (this.state.selectedCity[0].points +
                          this.state.selectedFactories.reduce(
                            (points, nft) => (points += nft.points),
                            0
                          )) /
                          20
                      )}
                    </small>
                    <small>
                      <strong>Cities merging: </strong>
                      If you want to merge your cities the second city will be
                      burned and all points will be added to the first city.
                      <strong>
                        Base city: {this.state.selectedCity[0].name}
                      </strong>{" "}
                      will get all points.{" "}
                      <strong>
                        Burned city: {this.state.selectedCity[1]?.name} will be
                        burned
                      </strong>
                    </small>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <a
                  href="https://docs.businessbuilders.city/the-game/cities/minting"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cities documentation
                </a>
              </div>
              <div className="ct-actions">
                {this.state.selectedCity.length === 1 &&
                this.state.selectedFactories.length > 0 ? (
                  <button
                    onClick={() => {
                      if (
                        this.state.selectedCity &&
                        this.state.selectedFactories.length > 0
                      ) {
                        this.state.cityDeployer?.addFactoryPoints(
                          this.state.selectedCity[0].id,
                          this.state.selectedFactories.map(
                            (factory) => factory.id
                          ),
                          (error: AppErrorCode | null) => {
                            this.setState({ selectedCity: [] }, () => {
                              this.onUpdateData(error);
                            });
                          }
                        );
                      }
                    }}
                    className="ct-main-button"
                  >
                    Use factories
                  </button>
                ) : (
                  ""
                )}
                {this.state.selectedCity.length === 1 &&
                this.state.cityDeployer ? (
                  <React.Fragment>
                    {" "}
                    <button
                      className="ct-main-button"
                      disabled={!!this.state.selectedCity[0].university}
                      onClick={() => {
                        if (
                          this.state.selectedCity &&
                          this.state.cityDeployer
                        ) {
                          if (
                            this.state.approvedTokens >=
                            this.state.cityDeployer.universityPrice
                          ) {
                            this.state.cityDeployer?.addUniversity(
                              this.state.selectedCity[0].id,
                              (error: AppErrorCode | null) => {
                                this.onUpdateData(error);
                              }
                            );
                          } else {
                            this.props.blockChain.controller?.token?.approve(
                              this.state.cityDeployer.contract.address,
                              this.state.cityDeployer.universityPrice,
                              (error: AppErrorCode | null) => {
                                this.onUpdateData(error);
                              }
                            );
                          }
                        }
                      }}
                    >
                      {this.state.approvedTokens >=
                      this.state.cityDeployer?.universityPrice
                        ? "Add"
                        : "Approve"}{" "}
                      University <br /> (
                      {this.state.cityDeployer?.universityPrice} FTB)
                    </button>
                    <button className="ct-main-button" disabled>
                      {this.state.approvedTokens >=
                      this.state.cityDeployer?.townHallPrice
                        ? "Add"
                        : "Approve"}{" "}
                      TownHall <br /> ({this.state.cityDeployer?.townHallPrice}{" "}
                      FTB)
                    </button>
                    <input
                      className="ct-city-name"
                      type="text"
                      placeholder="City name"
                      value={this.state.cityName}
                      onChange={(e) =>
                        this.setState({
                          cityName: e.target.value.toUpperCase(),
                        })
                      }
                    />
                    <button
                      className="ct-main-button"
                      onClick={() => {
                        if (this.props.blockChain.controller?.citiesDeployer) {
                          this.props.blockChain.controller.cities?.changeName(
                            this.state.selectedCity[0].id,
                            this.state.cityName,
                            (error) => {
                              this.onUpdateData(error);
                            }
                          );
                        }
                      }}
                    >
                      Change city name
                    </button>
                  </React.Fragment>
                ) : (
                  ""
                )}
                {this.state.selectedCity.length === 2 &&
                this.state.cityDeployer ? (
                  <React.Fragment>
                    <button
                      className="ct-main-button"
                      onClick={() => {
                        if (
                          this.props.blockChain.controller?.citiesDeployer &&
                          this.state.selectedCity[0] &&
                          this.state.selectedCity[1]
                        ) {
                          this.props.blockChain.controller?.citiesDeployer?.mergeCities(
                            this.state.selectedCity[0].id,
                            this.state.selectedCity[1].id,
                            (error: AppErrorCode | null) => {
                              this.setState({ selectedCity: [] }, () => {
                                this.onUpdateData(error);
                              });
                            }
                          );
                        }
                      }}
                    >
                      Merge cities
                    </button>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="ct-cities">
            {this.state.cities && this.props.appState.appData ? (
              <BuilderCitiesGrid
                nftsPerPage={8}
                validateRelations={this.state.selectedCity.length >= 1}
                citiesLoader={this.state.citiesLoader}
                cities={this.state.cities}
                appData={this.props.appState.appData}
                page={this.state.pageCities}
                title="Cities (NFT)"
                subtitle={
                  "Selected cities: " + (this.state.selectedCity ? "1" : "0")
                }
                selection={this.state.selectedCity}
                onChangePage={(page: number) =>
                  this.setState({ pageCities: page })
                }
                onClick={(city) => {
                  console.log(city);
                  const searchCity = this.state.selectedCity.findIndex(
                    (nft) => nft.id === city.id
                  );

                  if (searchCity !== -1) {
                    const newState = [...this.state.selectedCity];
                    newState.splice(searchCity, 1);
                    this.setState({ selectedCity: newState });
                  } else {
                    if (this.state.selectedCity.length < 2) {
                      const newState = [...this.state.selectedCity];
                      newState.push(city);
                      this.setState({ selectedCity: newState });
                    }
                  }
                }}
                onUpdate={() => this.loadAndSetCitiesController()}
              />
            ) : (
              ""
            )}
          </div>
          <div className="ct-factories">
            {this.state.factories && this.props.appState.appData ? (
              <BuilderFactoriesGrid
                nftsPerPage={8}
                factoriesLoader={this.state.factoriesLoader}
                factories={this.state.factories}
                appData={this.props.appState.appData}
                page={this.state.pageFactories}
                gameSelection={this.state.selectedFactories}
                onUpdate={() => this.onUpdateData(null)}
                onClick={async (nft: FactoryNFT) => {
                  const hasRelation =
                    await this.props.blockChain.controller?.cityRelationsStorage?.getFactoryState(
                      nft.id
                    );

                  if (!hasRelation) {
                    const searchedFactory =
                      this.state.selectedFactories.findIndex(
                        (factory) => factory.id === nft.id
                      );

                    const newState = [...this.state.selectedFactories];

                    if (searchedFactory === -1) {
                      newState.push(nft);
                      this.setState({ selectedFactories: newState });
                    } else {
                      newState.splice(searchedFactory, 1);
                      this.setState({ selectedFactories: newState });
                    }
                  }
                }}
                onChangeFactoriesPage={(page: number) =>
                  this.setState({ pageFactories: page })
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
