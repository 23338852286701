import { ContractBaseModel } from "../abstracts/contract";
import { appConfig, AppErrorCode } from "../app";
import { AbstractFactoryDeployer } from "../abstracts/factoryDeployer";

export class FactoryDeployer extends ContractBaseModel<AbstractFactoryDeployer> {
  getFactoriesData() {
    return this.contract.getFactoriesData().call(this.config);
  }

  mintFactory(
    employee: number[],
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mintFactory(employee)
      .send({ ...this.config, gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei") })
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  addEmployeeToFactory(
    factory: number,
    employee: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .addEmployeeToFactory(factory, employee)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(
          AppErrorCode.ADD_EMPLOYEE_TO_FACTORY,
          receipt,
          callback
        )
      )
      .on("error", (error: any) =>
        this.errorCallback(
          AppErrorCode.ADD_EMPLOYEE_TO_FACTORY,
          error,
          callback
        )
      );
  }

  isInWhitelist() {
    return this.contract.isInWhitelist(this.selectedAccount).call();
  }
}
