import Web3 from "web3";
import { NavWindow } from "../../components/types";
import { AppChain, appConfig, AppMode, ChainData, posibleChains } from "../app";
import { UtilsHelpers } from "./utils";

export class BlockChainHelpers {
  static async loadWeb3(): Promise<boolean> {
    return new Promise((res) => {
      if ((window as NavWindow).ethereum) {
        if (
          (window as NavWindow).coin98 &&
          (window as NavWindow).ethereum &&
          (window as NavWindow).ethereum?.isCoin98
        ) {
          console.log("Coin98 Extension is installed!");
        }

        if (
          (window as NavWindow).ethereum &&
          (window as NavWindow).ethereum?.isMetamask
        ) {
          console.log("Metamask Extension is installed!");
        }

        (window as NavWindow).web3 = new Web3((window as NavWindow).ethereum);

        try {
          (window as NavWindow).ethereum
            .request({
              method: "eth_requestAccounts",
            })
            .then((accounts: any) => {
              if (
                accounts.length > 0 &&
                (window as NavWindow).ethereum.isConnected()
              ) {
                res(true);
              } else {
                throw new Error("BLOCK CHAIN] Cannot connect to provider");
              }
            })
            .catch(() => res(false));
        } catch (error) {
          console.error("[BLOCK CHAIN] Principal error");
          res(false);
        }
      } else {
        console.error(
          "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );

        res(false);
      }
    });
  }

  static getMainTokenBalance(address: string): Promise<string> | undefined {
    return BlockChainHelpers.getProvider()?.eth.getBalance(address);
  }

  static getProvider(): Web3 | null {
    return (window as NavWindow).web3
      ? ((window as NavWindow).web3 as Web3)
      : null;
  }

  static async addEthereumChain(chainData: ChainData) {
    await (window as NavWindow).ethereum.request({
      method: "wallet_addEthereumChain",
      params: [chainData],
    });
  }

  static getEthWindow() {
    return (window as NavWindow).ethereum
      ? (window as NavWindow).ethereum
      : null;
  }

  static async changeEthereumChain(chainId: string) {
    if (chainId) {
      return await (window as NavWindow).ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });
    } else return false;
  }

  static getAppChain(): ChainData {
    let chain: ChainData = posibleChains[AppChain.GANACHE];
    switch (appConfig.mode) {
      case AppMode.TEST:
        chain = posibleChains[AppChain.BSCT];
        break;
      case AppMode.TEST_METER:
        chain = posibleChains[AppChain.METER_TEST];
        break;
      case AppMode.TEST_POLYGON:
        chain = posibleChains[AppChain.POLYGON_TEST];
        break;
      case AppMode.POLYGON:
        chain = posibleChains[AppChain.POLYGON];
        break;
      case AppMode.METER:
        chain = posibleChains[AppChain.METER];
        break;
      case AppMode.PRO:
        chain = posibleChains[AppChain.BSC];
        break;
      case AppMode.DEV:
        chain = posibleChains[AppChain.GANACHE];
        break;
      default:
        chain = posibleChains[AppChain.GANACHE];
        break;
    }
    return chain;
  }

  static async reloadOrChangeNetwork() {
    try {
      await BlockChainHelpers.changeEthereumChain(
        BlockChainHelpers.getAppChain().chainId
      );
    } catch (e) {
      let error: any = e;
      if (error?.code === 4902) {
        try {
          await BlockChainHelpers.addEthereumChain(
            BlockChainHelpers.getAppChain()
          );
        } catch (err) {
          UtilsHelpers.debugger("You can't change your network.");
        }
      }
    }
  }

  static async validateBlockChain(callback: (correct: boolean) => void) {
    const selectedNetwork = (
      await this.getProvider()?.eth.net.getId()
    )?.toString(16);

    callback(this.getAppChain().chainId === "0x" + selectedNetwork);
  }

  static parseTokenNumber(money: number): number {
    return money ? money / Math.pow(10, 18) : 0;
  }

  static async getProviderGasFee() {
    return BlockChainHelpers.getProvider()?.eth.getGasPrice();
  }
}
