import { AnyAction } from "redux";
import { AppActions, AppReducer } from "../../types";
import { Tutorial } from "./types";

export const tutorialsKey = AppReducer.TUTORIALS;

export interface TutorialsState {
  selectedTutorial: Tutorial | undefined;
  tutorialStep: number;
}

export const initialState: TutorialsState = {
  selectedTutorial: undefined,
  tutorialStep: 0,
};

export const tutorialsReducer = (
  state: TutorialsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AppActions.CHANGE_TUTORIAL:
      return {
        ...state,
        selectedTutorial: action.tutorial as Tutorial,
        tutorialStep: 0,
      } as TutorialsState;
    case AppActions.CHANGE_TUTORIAL_STEP:
      return {
        ...state,
        selectedTutorial: action.tutorial as Tutorial,
        tutorialStep: action.step,
      } as TutorialsState;
    default:
      return state;
  }
};
