import React from "react";
import { EmployeeNFT } from "../../core/nfts/employee";
import { AppErrorCode, Contract } from "../../core/app";
import { BuilderActions } from "../organisms/builder/employees/actions";
import { BuilderEmployeesGrid } from "../organisms/builder/employees/grid";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { EmployeeStakingController } from "../../core/modules/employeeStaking";
import { MarketplaceController } from "../../core/modules/marketplace";
import { EmployeeDeployerController } from "../../core/modules/employeeDeployer";
import { TokenController } from "../../core/modules/token";
import { EmployeesController } from "../../core/modules/employees";
import { UtilsHelpers } from "../../core/helpers/utils";
import Web3 from "web3";
import { TeamLeader } from "../organisms/teamLeader";
import { MultiEmployeeDeployerController } from "../../core/modules/multiEmployeeDeployer";

interface BuilderComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onLoadBlockChain: () => void;
  onToggleLoader: (froce: boolean) => void;
  onChangeEmployeesPagination: (page: number) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface BuilderComponentState {
  employeeStaking: null | EmployeeStakingController;
  marketplace: null | MarketplaceController;
  employeeDeployer: null | EmployeeDeployerController;
  multiEmployeesDeployer: MultiEmployeeDeployerController | null;
  employees: null | EmployeesController;
  token: null | TokenController;
  openEmployeeNFTModal: boolean;
  employeeNewNFTs: EmployeeNFT[];
  employeesLoader: boolean;
  employeeDeployerAllowance: number;
}

export class BuilderPage extends React.PureComponent<BuilderComponentProps, BuilderComponentState> {
  constructor(props: BuilderComponentProps) {
    super(props);

    this.state = {
      employeeStaking: null,
      employeeDeployer: null,
      token: null,
      marketplace: null,
      employees: null,
      openEmployeeNFTModal: false,
      employeeNewNFTs: [],
      employeesLoader: false,
      employeeDeployerAllowance: 0,
      multiEmployeesDeployer: null,
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetEmployeesController();
  }

  async preloadControllers() {
    let employeeDeployer = null;
    let token = null;
    let employeeDeployerAllowance = 0;
    let multiEmployeesDeployer = null;

    if (this.props.blockChain.controller?.employeeDeployer && this.props.blockChain.controller.multiEmployeesDeployer) {
      employeeDeployer = new EmployeeDeployerController(this.props.blockChain.controller.employeeDeployer);
      multiEmployeesDeployer = new MultiEmployeeDeployerController(this.props.blockChain.controller.multiEmployeesDeployer);
      await multiEmployeesDeployer.loadDeployerData();
    }

    if (
      this.props.blockChain.controller?.token &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.blockChain.controller.employeeDeployer
    ) {
      token = new TokenController(this.props.blockChain.controller.token);

      employeeDeployerAllowance = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token.allowance(
            this.props.blockChain.controller.selectedAccount,
            this.props.blockChain.controller.employeeDeployer?.address
          ),
          "ether"
        )
      );
    }

    this.setState(
      {
        employeeDeployer,
        token,
        employeesLoader: false,
        employeeDeployerAllowance,
        multiEmployeesDeployer,
      },
      () => this.props.onToggleLoader(false)
    );
  }

  async preloadEmployeeController() {
    let employees = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.appState.appData
    ) {
      employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employees.loadFullEmployeesData();
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return employees;
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.props.onLoadCustomerData(true);
    this.preloadControllers();
  }

  resetModals() {
    this.setState({
      employeeNewNFTs: [],
      openEmployeeNFTModal: false,
    });
  }

  async loadAndSetEmployeesController() {
    this.setState({ employeesLoader: true }, async () => {
      this.setState({ employees: await this.preloadEmployeeController(), employeesLoader: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} /> : ""}
        <div className="ct-max-container ct-builder-page">
          <div className="ct-employees">
            {this.props.blockChain.customer && this.props.appState.appData ? (
              <BuilderActions
                appData={this.props.appState.appData}
                employeeDeployerAllowance={this.state.employeeDeployerAllowance}
                onMint={(type: number) => {
                  if (this.props.blockChain.controller) {
                    this.resetModals();
                    this.props.onToggleLoader(true);
                    this.state.employeeDeployer?.mintEmployee(type, false, (error: AppErrorCode | null) => {
                      this.onUpdateData(error);
                    });
                  }
                }}
                onApprove={() => {
                  if (this.props.appState.appData && this.state.token) {
                    this.resetModals();
                    this.props.onToggleLoader(true);
                    this.state.token.approveTokenSpend(
                      this.props.appState.appData.contractsAddress[Contract.EMPLOYEE_DEPLOYER],
                      this.props.appState.appData?.employeesData.employeePrice,
                      (error: AppErrorCode | null) => {
                        this.onUpdateData(error);
                      }
                    );
                  }
                }}
              />
            ) : (
              ""
            )}

            {this.state.employees && this.props.appState.appData && this.state.token ? (
              <BuilderEmployeesGrid
                nftsPerPage={8}
                employeesLoader={this.state.employeesLoader}
                token={this.state.token}
                employees={this.state.employees}
                appData={this.props.appState.appData}
                page={this.props.appState.employeesPagination}
                withoutMultiEmployees
                onUpdate={() => this.loadAndSetEmployeesController()}
                onChangeEmployeesPage={(page: number) => this.props.onChangeEmployeesPagination(page)}
                onApprove={(address: string, amount: number) => {
                  if (this.props.blockChain.controller && this.props.appState.appData?.employeesData) {
                    this.resetModals();
                    this.props.onToggleLoader(true);
                    this.state.token?.approveTokenSpend(address, amount, (error: AppErrorCode | null) => {
                      this.onUpdateData(error);
                    });
                  }
                }}
              />
            ) : (
              ""
            )}

            {this.state.employees && this.props.appState.appData && this.state.token ? (
              <BuilderEmployeesGrid
                nftsPerPage={8}
                employeesLoader={this.state.employeesLoader}
                token={this.state.token}
                employees={this.state.employees}
                appData={this.props.appState.appData}
                page={this.props.appState.employeesPagination}
                onUpdate={() => this.loadAndSetEmployeesController()}
                onlyMultiEmployees
                subtitle={"Total multi employee points: " + this.state.multiEmployeesDeployer?.data?.points + " / 20"}
                actions={[
                  {
                    onClick: () => {
                      if (this.props.blockChain.controller) {
                        this.props.blockChain.controller.multiEmployeesDeployer?.randomize((error) => {
                          this.onUpdateData(error);
                        });
                      }
                    },
                    icon: "fas fa-random",
                  },
                ]}
                onChangeEmployeesPage={(page: number) => this.props.onChangeEmployeesPagination(page)}
                onApprove={(address: string, amount: number) => {
                  if (this.props.blockChain.controller && this.props.appState.appData?.employeesData) {
                    this.resetModals();
                    this.props.onToggleLoader(true);
                    this.state.token?.approveTokenSpend(address, amount, (error: AppErrorCode | null) => {
                      this.onUpdateData(error);
                    });
                  }
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
