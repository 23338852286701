import { AnyAction } from "redux";
import { EmployeeNFT } from "../../../core/nfts/employee";
import { AppActions, AppReducer } from "../../types";

export const studentsGameKeyKey = AppReducer.STUDENTS_GAME;

export interface StudentsGameState {
  selectedEmployee: EmployeeNFT[];
}

export const initialState: StudentsGameState = {
  selectedEmployee: [],
};

export const studentsGameReducer = (
  state: StudentsGameState = initialState,
  action: AnyAction
) => {
  let searchEmployee, newState;

  switch (action.type) {
    case AppActions.STUDENTS_CHANGE_SELECTED_EMPLOYEE:
      searchEmployee = state.selectedEmployee.find(
        (employee) => employee.id === action.employee.id
      );

      newState = { ...state };

      if (!searchEmployee) newState.selectedEmployee.push(action.employee);

      return newState;
    case AppActions.STUDENTS_REMOVE_SELECTED_EMPLOYEE:
      searchEmployee = state.selectedEmployee.findIndex(
        (employee) => employee.id === action.employee.id
      );

      newState = { ...state };

      if (searchEmployee !== -1) {
        newState.selectedEmployee.splice(searchEmployee, 1);
      }

      return newState;
    case AppActions.STUDENTS_CLEAR_SELECTED_EMPLOYEE:
      return { ...state, selectedEmployee: [] };
    default:
      return state;
  }
};
