import React from "react";
import Web3 from "web3";
import { appConfig, AppErrorCode } from "../../../core/app";
import { BlockChain } from "../../../core/chain";
import { BlockChainState } from "../../../storage/state/blockChain/state";

export interface FactoryRelationProps {
  relation: any;
  blockChain: BlockChainState;
  onUpdateData: (error: AppErrorCode | null) => void;
}

export class FactoryRelation extends React.Component<FactoryRelationProps> {
  render() {
    return (
      <div className="ct-event">
        <div className="ct-info">
          <p>
            <strong>City: </strong> {this.props.relation._cityName}
          </p>
          <p>
            <strong>Factory: </strong> {this.props.relation._factory}
          </p>
          <p>
            <strong>Type: </strong> {this.props.relation._factoryType}
          </p>
          <p>
            <strong>Payment: </strong>{" "}
            {this.props.relation?.addition
              ? Number(Web3.utils.fromWei(this.props.relation.addition.entryPayment, "ether")).toFixed(2) + " FTB"
              : Number(this.props.relation._payment).toFixed(2)}{" "}
            FTB
          </p>
          <p>
            <strong>Total Employees: </strong> {this.props.relation._totalEmployees}
          </p>
          <p>
            <strong>Points: </strong> {this.props.relation._points}
          </p>
          <p>
            <strong>Percentage: </strong>{" "}
            {this.props.relation?.addition ? this.props.relation.addition.relationPercentage : this.props.relation._percentage}%
          </p>
          <p className="ct-mt-10">
            <strong>Rewards</strong>
          </p>
          {this.props.relation.fees ? (
            <p>
              <strong>City fees: </strong> {this.props.relation.fees.city}%
            </p>
          ) : (
            ""
          )}
          {this.props.relation.fees ? (
            <p>
              <strong>Factory fees: </strong> {this.props.relation.fees.factory}%
            </p>
          ) : (
            ""
          )}
          {this.props.relation.relationRewards ? (
            <p>
              <strong>Rewards: </strong> {Number(Web3.utils.fromWei(this.props.relation.relationRewards)).toFixed(2)} FTB
            </p>
          ) : (
            ""
          )}
          {this.props.relation.relationTime ? (
            <p>
              <strong>Time to get all rewards: </strong>{" "}
              {((this.props.relation.timeToGetAll - (new Date().getTime() / 1000 - this.props.relation.relationTime)) / 60 / 60).toFixed(0)} Hours
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="ct-actions">
          <a
            href={appConfig.universeUrl + "?world=82&city=" + this.props.relation._city + "&factory=" + this.props.relation._factory}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fas fa-plane"></span>
          </a>
          <button
            onClick={() => {
              if (this.props.blockChain.controller) {
                this.props.blockChain.controller.cityRelations?.withdrawFromFactoryRelation(
                  this.props.relation._city,
                  this.props.relation._factory,
                  (error) => this.props.onUpdateData(error)
                );
              }
            }}
            className="ct-main-button"
          >
            <span>withdraw rewards</span>
          </button>
        </div>
      </div>
    );
  }
}
