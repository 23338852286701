import { AppErrorCode } from "../../../core/app";
import { BlockChain } from "../../../core/chain";
import { Customer } from "../../../core/customer";
import { BlockChainHelpers } from "../../../core/helpers/chain";

import "./main.styles.css";

export interface ErrorComponentProps {
  code: AppErrorCode;
  blockChain: BlockChain | null;
  customer: Customer | null;
  onApproveTokenSpend?: () => void;
  onChangeNetwork?: () => void;
  onRemoveError: () => void;
  validateBlockChain: () => void;
}

export const ErrorComponent = (props: ErrorComponentProps) => {
  let error: any = "";

  switch (props.code) {
    case AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK:
      error = (
        <div className="ct-error ct-big-error ct-blockchain-error">
          <h4>
            You are in incorrect network. Please, change to{" "}
            {BlockChainHelpers.getAppChain().chainName}.
          </h4>
          <a href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/">
            How to change my network
          </a>
          <button
            onClick={() => props.validateBlockChain()}
            className="ct-main-button"
          >
            Close
          </button>
        </div>
      );
      break;
    case AppErrorCode.INVALID_APPROVED_SPEND:
      error = (
        <div className="ct-error ct-big-error ct-approved-spend-error">
          <h4>You should approve token spend.</h4>
          <p>
            When you approve, connections contract can use your FBT tokens. You
            can read the contract on <br />
            {/* <a
              href={
                BlockChainHelpers.getAppChain().blockExplorerUrls +
                "/address/" +
                props.connectionsContract
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.connectionsContract}{" "}
              <span className="fas fa-search"></span>
            </a> */}
          </p>
          <button
            className="ct-main-button"
            onClick={() =>
              props.onApproveTokenSpend ? props.onApproveTokenSpend() : null
            }
          >
            Approve
          </button>
        </div>
      );
      break;
    case AppErrorCode.INVALID_CONTRACT_LOADING:
      error = (
        <div className="ct-error ct-big-error ct-contract-loading">
          <h4>There was an error while loading the data. Please wait.</h4>
          <button
            onClick={() => props.validateBlockChain()}
            className="ct-main-button"
          >
            Close
          </button>
        </div>
      );
      break;
    case AppErrorCode.USER_DENIED_TRANSACTION:
      error = (
        <div className="ct-error ct-denied-error">
          <h4>The user denied the transaction.</h4>
          <button
            onClick={() => props.onRemoveError()}
            className="ct-main-button"
          >
            Close
          </button>
        </div>
      );
      break;
    default:
      error = (
        <div className="ct-error ct-undefined-error">
          <h4>Undefined error</h4>
          <button
            onClick={() => props.onRemoveError()}
            className="ct-main-button"
          >
            Close
          </button>
        </div>
      );
      break;
  }

  return <div className="ct-error-container">{error}</div>;
};
