import { ContractBaseModel } from "../abstracts/contract";
import { AbstractTeamLeaderValidations } from "../abstracts/teamLeaderValidations";
import { AppErrorCode } from "../app";

export class TeamLeaderValidations extends ContractBaseModel<AbstractTeamLeaderValidations> {
  getTeamLeaderInfo() {
    return this.contract.getTeamLeaderInfo().call(this.config);
  }

  getMaxMultiplicator(id: number) {
    return this.contract.getMaxMultiplicator(id).call(this.config);
  }

  isInBlackList(id: number) {
    return this.contract.isInBlackList(id).call(this.config);
  }

  mintLeader(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintLeader()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.REQUEST_TOKENS, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.REQUEST_TOKENS, error, callback)
      );
  }

  getRewardsInfo(owner: string) {
    return this.contract.getRewardsInfo(owner).call(this.config);
  }

  requestRewards(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .requestRewards(id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.REQUEST_TOKENS, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.REQUEST_TOKENS, error, callback)
      );
  }
}
