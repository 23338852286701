import { ContractBaseModel } from "../abstracts/contract";
import { AbstractEmployeesExpanded } from "../abstracts/employeesExpanded";

export class EmployeesExpanded extends ContractBaseModel<AbstractEmployeesExpanded> {
  getEmployeeData(id: number) {
    return this.contract.getEmployeeData(id).call(this.config);
  }

  getMergeRecord(id: number) {
    return this.contract.getMergeRecord(id).call(this.config);
  }

  canMerge(id: number) {
    return this.contract.canMerge(id).call(this.config);
  }

  isUsedForMultiEmployee(id: number) {
    return this.contract.isUsedForMultiEmployee(id).call(this.config);
  }
}
