import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractVoltRewards } from "../abstracts/voltRewards";

export class VoltRewards extends ContractBaseModel<AbstractVoltRewards> {
  getStakingData() {
    return this.contract.getStakingData().call(this.config);
  }

  getValidPrototype() {
    return this.contract
      .getValidPrototype(this.selectedAccount)
      .call(this.config);
  }

  requestMiniEmployeesWithVolt(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .requestMiniEmployeesWithVolt()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.MINTER_REQUEST_ERROR,
          receipt,
          callback
        );
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback)
      );
  }
}
