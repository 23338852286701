import { Contract } from "./app";
import { UtilsHelpers } from "./helpers/utils";

export interface CustomerToken {
  mainTokenBalance: number;
  loadTokenBalance: number;
  loadTokenSymbol: string;
  loadTokenName: string;
}

export interface EmployeesData {
  buildTypes: string[];
  buildModels: string[];
  typeProbabilities: string[];
  employeePrice: number;
}

export interface TeamLeaderNFT {
  inBlackList: boolean;
  maxMultiplier: number;
  teamLeader: {
    leader: {
      level: number;
      nextLevelXP: number;
      startTime: number;
      xp: number;
    };
    uri: string;
  };
}

export interface MiniEmployeesData {
  buildTypes: string[];
  buildModels: string[];
  typeProbabilities: string[];
  employeePrice: number;
  minterPointsPrice: number;
  packageSize: number;
  packageDiscount: number;
  maxMerges: number;
}

export interface FactoriesData {
  buildTypes: string[];
  buildModels: string[];
  typeProbabilities: string[];
  factoryPrice: number;
  mergeEmployeePrice: number;
}

export interface ContractsAddress {
  [Contract.TOKEN]: string;
  [Contract.EMPLOYEES]: string;
  [Contract.EMPLOYEE_DEPLOYER]: string;
  [Contract.BASE_DEPLOYER]: string;
  [Contract.FACTORIES]: string;
  [Contract.FACTORY_DEPLOYER]: string;
  [Contract.MINI_EMPLOYEES]: string;
  [Contract.MINI_EMPLOYEES_D]: string;
  [Contract.MULTI_EMPLOYEE]: string;
  [Contract.MULTI_EMPLOYEE_D]: string;
  [Contract.NFT_BRIDGE_STORAGE]: string;
  [Contract.CITIES_DEPLOYER]: string;
  [Contract.TEAM_BUILDER]: string;
  [Contract.EMPLOYEES_TEAM]: string;
}

export class DataModel {
  buildTypes: string[];
  buildModels: string[];
  typeProbabilities: string[];

  constructor(
    buildTypes: string[],
    buildModels: string[],
    typeProbabilities: string[]
  ) {
    this.buildTypes = buildTypes;
    this.buildModels = buildModels;
    this.typeProbabilities = typeProbabilities;
  }

  get totalPobabilities() {
    return (this.typeProbabilities || []).reduce(
      (total, current) => (total += Number(current)),
      0
    );
  }

  calcTypePoints(type: string) {
    if (this.isValidType(type)) {
      return Math.floor(this.totalPobabilities / this.getTypeProbability(type));
    } else return 0;
  }

  isValidType(type: string) {
    let isValid = false;
    for (let i = 0; i < this.buildTypes.length; i++) {
      if (type === this.buildTypes[i]) {
        isValid = true;
        break;
      }
    }
    return isValid;
  }

  getTypeProbability(type: string) {
    if (this.isValidType(type)) {
      let probability = 0;
      for (let i = 0; i < this.buildTypes.length; i++) {
        if (type === this.buildTypes[i]) {
          probability =
            (Number(this.typeProbabilities[i]) / this.totalPobabilities) * 100;
          break;
        }
      }
      return probability;
    } else return 0;
  }
}

export class EmployeesDataModel extends DataModel implements EmployeesData {
  employeePrice: number;

  constructor(template: EmployeesData) {
    super(
      template.buildTypes,
      template.buildModels,
      template.typeProbabilities
    );

    this.employeePrice = template.employeePrice;
  }
}

export class MiniEmployeesDataModel extends DataModel implements EmployeesData {
  employeePrice: number;
  minterPointsPrice: number;
  packageSize: number;
  packageDiscount: number;
  maxMerges: number;

  constructor(template: MiniEmployeesData) {
    super(
      template.buildTypes,
      template.buildModels,
      template.typeProbabilities
    );

    this.employeePrice = template.employeePrice;
    this.minterPointsPrice = template.minterPointsPrice;
    this.packageSize = template.packageSize;
    this.packageDiscount = template.packageDiscount;
    this.maxMerges = template.maxMerges;
  }
}

export class FactoriesDataModel extends DataModel implements FactoriesData {
  factoryPrice: number;
  mergeEmployeePrice: number;

  constructor(template: FactoriesData) {
    super(
      template.buildTypes,
      template.buildModels,
      template.typeProbabilities
    );

    this.factoryPrice = template.factoryPrice;
    this.mergeEmployeePrice = template.mergeEmployeePrice;
  }
}

export class AppData {
  constructor(
    public customerToken: CustomerToken,
    public employeesData: EmployeesDataModel,
    public factoriesData: FactoriesDataModel,
    public miniEmployeesData: MiniEmployeesDataModel,
    public contractsAddress: ContractsAddress
  ) {}

  static buildFromTemplates(
    customerToken: CustomerToken,
    employeesData: any,
    factoriesData: any,
    miniEmployeesData: any,
    contractsAddress: ContractsAddress
  ) {
    return new AppData(
      customerToken,
      new EmployeesDataModel({
        buildModels: employeesData["buildModels"],
        buildTypes: employeesData["buildTypes"],
        typeProbabilities: employeesData["typeProbabilities"],
        employeePrice: UtilsHelpers.normalizeWei(employeesData["price"]),
      }),
      new FactoriesDataModel({
        buildModels: factoriesData["buildModels"],
        buildTypes: factoriesData["buildTypes"],
        typeProbabilities: factoriesData["typeProbabilities"],
        factoryPrice: UtilsHelpers.normalizeWei(factoriesData["price"]),
        mergeEmployeePrice: UtilsHelpers.normalizeWei(
          factoriesData["mergeEmployeePrice"]
        ),
      }),
      new MiniEmployeesDataModel({
        buildModels: miniEmployeesData["buildModels"],
        buildTypes: miniEmployeesData["buildTypes"],
        typeProbabilities: miniEmployeesData["typeProbabilities"],
        employeePrice: UtilsHelpers.normalizeWei(
          miniEmployeesData["employeePrice"]
        ),
        minterPointsPrice: Number(miniEmployeesData["minterPointsPrice"]),
        packageSize: Number(miniEmployeesData["packageSize"]),
        packageDiscount: Number(miniEmployeesData["packageDiscount"]),
        maxMerges: Number(miniEmployeesData["maxMerges"]),
      }),
      contractsAddress
    );
  }
}
