import { AppData } from "../types";

export interface FactoryNFTTemplate {
  id: number;
  build: number;
  model: number;
  points: number;
  uri: string;
  playTime: number;
  isMarketApproved: boolean;
  isStakingApproved: boolean;
  canPlay: boolean;
  appData: AppData;
  stakingRewards: number;
  growTimestamp: number;
  isStaked: boolean;
  canFactoryGrow: boolean;
  hasRelation: boolean;
}

export enum FactoryProperty {
  ID = "id",
  BUILD = "build",
  MODEL = "model",
  POINTS = "points",
}

export type FactoryProperties = {
  [key in FactoryProperty]: string | null;
};

export class FactoryNFT implements FactoryNFTTemplate {
  public uri: string;
  public id: number;
  public build: number;
  public model: number;
  public points: number;
  public playTime: number;
  public isStaked: boolean;
  public growTimestamp: number;
  public canFactoryGrow: boolean;
  public stakingRewards: number;
  public isMarketApproved: boolean;
  public isStakingApproved: boolean;
  public canPlay: boolean;
  public hasRelation: boolean;
  public appData: AppData;

  constructor(private _template: FactoryNFTTemplate) {
    this.id = this._template.id;
    this.appData = this._template.appData;
    this.build = Number(this._template.build);
    this.model = Number(this._template.model);
    this.points = Number(this._template.points);
    this.playTime = Number(this._template.playTime);
    this.stakingRewards = Number(this._template.stakingRewards);
    this.canFactoryGrow = Boolean(this._template.canFactoryGrow);
    this.growTimestamp = Number(this._template.growTimestamp);
    this.isStaked = Boolean(this._template.isStaked);
    this.isMarketApproved = Boolean(this._template.isMarketApproved);
    this.isStakingApproved = Boolean(this._template.isStakingApproved);
    this.canPlay = Boolean(this._template.canPlay);
    this.hasRelation = this._template.hasRelation;
    this.uri = this._template.uri || "";
  }

  static createWithContractTemplate(contractTemplate: { [id: string]: any }) {
    return new FactoryNFT({
      id: contractTemplate.id,
      build: contractTemplate.build,
      model: contractTemplate.model,
      points: contractTemplate.points,
      uri: contractTemplate.uri,
      appData: contractTemplate.appData,
      isMarketApproved: contractTemplate.isMarketApproved,
      isStakingApproved: contractTemplate.isStakingApproved,
      canPlay: contractTemplate.canPlay,
      playTime: contractTemplate.playTime,
      stakingRewards: contractTemplate.stakingRewards,
      isStaked: contractTemplate.isStaked,
      canFactoryGrow: contractTemplate.canFactoryGrow,
      growTimestamp: contractTemplate.growTimestamp,
      hasRelation: contractTemplate.hasRelation,
    });
  }

  get properties(): FactoryProperties {
    return {
      id: this.id.toString(),
      build: this.build.toString(),
      model: this.model.toString(),
      points: this.points.toString(),
    };
  }
}
