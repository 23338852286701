import React from "react";
import { AppErrorCode } from "../../../../core/app";
import { TokenController } from "../../../../core/modules/token";
import { EmployeesTeamNFT } from "../../../../core/nfts/teams";
import { EmployeesController } from "../../../../core/modules/employees";

import "./teams.styles.css";
import { UtilsHelpers } from "../../../../core/helpers/utils";

export interface StaticNFTProps {
  small?: boolean;
  deactivated?: boolean;
  selected?: boolean;
  ultraSmall?: boolean;
  nft: EmployeesTeamNFT;
  className?: string;
  staticImage?: boolean;
  travel?: boolean;
  token?: TokenController;
  teams: EmployeesController;
  onSell?: (price: number) => void;
  onClick?: (team: EmployeesTeamNFT) => void;
}

export interface StaticNFTState {
  actionMessage: null | string;
  sellPrice: string;
  loader: boolean;
  error: AppErrorCode | null;
  xp: number;
}

export class StaticTeamNFT extends React.Component<StaticNFTProps, StaticNFTState> {
  constructor(props: StaticNFTProps) {
    super(props);

    this.state = {
      actionMessage: null,
      sellPrice: "",
      loader: false,
      error: null,
      xp: 0,
    };
  }

  componentDidMount() {
    this.reloadTeamData();
  }

  async reloadTeamData() {
    let xp = 0;

    if (this.props.teams.employeeTeams && this.props.nft.isMini) {
      xp = (await this.props.teams.employeeTeams.getMiniData(this.props.nft.id))?.team?.totalXP;
    }

    this.setState({
      loader: false,
      xp,
    });
  }

  render() {
    let selectedMessage = null;

    switch (this.state.actionMessage) {
      default:
        selectedMessage = null;
        break;
    }

    return (
      <div
        onClick={() => (this.props.onClick ? this.props.onClick(this.props.nft) : null)}
        className={
          "ct-nft" +
          (this.props.className ? this.props.className : "") +
          (this.props.small ? " ct-small" : "") +
          (this.props.ultraSmall ? " ct-ultra-small" : "") +
          (this.props.onClick ? " ct-click" : "") +
          (this.props.deactivated ? " ct-un-click" : "") +
          (this.props.selected ? " ct-selected" : "")
        }
      >
        {this.state.loader ? (
          <div className="ct-loader">
            <div className="spinner"></div>
          </div>
        ) : (
          ""
        )}
        <a className="ct-search-team" href={"/team?teamId=" + this.props.nft.id} target="_blank" rel="noopener noreferrer">
          {" "}
          <span className="fas fa-search"></span>
        </a>
        <div className="ct-image">
          <img src={this.props.staticImage ? this.props.nft.uri : this.props.nft.uri + "&onlyImage=true"} alt="" />
        </div>
        <div className="ct-data">
          <span>
            <strong>ID: #</strong> {this.props.nft.id}
          </span>
          <span>
            <strong>Points: </strong> {this.props.nft.totalPoints}
          </span>
          {this.props.nft.typesPart && this.props.nft.typesPart.length > 0
            ? this.props.nft.typesPart.map((part, index) => {
                return (
                  <span key={index + "part"}>
                    <strong>{UtilsHelpers.getTypeName(index + 1)}: </strong>{" "}
                    {part +
                      " / " +
                      this.props.nft.totalEmployees * 4 +
                      " = " +
                      ((part / (this.props.nft.totalEmployees * 4)) * 100).toFixed(1) +
                      " %"}
                  </span>
                );
              })
            : ""}
          <span>
            <strong>Total Employees: </strong> {this.props.nft.totalEmployees}
          </span>
          <span>
            <strong>Special: </strong> {((this.props.nft.totalSpecial * 100) / this.props.nft.totalEmployees >= 50).toString()}
          </span>
          <span>
            <strong>XP: </strong> {this.state.xp + " / " + this.props.nft.totalPoints * 100}{" "}
          </span>
        </div>
        <div className="ct-actions-container">
          <div className="ct-actions"></div>
          <div className="ct-action-message">{selectedMessage}</div>
        </div>
      </div>
    );
  }
}
