import { AppData } from "../types";

export interface EmployeesTeamTemplate {
  id: number;
  package: number;
  totalXP: number;
  stakedToken: number;
  totalEmployees: number;
  totalSpecial: number;
  totalNormal: number;
  totalPoints: number;
  typesPart: number[];
  isMini: boolean;
  uri: string;
  appData: AppData;
}

export enum TeamProperty {
  ID = "id",
  TOTAL_POINTS = "points",
}

export type TeamProperties = {
  [key in TeamProperty]: string | null;
};

export class EmployeesTeamNFT implements EmployeesTeamTemplate {
  public id: number;
  public package: number;
  public totalXP: number;
  public stakedToken: number;
  public totalEmployees: number;
  public totalSpecial: number;
  public totalNormal: number;
  public totalPoints: number;
  public typesPart: number[];
  public isMini: boolean;
  public uri: string;
  public appData: AppData;

  constructor(private _template: EmployeesTeamTemplate) {
    this.id = this._template.id;
    this.appData = this._template.appData;
    this.package = Number(this._template.package);
    this.totalXP = Number(this._template.totalXP);
    this.totalEmployees = Number(this._template.totalEmployees);
    this.stakedToken = Number(this._template.stakedToken);
    this.totalNormal = Number(this._template.totalNormal);
    this.totalPoints = Number(this._template.totalPoints);
    this.totalSpecial = Number(this._template.totalSpecial);
    this.isMini = Boolean(this._template.isMini);
    this.typesPart = this._template.typesPart;
    this.uri = this._template.uri || "";
  }

  static createWithContractTemplate(contractTemplate: { [id: string]: any }) {
    return new EmployeesTeamNFT({
      id: contractTemplate.id,
      package: contractTemplate.package,
      totalXP: contractTemplate.totalXP,
      stakedToken: contractTemplate.stakedToken,
      totalEmployees: contractTemplate.totalEmployees,
      totalSpecial: contractTemplate.totalSpecial,
      totalNormal: contractTemplate.totalNormal,
      totalPoints: contractTemplate.totalPoints,
      typesPart: contractTemplate.typesPart,
      isMini: contractTemplate.isMini,
      uri: contractTemplate.uri,
      appData: contractTemplate.appData,
    });
  }

  get properties(): TeamProperties {
    return {
      id: this.id.toString(),
      points: this.totalPoints.toString(),
    };
  }
}
