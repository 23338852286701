import { AnyAction } from "redux";
import { Contract } from "../../../core/app";
import { ContractData, ContractsStateController } from "../../../core/contracts";
import { AppActions, AppReducer } from "../../types";

export const contractsKey = AppReducer.CONTRACTS;

export interface ContractsState {
  [Contract.TOKEN]: ContractData | null;
  [Contract.SPECIAL_TOKEN]: ContractData | null;
  [Contract.EMPLOYEES]: ContractData | null;
  [Contract.BASE_DEPLOYER]: ContractData | null;
  [Contract.EMPLOYEE_DEPLOYER]: ContractData | null;
  [Contract.FACTORIES]: ContractData | null;
  [Contract.FACTORY_DEPLOYER]: ContractData | null;
  [Contract.MINI_EMPLOYEES_D]: ContractData | null;
  [Contract.MINI_EMPLOYEES]: ContractData | null;
  [Contract.MULTI_EMPLOYEE]: ContractData | null;
  [Contract.MULTI_EMPLOYEE_D]: ContractData | null;
  [Contract.EMPLOYEES_EXPANDED]: ContractData | null;
  [Contract.NFT_BRIDGE_STORAGE]: ContractData | null;
  [Contract.CITIES]: ContractData | null;
  [Contract.CITIES_DEPLOYER]: ContractData | null;
  [Contract.CITIES_STORAGE]: ContractData | null;
  [Contract.UNIVERSITIES]: ContractData | null;
  [Contract.CITY_GETTERS]: ContractData | null;
  [Contract.TEAM_LEADER_V]: ContractData | null;
  [Contract.TEAM_LEADER]: ContractData | null;
  [Contract.TEAM_BUILDER]: ContractData | null;
  [Contract.EMPLOYEES_TEAM]: ContractData | null;
  [Contract.TEAM_STORAGE]: ContractData | null;
  [Contract.LP_REWARDS]: ContractData | null;
  [Contract.VOLT_REWARDS]: ContractData | null;
  [Contract.CITY_RELATIONS]: ContractData | null;
}

export const initialState: ContractsState = {
  [Contract.TOKEN]: null,
  [Contract.SPECIAL_TOKEN]: null,
  [Contract.EMPLOYEES]: null,
  [Contract.BASE_DEPLOYER]: null,
  [Contract.EMPLOYEE_DEPLOYER]: null,
  [Contract.FACTORIES]: null,
  [Contract.FACTORY_DEPLOYER]: null,
  [Contract.MINI_EMPLOYEES_D]: null,
  [Contract.MINI_EMPLOYEES]: null,
  [Contract.MULTI_EMPLOYEE]: null,
  [Contract.MULTI_EMPLOYEE_D]: null,
  [Contract.EMPLOYEES_EXPANDED]: null,
  [Contract.NFT_BRIDGE_STORAGE]: null,
  [Contract.CITIES]: null,
  [Contract.CITIES_DEPLOYER]: null,
  [Contract.CITIES_STORAGE]: null,
  [Contract.CITY_GETTERS]: null,
  [Contract.UNIVERSITIES]: null,
  [Contract.TEAM_LEADER_V]: null,
  [Contract.TEAM_LEADER]: null,
  [Contract.TEAM_BUILDER]: null,
  [Contract.EMPLOYEES_TEAM]: null,
  [Contract.TEAM_STORAGE]: null,
  [Contract.VOLT_REWARDS]: null,
  [Contract.LP_REWARDS]: null,
  [Contract.CITY_RELATIONS]: null,
};

export const contractsReducer = (state: ContractsState = initialState, action: AnyAction) => {
  switch (action.type) {
    case AppActions.SET_MULTIPLE_CONTRACT_ABI:
      return ContractsStateController.setMultipleContractsAbi(state, action.contracts);
    default:
      return state;
  }
};
