import { ContractBaseModel } from "../abstracts/contract";
import { appConfig, AppErrorCode } from "../app";
import { AbstractEmployeeDeployer } from "../abstracts/employeeDeployer";

export class EmployeeDeployer extends ContractBaseModel<AbstractEmployeeDeployer> {
  getEmployeesData() {
    return this.contract.getEmployeesData().call(this.config);
  }

  mintEmployee(type: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintEmployee(type)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  isInWhitelist() {
    return this.contract.isInWhitelist(this.selectedAccount).call();
  }
}
