import { AppErrorCode } from "../app";
import { MiniEmployeeDeployer } from "../contracts/miniEmployeeDeployer";
import { UtilsHelpers } from "../helpers/utils";

export interface DeployerData {
  creatorFee: number;
  maxMerge: number;
  maxPayedMints: number;
  packageDiscount: number;
  packageSize: number;
  payedMints: number;
  playToEarnFee: number;
  employeePrice: number;
  minterPointsPrice: number;
  stakingFee: number;
  validatePayedMints: boolean;

  specialToken: number;
  specialEmployeePrice: number;
  specialEmployeeInitPrice: number;
  specialEmployeeMaxPrice: number;
  specialQuantity: number;
  specialAugment: number;
  openSpecialEmployee: boolean;
}

export class MiniEmployeeDeployerController {
  public data: DeployerData | null = null;

  constructor(public contract: MiniEmployeeDeployer) {}

  public async loadDeployerData() {
    const data = await this.contract.getEmployeesData();

    if (data) {
      this.data = {
        ...data,
        employeePrice: UtilsHelpers.normalizeWei(data.employeePrice),
      };
    }
  }

  public mintEmployee(multiple: boolean, callback?: (error: AppErrorCode | null, hash?: string) => void) {
    UtilsHelpers.debugger("Mint Employee");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger("Try Mint NFT (" + this.contract.selectedAccount + ")");

      if (callback) this.contract.mintPayedEmployee(multiple, callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }

  public mintWithStakingPoints(multiple: boolean, callback?: (error: AppErrorCode | null, hash?: string) => void) {
    UtilsHelpers.debugger("Mint Employee");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger("Try Mint NFT (" + this.contract.selectedAccount + ")");

      if (callback) this.contract.mintWithStakingPoints(multiple, callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }

  public mergeEmployees(men: number, woman: number, callback?: (error: AppErrorCode | null, hash?: string) => void) {
    UtilsHelpers.debugger("Mint Employee");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger("Try Mint NFT (" + this.contract.selectedAccount + ")");

      if (callback) this.contract.mergeTwoEmployees(men, woman, callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }
}
