import React from "react";
import { AppErrorCode, Contract } from "../../core/app";
import { EmployeeNFT } from "../../core/nfts/employee";
import { BuilderEmployeesGrid } from "../organisms/builder/employees/grid";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { EmployeesController } from "../../core/modules/employees";
import { UtilsHelpers } from "../../core/helpers/utils";
import { MiniEmployeeDeployerController } from "../../core/modules/miniEmployeeDeployer";
import Web3 from "web3";
import { TeamLeader } from "../organisms/teamLeader";

interface MiniEmployeesComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface MiniEmployeesComponentState {
  employeeDeployer: null | MiniEmployeeDeployerController;
  employees: null | EmployeesController;
  token: null | TokenController;
  openEmployeeNFTModal: boolean;
  employeeNewNFTs: EmployeeNFT[];
  employeesLoader: boolean;
  payedEmployeeConfirm: boolean;
  pointsEmployeeConfirm: boolean;
  package: boolean;
  deployerAllowance: number;
  specialTokenAllowance: number;
  specialTokenBalance: number;
  page: number;
  lpStakingData: {
    locked: number;
    participation: number;
    supply: number;
    canRequest: boolean;
    validCheckpoint: boolean;
  };
}

export class MiniEmployeesPage extends React.PureComponent<MiniEmployeesComponentProps, MiniEmployeesComponentState> {
  constructor(props: MiniEmployeesComponentProps) {
    super(props);

    this.state = {
      employeeDeployer: null,
      token: null,
      employees: null,
      openEmployeeNFTModal: false,
      employeeNewNFTs: [],
      employeesLoader: false,
      payedEmployeeConfirm: false,
      pointsEmployeeConfirm: false,
      package: false,
      deployerAllowance: 0,
      specialTokenAllowance: 0,
      specialTokenBalance: 0,
      page: 0,
      lpStakingData: {
        locked: 0,
        participation: 0,
        supply: 0,
        canRequest: false,
        validCheckpoint: false,
      },
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetEmployeesController();
    this.loadAndSetDeployerEmployeesController();
    this.props.onToggleLoader(false);
  }

  async preloadControllers(closeLoader: boolean = false) {
    let token = null;
    let deployerAllowance = 0;
    let specialTokenAllowance = 0;
    let specialTokenBalance = 0;
    let lpStakingData = null;

    if (this.props.blockChain.controller?.specialToken && this.props.appState.appData) {
      specialTokenAllowance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.controller.specialToken.allowance(
          this.props.blockChain.controller.specialToken.selectedAccount,
          this.props.appState.appData?.contractsAddress[Contract.MINI_EMPLOYEES_D]
        )
      );

      specialTokenBalance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.controller.specialToken.balanceOf(this.props.blockChain.controller.specialToken.selectedAccount)
      );
    }

    if (this.props.blockChain.controller?.token && this.props.appState.appData) {
      token = new TokenController(this.props.blockChain.controller.token);

      deployerAllowance = await token.getAllowance(this.props.appState.appData?.contractsAddress[Contract.MINI_EMPLOYEES_D]);
    }

    if (
      this.props.blockChain.controller?.voltLPRewards &&
      this.props.blockChain.controller.voltRewards &&
      this.props.blockChain.controller.selectedAccount
    ) {
      try {
        const data = await this.props.blockChain.controller.voltLPRewards.getStakingData();
        const participation = await this.props.blockChain.controller.voltLPRewards.participation(this.props.blockChain.controller.selectedAccount);

        if (data) {
          lpStakingData = {
            locked: Number(Web3.utils.fromWei(data.locked, "ether")),
            participation: Number(participation),
            supply: Number(Web3.utils.fromWei(data.supply || "0")),
            canRequest: data.canRequest,
            validCheckpoint: data.hasValidCheckpoint,
          };
        }
      } catch (error) {
        console.log("[Mini employees] LP Staking error");
      }
    }

    this.setState({
      token,
      employeesLoader: false,
      deployerAllowance,
      specialTokenAllowance,
      specialTokenBalance,
      lpStakingData: lpStakingData ? { ...this.state.lpStakingData, ...lpStakingData } : this.state.lpStakingData,
    });
  }

  async preloadEmployeeController() {
    let employees = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employees.loadMiniEmployeesData();
      console.log(employees);
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return employees;
  }

  async preloadEmployeeDeployerController() {
    let controller = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (this.props.blockChain.controller?.miniEmployeesDeployer && this.props.appState.appData) {
      controller = new MiniEmployeeDeployerController(this.props.blockChain.controller.miniEmployeesDeployer);

      await controller.loadDeployerData();
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return controller;
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.props.onLoadCustomerData(true);
    this.preloadControllers();
  }

  async loadAndSetEmployeesController() {
    this.setState({ employeesLoader: true }, async () => {
      const employees = await this.preloadEmployeeController();
      this.setState({ employees, employeesLoader: false });
    });
  }

  async loadAndSetDeployerEmployeesController() {
    const employeeDeployer = await this.preloadEmployeeDeployerController();
    let deployerAllowance = 0;

    if (this.props.blockChain.controller?.token && this.props.appState.appData) {
      deployerAllowance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.controller.token.allowance(
          this.props.blockChain.controller.token.selectedAccount,
          this.props.appState.appData?.contractsAddress[Contract.MINI_EMPLOYEES_D]
        )
      );
    }

    this.setState({ employeeDeployer, deployerAllowance });
  }

  render() {
    let employeePrice = 0;

    if (this.state.employeeDeployer && this.state.employeeDeployer.data) {
      employeePrice = this.state.package
        ? (this.state.employeeDeployer.data.employeePrice *
            this.state.employeeDeployer.data.packageSize *
            (100 - this.state.employeeDeployer.data.packageDiscount)) /
          100
        : this.state.employeeDeployer.data.employeePrice;
    }

    console.log(this.state.lpStakingData);

    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} /> : ""}
        <div className="ct-max-container ct-builder-page">
          <div className="ct-page-actions-container ct-mb-10">
            <div className="ct-container">
              <div className="ct-data">
                <h1>MINTING childrens</h1>
                <small>Build your NFT empire. Educate your children and grow your business.</small>
                <a href="https://docs.businessbuilders.city/the-game/1.-childrens-nfts" target="_blank" rel="noopener noreferrer">
                  Childrens documentation
                </a>
                <small>Read before buying your employee</small>
              </div>
              {this.state.employeeDeployer && this.state.employeeDeployer.data && this.props.blockChain.customer?.tokensData ? (
                <div className="ct-actions">
                  {this.state.payedEmployeeConfirm || this.state.pointsEmployeeConfirm ? (
                    <button
                      className="ct-main-button"
                      onClick={() =>
                        this.setState({
                          payedEmployeeConfirm: false,
                          pointsEmployeeConfirm: false,
                        })
                      }
                    >
                      <span className="fas fa-times"></span>
                    </button>
                  ) : (
                    ""
                  )}

                  {!this.state.payedEmployeeConfirm && !this.state.pointsEmployeeConfirm ? (
                    <button
                      onClick={() =>
                        this.setState({
                          payedEmployeeConfirm: true,
                          pointsEmployeeConfirm: false,
                        })
                      }
                      className="ct-main-button"
                    >
                      Mint Payed Employee
                    </button>
                  ) : (
                    ""
                  )}

                  {this.state.payedEmployeeConfirm ? (
                    <div className="ct-payed-confirm">
                      <span>
                        <strong>Necessary FTB: {employeePrice} </strong>
                        {this.props.appState.appData?.customerToken.loadTokenSymbol}
                      </span>
                      <span>
                        <strong>FTB Balance: </strong>
                        {this.props.blockChain.customer?.tokensData.loadTokenBalance.toFixed()}{" "}
                        {this.props.appState.appData?.customerToken.loadTokenSymbol}
                      </span>

                      <label htmlFor="package">
                        Use package{" "}
                        <input
                          type="checkbox"
                          onChange={(e) => this.setState({ package: e.target.checked })}
                          checked={this.state.package}
                          name="package"
                          id="package"
                        />
                      </label>
                      {this.state.deployerAllowance >= employeePrice ? (
                        <button
                          className="ct-main-button"
                          disabled={employeePrice > this.props.blockChain.customer?.tokensData.loadTokenBalance}
                          onClick={() => {
                            this.props.onToggleLoader(true);
                            console.log(this.state.employeeDeployer);
                            this.state.employeeDeployer?.mintEmployee(this.state.package, (error: AppErrorCode | null) => {
                              this.onUpdateData(error);
                            });
                          }}
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          className="ct-main-button"
                          onClick={() => {
                            if (this.props.appState.appData) {
                              this.props.onToggleLoader(true);
                              this.state.token?.approveTokenSpend(
                                this.props.appState.appData?.contractsAddress[Contract.MINI_EMPLOYEES_D],
                                employeePrice,
                                (error: AppErrorCode | null) => {
                                  console.log(error);
                                  this.onUpdateData(error);
                                }
                              );
                            }
                          }}
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="ct-page-actions-container ct-mb-10">
            {/* <div className="ct-container">
              <div className="ct-data">
                <h1>LP provider rewards</h1>
                <small>Obtain 5 mini employees daily for providing 1% of our liquidity. And get rewards in VoltSwap farming.</small>
                <h5>INFORMATION</h5>
                <ul>
                  <li>Locked LPs: {this.state.lpStakingData.locked.toFixed(2)} MTRG - FTB</li>
                  <li>LPs total supply: {this.state.lpStakingData.supply.toFixed(4)} LPs</li>
                  <li>LP Participation: {((this.state.lpStakingData.locked * 100) / this.state.lpStakingData.supply).toFixed(4)} %</li>
                  <li>Can request Mini Employees with LP: {this.state.lpStakingData.canRequest.toString()}</li>
                  {!this.state.lpStakingData.validCheckpoint ? <li>Wait 24 hours after locking your LPs</li> : ""}
                  <li>
                    <a href="https://voltswap.finance/" target="_blank" rel="noopener noreferrer">
                      {" "}
                      Powered by Voltswap V2
                    </a>
                  </li>
                </ul>
              </div>
              <div className="ct-actions">
                <button
                  className="ct-main-button"
                  onClick={() => {
                    if (this.props.blockChain.controller?.voltLPRewards) {
                      this.props.blockChain.controller?.voltLPRewards.requestMiniEmployeesWithLP((error: any) => this.onUpdateData(error));
                    }
                  }}
                  disabled={!this.state.lpStakingData.canRequest}
                >
                  Request (MTRG - FTB)
                </button>

                {/* <button
                  className="ct-main-button"
                  onClick={() => {
                    if (this.props.blockChain.controller?.voltRewards) {
                      this.props.blockChain.controller?.voltRewards.requestMiniEmployeesWithVolt((error: any) => this.onUpdateData(error));
                    }
                  }}
                  disabled={!this.state.lpStakingData.canRequestMiniEmployeesWithVolt}
                >
                  Request (VOLT)
                </button> 
              </div>
            </div> */}
          </div>
          <div className="ct-employees">
            {this.state.employees && this.props.appState.appData ? (
              <BuilderEmployeesGrid
                nftsPerPage={8}
                employeesLoader={this.state.employeesLoader}
                employees={this.state.employees}
                appData={this.props.appState.appData}
                page={this.state.page}
                onlyMiniEmployees={true}
                miniEmployeesContract={this.state.employees.miniEmployees}
                onChangeEmployeesPage={(page: number) => this.setState({ page })}
                onUpdate={() => this.loadAndSetEmployeesController()}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
