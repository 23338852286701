import { ContractsState } from "../storage/state/contracts/state";
import { Contract } from "./app";
import { UtilsHelpers } from "./helpers/utils";

// Test Contracts

import Token from "../assets/contracts/Token.json";
import Employees from "../assets/contracts/Employees.json";
import EmployeeDeployer from "../assets/contracts/EmployeeDeployer.json";
import BaseDeployer from "../assets/contracts/BaseDeployer.json";
import Factories from "../assets/contracts/Factories.json";
import FactoriesDeployer from "../assets/contracts/FactoryDeployer.json";
import MiniEmployees from "../assets/contracts/MiniEmployees.json";
import MiniEmployeesDeployer from "../assets/contracts/MiniEmployeeDeployer.json";
import MultiEmployees from "../assets/contracts/MultiEmployees.json";
import MultiEmployeesDeployer from "../assets/contracts/MultiEmployeeDeployer.json";
import EmployeesExpanded from "../assets/contracts/EmployeesExpanded.json";
import NFTBridgeStorage from "../assets/contracts/NFTBridgeStorage.json";
import Cities from "../assets/contracts/Cities.json";
import CitiesDeployer from "../assets/contracts/CitiesDeployer.json";
import CitiesStorage from "../assets/contracts/CityRelationsStorage.json";
import CityGetters from "../assets/contracts/CityRelationsGetters.json";
import CityUniversities from "../assets/contracts/CityUniversities.json";
import TeamLeaderValidations from "../assets/contracts/TeamLeaderValidations.json";
import TeamLeaderNFT from "../assets/contracts/TeamLeaderNFT.json";
import TeamBuilder from "../assets/contracts/TeamBuildersDeployer.json";
import EmployeesTeam from "../assets/contracts/EmployeesTeam.json";
import NormalTeamStorage from "../assets/contracts/NormalTeamBuildersStorage.json";
import VoltRewards from "../assets/contracts/VoltRewards.json";
import LPRewards from "../assets/contracts/VoltSwapRewardsV2.json";
import CityRelations from "../assets/contracts/CityRelations.json";

export interface NormalizedABI {
  networks: ABINetworks;
  [property: string]: any;
}

export type ABINetworkData = {
  events: any;
  links: any;
  address: string;
  transactionHash: string;
};

export type ABINetworks = {
  [id: string]: ABINetworkData;
};

export type ContractData = {
  data: NormalizedABI | null;
  contract: Contract;
};

export class ContractsStateController {
  static contractBasePath: string = "/assets/contracts/";

  static loadedContracts: any = {
    [Contract.TOKEN]: Token,
    [Contract.EMPLOYEES]: Employees,
    [Contract.EMPLOYEE_DEPLOYER]: EmployeeDeployer,
    [Contract.BASE_DEPLOYER]: BaseDeployer,
    [Contract.FACTORIES]: Factories,
    [Contract.FACTORY_DEPLOYER]: FactoriesDeployer,
    [Contract.MULTI_EMPLOYEE]: MultiEmployees,
    [Contract.MULTI_EMPLOYEE_D]: MultiEmployeesDeployer,
    [Contract.MINI_EMPLOYEES]: MiniEmployees,
    [Contract.MINI_EMPLOYEES_D]: MiniEmployeesDeployer,
    [Contract.EMPLOYEES_EXPANDED]: EmployeesExpanded,
    [Contract.NFT_BRIDGE_STORAGE]: NFTBridgeStorage,
    [Contract.CITIES]: Cities,
    [Contract.CITIES_DEPLOYER]: CitiesDeployer,
    [Contract.SPECIAL_TOKEN]: Token,
    [Contract.CITIES_STORAGE]: CitiesStorage,
    [Contract.CITY_GETTERS]: CityGetters,
    [Contract.UNIVERSITIES]: CityUniversities,
    [Contract.TEAM_LEADER_V]: TeamLeaderValidations,
    [Contract.TEAM_LEADER]: TeamLeaderNFT,
    [Contract.EMPLOYEES_TEAM]: EmployeesTeam,
    [Contract.TEAM_BUILDER]: TeamBuilder,
    [Contract.TEAM_STORAGE]: NormalTeamStorage,
    [Contract.LP_REWARDS]: LPRewards,
    [Contract.VOLT_REWARDS]: VoltRewards,
    [Contract.CITY_RELATIONS]: CityRelations,
  };

  public static async loadContractAbiMock(path: string, callback?: (error: boolean, abiData?: any) => void) {
    fetch(path)
      .then((result) => result.json())
      .then((json: any) => {
        return callback && json ? callback(false, json) : callback ? callback(true) : null;
      })
      .catch((err) => {
        UtilsHelpers.debugger(err?.code + " " + path);
        return callback ? callback(true) : null;
      });
  }

  public static loadContractAbi(contract: Contract) {
    return ContractsStateController.loadedContracts[contract];
  }

  public static isLoaded(state: ContractsState) {
    let isValid = true;
    let keys = Object.keys(state);

    for (let i = 0; i < keys.length; i++) {
      if (state[keys[i] as Contract] === null) isValid = false;
    }

    return isValid;
  }

  /* -------------------------------------------------------------------------- */
  /*                               ANCHOR Storage                               */
  /* -------------------------------------------------------------------------- */

  public static setContractAbi(state: ContractsState, contract: string, abiData: any): ContractsState {
    return {
      ...state,
      [contract]: { ...state[contract as Contract], data: abiData },
    };
  }

  public static setMultipleContractsAbi(state: ContractsState, contracts: ContractData[]): ContractsState {
    let obj: any = {};

    for (let i = 0; i < contracts.length; i++) {
      obj[contracts[i].contract] = contracts[i];
    }

    return { ...state, ...obj } as ContractsState;
  }

  /* -------------------------------------------------------------------------- */
}
