import { ContractBaseModel } from "../abstracts/contract";
import { appConfig, AppErrorCode } from "../app";
import { AbstractNFTBridgeStorage } from "../abstracts/nftBridgeStorage";

export class NFTBridgeStorage extends ContractBaseModel<AbstractNFTBridgeStorage> {
  getBridgeData() {
    return this.contract.getBridgeData().call(this.config);
  }

  isLocked(contract: string, id: number) {
    console.log(this.contract);
    return this.contract.isLocked(contract, id).call(this.config);
  }

  createLimboRequestWithMany(
    contract: string,
    nfts: number[],
    value: number,
    callback: (error: AppErrorCode | null, receipt: any | null) => void
  ) {
    return this.contract
      .createLimboRequestWithMany(contract, nfts)
      .send({
        ...this.config,
        value: this.web3.utils.toWei(value.toString(), "ether"),
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.APPROVE_EMPLOYEE, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.APPROVE_EMPLOYEE, error, callback)
      );
  }
}
