import React from "react";
import { TokenController } from "../../../../core/modules/token";
import { AppData } from "../../../../core/types";

import { EmployeesTeamNFT, TeamProperties } from "../../../../core/nfts/teams";
import { EmployeesController } from "../../../../core/modules/employees";
import { StaticTeamNFT } from "../../../molecules/nft/teams/teams";

import "./grid.styles.css";

export interface TeamGridProps {
  page: number;
  nftsPerPage: number;
  appData: AppData;
  token?: TokenController;
  teams: EmployeesController;
  loader: boolean;
  ultraSmall?: boolean;
  selected?: EmployeesTeamNFT;
  selection?: EmployeesTeamNFT[];
  title?: string;
  travel?: boolean;
  subtitle?: string;
  onClick?: (team: EmployeesTeamNFT) => void;
  onUpdate?: () => void;
  onApprove?: (address: string, amount: number) => void;
  onTransfer?: (to: string) => void;
  onChangePage?: (page: number) => void;
  onSell?: (id: number, pirce: number) => void;
}

export interface TeamGridState {
  properties: TeamProperties;
  filter: boolean;
  points: boolean;
  id: boolean;
}

export class TeamGrid extends React.Component<TeamGridProps, TeamGridState> {
  constructor(props: TeamGridProps) {
    super(props);

    this.state = {
      properties: {
        points: null,
        id: null,
      },
      filter: false,
      points: false,
      id: true,
    };
  }

  render() {
    const filteredNFTs = this.props.teams.teamsData;
    const totalPages = Math.ceil(filteredNFTs.length / this.props.nftsPerPage);
    const selectedNFTs = [];

    if (this.state.points && !this.state.id) {
      filteredNFTs.sort((a, b) => {
        if (a.totalPoints < b.totalPoints) return 1;
        if (a.totalPoints > b.totalPoints) return -1;
        return 0;
      });
    }

    if (this.state.id && !this.state.points) {
      filteredNFTs.sort((a, b) => {
        if (a.id < b.id) return 1;
        if (a.id > b.id) return -1;
        return 0;
      });
    }

    for (let i = 0; i < totalPages; i++) {
      if (i === this.props.page) {
        for (
          let j = i * this.props.nftsPerPage;
          j < this.props.nftsPerPage * i + this.props.nftsPerPage;
          j++
        ) {
          if (filteredNFTs[j]) selectedNFTs.push(filteredNFTs[j]);
        }
      }
    }

    return (
      <div className="ct-builder-grid ct-cities-grid">
        <div className="ct-sub-container">
          <div className="ct-title">
            <div className="ct-text">
              <h1>{this.props.title ? this.props.title : "Teams (NFTs)"}</h1>
              <small>{this.props.subtitle ? this.props.subtitle : ""}</small>
            </div>
            <div className="ct-actions">
              {this.props.onUpdate ? (
                <span
                  onClick={() =>
                    this.props.onUpdate ? this.props.onUpdate() : null
                  }
                  className="fas fa-sync-alt"
                ></span>
              ) : (
                ""
              )}

              {this.state.filter ? (
                <span
                  onClick={() => this.setState({ filter: !this.state.filter })}
                  className="fas fa-times"
                ></span>
              ) : (
                <span
                  onClick={() => this.setState({ filter: !this.state.filter })}
                  className="fas fa-search"
                ></span>
              )}
            </div>
          </div>
          {!this.props.loader && this.props.teams ? (
            !this.state.filter ? (
              totalPages > 0 ? (
                <div className="ct-grid-container">
                  {selectedNFTs.map((nft, index) => {
                    return (
                      <StaticTeamNFT
                        nft={nft}
                        key={index + "-" + nft.id}
                        token={this.props.token}
                        teams={this.props.teams}
                        ultraSmall={this.props.ultraSmall}
                        selected={
                          nft.id === this.props.selected?.id ||
                          !!this.props.selection?.find(
                            (selected) => selected.id === nft.id
                          )
                        }
                        travel={this.props.travel}
                        onClick={
                          this.props.onClick
                            ? (nft) =>
                                this.props.onClick
                                  ? this.props.onClick(nft)
                                  : undefined
                            : undefined
                        }
                      />
                    );
                  })}
                  <div className="ct-pagination">
                    {this.props.page > 0 ? (
                      <span
                        className="fas fa-chevron-left"
                        onClick={() => {
                          let momentPage: number = this.props.page;
                          if (momentPage > 0) momentPage--;
                          if (this.props.onChangePage) {
                            this.props.onChangePage(momentPage);
                          }
                        }}
                      ></span>
                    ) : (
                      ""
                    )}
                    <span>{this.props.page + 1}</span>
                    {this.props.page < totalPages - 1 ? (
                      <span
                        className="fas fa-chevron-right"
                        onClick={() => {
                          let momentPage: number = this.props.page;
                          if (momentPage < totalPages - 1) momentPage++;
                          if (this.props.onChangePage) {
                            this.props.onChangePage(momentPage);
                          }
                        }}
                      ></span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="ct-without-data">
                  <div className="spinner"></div>
                  {totalPages === 0 ? <p>You dont have teams.</p> : ""}
                </div>
              )
            ) : (
              <div className="ct-filters">
                <h5>Filters</h5>
                <div className="ct-filter">
                  <label htmlFor="points">Points</label>
                  <input
                    type="number"
                    onChange={(e) =>
                      this.setState({
                        properties: {
                          ...this.state.properties,
                          points: e.target.value !== "" ? e.target.value : null,
                        },
                      })
                    }
                  />
                </div>
                <div className="ct-filter ct-sort-filter">
                  <label htmlFor="sort-by">Sort by</label>
                  <label htmlFor="points">
                    <input
                      type="radio"
                      checked={this.state.points}
                      name="points-sort"
                      onChange={(e) =>
                        this.setState({
                          points: e.target.checked,
                          id: !e.target.checked,
                        })
                      }
                    />
                    Points
                  </label>
                  <label htmlFor="id-sort">
                    <input
                      type="radio"
                      checked={this.state.id}
                      name="id-sort"
                      onChange={(e) =>
                        this.setState({
                          id: e.target.checked,
                          points: !e.target.checked,
                        })
                      }
                    />
                    ID
                  </label>
                </div>
                <div className="ct-buttons">
                  <button
                    onClick={() =>
                      this.setState({ filter: !this.state.filter })
                    }
                    className="ct-main-button"
                  >
                    Search (Total NFTs: {filteredNFTs.length})
                  </button>
                  <button
                    className="ct-main-button"
                    onClick={() =>
                      this.setState({
                        properties: {
                          points: null,
                          id: null,
                        },
                      })
                    }
                  >
                    Reset
                  </button>
                </div>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
