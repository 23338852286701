import { AbstractCities } from "../abstracts/cities";
import { ContractBaseModel } from "../abstracts/contract";
import { appConfig, AppErrorCode } from "../app";

export class Cities extends ContractBaseModel<AbstractCities> {
  name() {
    return this.contract.name().call(this.config);
  }

  symbol() {
    return this.contract.symbol().call(this.config);
  }

  totalSupply() {
    return this.contract.totalSupply().call(this.config);
  }

  balanceOf(owner: string) {
    return this.contract.balanceOf(owner).call(this.config);
  }

  ownerOf(id: number) {
    return this.contract.ownerOf(id).call(this.config);
  }

  tokenURI(id: number) {
    return this.contract.tokenURI(id).call(this.config);
  }

  approve(to: string, id: number) {}

  getApproved(id: number) {
    return this.contract.getApproved(id).call(this.config);
  }

  safeTransferFrom(from: string, to: string, id: number) {}

  burn(id: number) {}

  getCustomerCities() {
    return this.contract
      .getCustomerCities(this.selectedAccount)
      .call(this.config);
  }

  getData(id: number) {
    return this.contract.getData(id).call(this.config);
  }

  getPoints(id: number) {
    return this.contract.getPoints(id).call(this.config);
  }

  hasUniversity(id: number) {
    return this.contract.hasUniversity(id).call(this.config);
  }

  hasTownHall(id: number) {
    return this.contract.hasTownHall(id).call(this.config);
  }

  setApprovalForAll(operator: string, approved: boolean) {}

  isApprovedForAll(owner: string, operator: string) {
    return this.contract.isApprovedForAll(owner, operator).call(this.config);
  }

  getLands(id: number) {
    return this.contract.getLands(id).call(this.config);
  }

  changeName(
    city: number,
    name: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .changeName(city, name)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }
}
