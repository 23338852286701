import React from "react";
import { AppErrorCode } from "../../core/app";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { FactoriesController } from "../../core/modules/factories";
import { MarketplaceController } from "../../core/modules/marketplace";
import { CityDeployerController } from "../../core/modules/citiesDeployer";
import { CitiesController } from "../../core/modules/cities";
import { CityBuilderActions } from "../organisms/builder/cities/actions";
import { BuilderCitiesGrid } from "../organisms/builder/cities/grid";
import { TeamLeader } from "../organisms/teamLeader";

interface CitiesBuilderComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface CitiesBuilderComponentState {
  cities: null | CitiesController;
  citiesDeployer: null | CityDeployerController;
  factories: null | FactoriesController;
  token: null | TokenController;
  marketplace: null | MarketplaceController;
  factoriesLoader: boolean;
  citiesLoader: boolean;
  citiesPage: number;
  factoriesReload: boolean;
}

export class CitiesBuilderPage extends React.PureComponent<CitiesBuilderComponentProps, CitiesBuilderComponentState> {
  constructor(props: CitiesBuilderComponentProps) {
    super(props);

    this.state = {
      cities: null,
      citiesDeployer: null,
      token: null,
      marketplace: null,
      factories: null,
      factoriesLoader: false,
      citiesLoader: false,
      citiesPage: 0,
      factoriesReload: false,
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.props.onToggleLoader(false);
  }

  async preloadControllers() {
    let factories = null;
    let cities = null;
    let token = null;
    let citiesDeployer = null;
    let marketplace = null;

    //Load Factories info
    if (this.props.blockChain.controller?.factories && this.props.blockChain.controller?.cityRelationsStorage && this.props.appState.appData) {
      factories = new FactoriesController(
        this.props.blockChain.controller.factories,
        this.props.blockChain.controller.cityRelationsStorage,
        this.props.appState.appData
      );
      await factories.loadFactoriesData();
    }

    if (this.props.blockChain.controller?.cities && this.props.blockChain.controller?.cityRelationsStorage && this.props.appState.appData) {
      cities = new CitiesController(
        this.props.blockChain.controller.cities,
        this.props.blockChain.controller.cityRelationsStorage,
        this.props.appState.appData
      );

      await cities.loadCitiesData();
    }

    //Load Token info
    if (this.props.blockChain.controller?.token && this.props.appState.appData) {
      token = new TokenController(this.props.blockChain.controller.token);
    }

    //Load Factory deployer info
    if (this.props.blockChain.controller?.citiesDeployer && this.props.appState.appData) {
      citiesDeployer = new CityDeployerController(this.props.blockChain.controller.citiesDeployer);
    }

    //Load Marketplace info
    // if (this.props.blockChain.controller?.marketplace) {
    //   marketplace = new MarketplaceController(
    //     this.props.blockChain.controller.marketplace
    //   );
    // }

    this.setState({
      factories,
      token,
      cities,
      citiesDeployer,
      marketplace,
    });
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.props.onLoadCustomerData(true);
    this.preloadControllers();
  }

  render() {
    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} /> : ""}
        <div className="ct-max-container ct-builder-page">
          <div className="ct-factories">
            {this.props.blockChain.controller &&
            this.props.blockChain.customer &&
            this.props.appState.appData &&
            this.props.blockChain.controller.factories &&
            !this.state.factoriesReload ? (
              <CityBuilderActions
                blockChain={this.props.blockChain.controller}
                appData={this.props.appState.appData}
                onMint={(name: string, factories: number[]) => {
                  if (this.props.blockChain.controller) {
                    this.props.onToggleLoader(true);
                    this.state.citiesDeployer?.mintCity(name, factories, (error: AppErrorCode | null) => {
                      this.onUpdateData(error);
                    });
                  }
                }}
              />
            ) : (
              ""
            )}

            {this.props.appState.appData && this.state.cities && this.state.token ? (
              <BuilderCitiesGrid
                nftsPerPage={8}
                validateRelations={false}
                citiesLoader={this.state.citiesLoader}
                token={this.state.token}
                cities={this.state.cities}
                appData={this.props.appState.appData}
                page={this.state.citiesPage}
                onUpdate={() => this.onUpdateData(null)}
                travel
                onChangePage={(page: number) => this.setState({ citiesPage: page })}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
