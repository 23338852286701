import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractMultiEmployeeDeployer } from "../abstracts/multiEmployeeDeployer";

export class MultiEmployeeDeployer extends ContractBaseModel<AbstractMultiEmployeeDeployer> {
  getEmployeesData() {
    return this.contract.getEmployeesData().call(this.config);
  }

  mintEmployee(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintEmployee()
      .send({
        ...this.config,
      })
      .on("receipt", (receipt: any) => this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback))
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback);
      });
  }

  randomize(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .randomize()
      .send({
        ...this.config,
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) => {
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback);
      });
  }
}
