import { AbstractCityRelations } from "../abstracts/cityRelations";
import { ContractBaseModel } from "../abstracts/contract";
import { appConfig, AppErrorCode } from "../app";

export class CityRelations extends ContractBaseModel<AbstractCityRelations> {
  getOwnerRelationRewards(city: number, factory: number, owner: string) {
    return this.contract.getOwnerRelationRewards(city, factory, owner).call(this.config);
  }

  withdrawFromFactoryRelation(city: number, factory: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawFromFactoryRelation(city, factory)
      .send({
        ...this.config,
        value: "10000000000000000",
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINT_ERROR, error, callback));
  }
}
