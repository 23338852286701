import { ContractBaseModel } from "../abstracts/contract";
import { AbstractTeamBuilder } from "../abstracts/teamBuilder";
import { appConfig, AppErrorCode } from "../app";

export class TeamBuilder extends ContractBaseModel<AbstractTeamBuilder> {
  createTeamFee() {
    return this.contract.createTeamFee().call(this.config);
  }

  brokeTeam(
    id: number,
    value: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .brokeTeam(id)
      .send({
        ...this.config,
        value,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  createEmployeesTeam(
    employee: number[],
    value: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    console.log(employee);
    return this.contract
      .createEmployeesTeam(employee)
      .send({
        ...this.config,
        value,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  validateMiniEmployeesTeam(employee: number[]) {
    return this.contract.validateMiniEmployeesTeam(employee).call(this.config);
  }

  createMiniEmployeesTeam(
    employee: number[],
    value: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .createMiniEmployeesTeam(employee)
      .send({
        ...this.config,
        value,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  updateEmployeesTeam(
    id: number,
    employee: number[],
    value: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .updateEmployeesTeam(id, employee)
      .send({
        ...this.config,
        value,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }
}
