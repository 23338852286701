import React from "react";

import NuclearEmployee from "../../../../assets/images/employees/1_1_1_1.png";
import WaterEmployee from "../../../../assets/images/employees/2_2_2_2.png";
import FoodEmployee from "../../../../assets/images/employees/3_3_3_3.png";
import CoalEmployee from "../../../../assets/images/employees/4_4_4_4.png";
import WoodEmployee from "../../../../assets/images/employees/5_5_5_5.png";

import { UtilsHelpers } from "../../../../core/helpers/utils";
import { AppData } from "../../../../core/types";

import "./actions.styles.css";

export interface BuilderActionsProps {
  appData: AppData;
  employeeDeployerAllowance: number;
  onMint?: (type: number) => void;
  onToggleFilter?: () => void;
  onApprove: () => void;
}

export const imageByType: { [type: number]: string } = {
  1: NuclearEmployee,
  2: WaterEmployee,
  3: FoodEmployee,
  4: CoalEmployee,
  5: WoodEmployee,
};

export const BuilderActions = (props: BuilderActionsProps) => {
  return (
    <React.Fragment>
      <div className="ct-builder-actions">
        <div className="ct-minting">
          <div className="ct-info">
            <h1>Mint NFTs</h1>
            <small>Build your NFT empire.</small>
            <small>Generate random employee. Only the head is secure.</small>
          </div>
          <div className="ct-actions">
            {props.appData.employeesData.employeePrice <=
            props.employeeDeployerAllowance ? (
              <button
                className="ct-main-button"
                onClick={() =>
                  props.onMint
                    ? props.onMint(
                        Number(
                          props.appData.employeesData.buildTypes[
                            Math.floor(
                              Math.random() *
                                props.appData.employeesData.buildTypes.length
                            )
                          ]
                        )
                      )
                    : null
                }
              >
                {"Random Mint (" +
                  (props.appData.employeesData.employeePrice || 0) +
                  " " +
                  props.appData.customerToken.loadTokenSymbol +
                  ")"}
              </button>
            ) : (
              <button
                onClick={() => props.onApprove()}
                className="ct-main-button"
              >
                Approve Mint
              </button>
            )}
            <a
              href="https://docs.businessbuilders.city/ecosystems/polygon/employees-nfts"
              target="_blank"
              rel="noopener noreferrer"
              className="ct-main-button"
            >
              <span className="fas fa-file-alt"></span>Documentation
            </a>
          </div>
        </div>
        <div className="ct-selector">
          <div className="ct-employee-types">
            {props.appData.employeesData.buildTypes
              ? props.appData.employeesData.buildTypes.map(
                  (type: string, index) => {
                    return (
                      <div key={index} className="ct-mint-employee-type">
                        <h4>{UtilsHelpers.getTypeName(Number(type))}</h4>
                        <div className="ct-image">
                          <img src={imageByType[Number(type)]} alt="" />
                        </div>
                        {props.appData.employeesData.employeePrice <=
                        props.employeeDeployerAllowance ? (
                          <button
                            onClick={() =>
                              props.onMint ? props.onMint(Number(type)) : null
                            }
                            className="ct-main-button"
                          >
                            {UtilsHelpers.getTypeName(Number(type))} Mint <br />{" "}
                            ({props.appData.employeesData.employeePrice || 0}{" "}
                            {props.appData.customerToken.loadTokenSymbol})
                          </button>
                        ) : (
                          <button
                            onClick={() => props.onApprove()}
                            className="ct-main-button"
                          >
                            Approve Mint
                          </button>
                        )}
                        <small>
                          Points per equal part:{" "}
                          {props.appData.employeesData.calcTypePoints(type)}
                        </small>
                        <small>
                          {UtilsHelpers.getTypeName(Number(type))} part chance{" "}
                          {props.appData.employeesData.getTypeProbability(type)}
                          %
                        </small>
                      </div>
                    );
                  }
                )
              : ""}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
