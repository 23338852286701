import { appConfig, AppMode } from "../app";

export class UtilsHelpers {
  static toHexadecimal(number: string) {
    if (!isNaN(Number(number))) return parseInt(number, 16).toString();
    else console.log("[UTILS] To Hexadecimal error.");
  }

  static normalizeWei(wei: string) {
    return !isNaN(Number(wei)) ? Number(wei) / Math.pow(10, 18) : NaN;
  }

  static debugger(log: string) {
    if (
      appConfig.mode === AppMode.DEV ||
      appConfig.mode === AppMode.TEST ||
      appConfig.mode === AppMode.TEST_METER ||
      appConfig.mode === AppMode.TEST_POLYGON
    ) {
      console.warn("[DEBUG] " + log);
    }
  }

  static parseSeconds(timeInSeconds: number) {
    const minutes = timeInSeconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    return timeInSeconds < 60
      ? timeInSeconds.toFixed() + " Seconds"
      : minutes < 60
      ? minutes.toFixed() + " Minutes"
      : hours < 48
      ? hours.toFixed() + " Hours"
      : days.toFixed() + " Days";
  }

  static timestampToHours(miliseconds: number) {
    return (miliseconds / 1000 / 60 / 60).toFixed(1);
  }

  static parseMoney(money: number) {
    return Intl.NumberFormat().format(money);
  }

  static getTypesArray() {
    return [1, 2, 3, 4, 5];
  }

  static getTypeName(typeNumber: number | undefined) {
    switch (typeNumber) {
      case 1:
        return "nuclear";
      case 2:
        return "water";
      case 3:
        return "food";
      case 4:
        return "coal";
      case 5:
        return "wood";
      case 6:
        return "iron";
      default:
        return "wood";
    }
  }

  static shuffle(array: any[]) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
}
