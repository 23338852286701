import { createStore, combineReducers, applyMiddleware } from "redux";
import { loadContractAbi } from "./middlewares/loadContract";
import { applicationKey, applicationReducer } from "./state/app/state";
import {
  blockChainReducer,
  blockChainReducerKey,
} from "./state/blockChain/state";
import { contractsKey, contractsReducer } from "./state/contracts/state";
import { relationsGameKeyKey, relationsGameReducer } from "./state/game/state";
import {
  studentsGameKeyKey,
  studentsGameReducer,
} from "./state/students/state";
import { tutorialsKey, tutorialsReducer } from "./state/tutorials/state";

export const Store = createStore(
  combineReducers({
    [contractsKey]: contractsReducer,
    [blockChainReducerKey]: blockChainReducer,
    [applicationKey]: applicationReducer,
    [relationsGameKeyKey]: relationsGameReducer,
    [studentsGameKeyKey]: studentsGameReducer,
    [tutorialsKey]: tutorialsReducer,
  }),
  applyMiddleware(loadContractAbi)
);

export default Store;
