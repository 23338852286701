import { EmployeeNFT } from "../../../core/nfts/employee";
import { AppActions } from "../../types";

export const changeSelectedEmployee = (employee: EmployeeNFT | undefined) => {
  return { type: AppActions.STUDENTS_CHANGE_SELECTED_EMPLOYEE, employee };
};

export const removeSelectedEmployee = (employee: EmployeeNFT) => {
  return { type: AppActions.STUDENTS_REMOVE_SELECTED_EMPLOYEE, employee };
};

export const onClearEmployeeSelection = () => {
  return { type: AppActions.STUDENTS_CLEAR_SELECTED_EMPLOYEE };
};
