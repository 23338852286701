import React from "react";
import { AppErrorCode, Contract } from "../../../../core/app";
import { MiniEmployees } from "../../../../core/contracts/miniEmployees";
import { UtilsHelpers } from "../../../../core/helpers/utils";
import { EmployeesController } from "../../../../core/modules/employees";
import { TokenController } from "../../../../core/modules/token";
import { EmployeeNFT } from "../../../../core/nfts/employee";

import "./employee.styles.css";

export interface StaticNFTProps {
  small?: boolean;
  nft: EmployeeNFT;
  className?: string;
  staticImage?: boolean;
  withGameData?: boolean;
  withMiniEmployeesData?: boolean;
  token?: TokenController;
  employees?: EmployeesController;
  miniEmployees?: MiniEmployees;
  selected?: boolean;
  selectedClass?: string;
  selectionIcon?: string;
  deactivated?: boolean;
  onSelect?: (employee: EmployeeNFT) => void;
  onBurn?: () => void;
  onClick?: (nft: EmployeeNFT) => void;
  onSendToStake?: () => void;
  onRemoveFromStake?: () => void;
  onSell?: (price: number) => void;
  onTransfer?: (to: string) => void;
  onUpgrade?: (nft: EmployeeNFT) => void;
  onRandomize?: (nft: EmployeeNFT) => void;
}

enum NFTMessages {
  SEND_TO_STAKE = "send-to-stake",
  REMOVE_FROM_STAKE = "remove-from-stake",
  BURN = "burn",
  TRANSFER = "transfer",
  SELL = "sell",
}

export interface StaticEmployeeNFTState {
  actionMessage: null | string;
  sellPrice: string;
  approvedForMarketplace: boolean;
  approvedForStake: boolean;
  loader: boolean;
  approvedTokens: { [address: string]: number };
  error: AppErrorCode | null;
  xp: number;
  merges: number;
}

export class StaticEmployeeNFT extends React.Component<StaticNFTProps, StaticEmployeeNFTState> {
  constructor(props: StaticNFTProps) {
    super(props);

    this.state = {
      actionMessage: null,
      sellPrice: "",
      approvedForMarketplace: false,
      approvedForStake: false,
      approvedTokens: {},
      loader: false,
      error: null,
      xp: 0,
      merges: 0,
    };
  }

  async componentDidMount() {
    await this.reloadEmployeeData(true);
  }

  // async approveForMarketplace() {
  //   if (!this.state.approvedForMarketplace && this.props.employees) {
  //     this.setState({ loader: true });
  //     this.props.employees.approveEmployee(
  //       this.props.nft.appData.contractsAddress[Contract.MARKETPLACE],
  //       this.props.nft.id,
  //       false,
  //       false,
  //       (error: AppErrorCode | null) => {
  //         if (error) this.setState({ error, loader: false });
  //         else {
  //           this.props.employees?.contract
  //             .getApproved(this.props.nft.id)
  //             .then((approved: string) => {
  //               if (typeof approved === "string") {
  //                 const isApproved =
  //                   approved ===
  //                   this.props.nft.appData.contractsAddress[
  //                     Contract.MARKETPLACE
  //                   ];

  //                 this.setState({
  //                   loader: false,
  //                   approvedForMarketplace: isApproved,
  //                   approvedForStake: !isApproved,
  //                 });
  //               }
  //             });
  //         }
  //       }
  //     );
  //   }
  // }

  async approveTokens(address: string, amount: number) {
    if (this.props.token) {
      this.setState({ loader: true }, () => {
        this.props.token?.approveTokenSpend(address, amount, (error: AppErrorCode | null) => {
          if (error) this.setState({ error, loader: false });
          else this.reloadEmployeeData(false);
        });
      });
    }
  }

  async reloadEmployeeData(remove: boolean) {
    this.setState({ loader: true }, async () => {
      if (!this.props.nft.isMiniEmployee && !this.props.nft.isMultiEmployee) {
        const merges = await this.props.employees?.employeesExpanded.getMergeRecord(this.props.nft.id);

        this.setState({
          loader: false,
          actionMessage: remove ? null : this.state.actionMessage,
          approvedTokens: {},
          merges,
        });
      } else {
        const xp = this.props.miniEmployees ? await this.props.miniEmployees.getXP(this.props.nft.id) : 0;

        this.setState({
          loader: false,
          actionMessage: remove ? null : this.state.actionMessage,
          xp,
        });
      }
    });
  }

  async openStakingInfo() {
    this.setState({
      loader: false,
      actionMessage: NFTMessages.SEND_TO_STAKE,
    });
  }

  async openMarketplaceInfo() {
    this.setState({
      loader: false,
      actionMessage: NFTMessages.SELL,
    });
  }

  render() {
    let selectedMessage = null;

    switch (this.state.actionMessage) {
      default:
        selectedMessage = null;
        break;
    }

    return (
      <div
        onClick={() => (this.props.onClick ? this.props.onClick(this.props.nft) : null)}
        className={
          "ct-nft" +
          (this.props.className ? this.props.className : "") +
          (this.props.small ? " ct-small" : "") +
          (this.props.nft.isStaked ? " ct-staked" : "") +
          (this.props.selected ? (this.props.selectedClass ? " " + this.props.selectedClass : " ct-selected") : "") +
          (this.props.nft.isMultiEmployee ? " bb" : "") +
          (this.props.nft.isMiniEmployee ? " ct-mini-employee" : "")
        }
      >
        {this.state.loader ? (
          <div className="ct-loader">
            <div className="spinner"></div>
            {this.state.error ? <small>Network error, please wait a minute and reload the list.</small> : ""}
          </div>
        ) : (
          ""
        )}
        {this.props.nft.isStaked ? <span className="ct-staked-icon fas fa-user-lock"></span> : ""}
        <div className="ct-image">
          <img src={this.props.staticImage ? this.props.nft.uri : this.props.nft.uri + "&onlyImage=true"} alt="" />
        </div>
        <div className="ct-data">
          <span>
            <strong>ID: #</strong> {this.props.nft.id}
          </span>
          <span>
            <strong>Head: </strong> {UtilsHelpers.getTypeName(this.props.nft.head)}
          </span>
          <span>
            <strong>Body: </strong> {UtilsHelpers.getTypeName(this.props.nft.body)}
          </span>
          <span>
            <strong>Legs: </strong> {UtilsHelpers.getTypeName(this.props.nft.legs)}
          </span>
          <span>
            <strong>Hands: </strong> {UtilsHelpers.getTypeName(this.props.nft.hands)}
          </span>
          {this.props.nft.points ? (
            <span>
              <strong>Points: </strong> {this.props.nft.points}
            </span>
          ) : (
            ""
          )}
          {this.props.nft.isMultiEmployee ? (
            <span>
              <strong>MultiEmployee: </strong> true
            </span>
          ) : (
            ""
          )}
          {this.props.nft.isMiniEmployee ? (
            <span>
              <strong>XP: </strong> {this.state.xp} / {this.props.nft.necessaryXP}
            </span>
          ) : (
            ""
          )}
          {!this.props.nft.isMiniEmployee && !this.props.nft.isMultiEmployee && this.props.withMiniEmployeesData ? (
            <span>
              <strong>Merges: </strong> {this.state.merges} / {this.props.nft.appData.miniEmployeesData.maxMerges}
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="ct-actions-container">
          <div className="ct-actions">
            {this.props.onTransfer ? (
              <button className="ct-main-button" onClick={() => (this.props.onTransfer ? this.props.onTransfer("") : null)}>
                <span className="fas fa-send"></span>
              </button>
            ) : (
              " "
            )}
            {/* {this.props.onRandomize && this.props.nft.isMultiEmployee ? (
              <button
                className="ct-main-button"
                onClick={() => (this.props.onRandomize ? this.props.onRandomize(this.props.nft) : null)}
              >
                <span className="fas fa-random"></span>
              </button>
            ) : (
              ""
            )} */}
            {this.props.onSendToStake && !this.props.nft.isStaked && !this.props.nft.isMultiEmployee ? (
              <button
                disabled={this.props.nft.isStaked}
                className={"ct-main-button" + (this.state.actionMessage === NFTMessages.SEND_TO_STAKE ? " ct-active" : "")}
                onClick={() =>
                  this.state.actionMessage === NFTMessages.SEND_TO_STAKE ? this.setState({ actionMessage: null }) : this.openStakingInfo()
                }
              >
                {this.state.actionMessage === NFTMessages.SEND_TO_STAKE ? (
                  <span className="fas fa-times"></span>
                ) : (
                  <span className="fas fa-share-square"></span>
                )}
              </button>
            ) : (
              " "
            )}
            {this.props.onSell && !this.props.nft.isStaked ? (
              <button
                disabled={this.props.nft.isStaked}
                className={"ct-main-button" + (this.state.actionMessage === NFTMessages.SELL ? " ct-active" : "")}
                onClick={() => (this.state.actionMessage === NFTMessages.SELL ? this.setState({ actionMessage: null }) : this.openMarketplaceInfo())}
              >
                {this.state.actionMessage === NFTMessages.SELL ? <span className="fas fa-times"></span> : <span className="fas fa-store-alt"></span>}
              </button>
            ) : (
              " "
            )}
            {this.props.onRemoveFromStake && this.props.nft.isStaked && !this.props.nft.isMultiEmployee ? (
              <button
                className={"ct-main-button" + (this.state.actionMessage === NFTMessages.REMOVE_FROM_STAKE ? " ct-active" : "")}
                onClick={() =>
                  this.state.actionMessage === NFTMessages.REMOVE_FROM_STAKE
                    ? this.setState({ actionMessage: null })
                    : this.setState({
                        actionMessage: NFTMessages.REMOVE_FROM_STAKE,
                      })
                }
              >
                {this.state.actionMessage === NFTMessages.REMOVE_FROM_STAKE ? (
                  <span className="fas fa-times"></span>
                ) : (
                  <span className="fas fa-lock-open"></span>
                )}
              </button>
            ) : (
              " "
            )}
            {this.props.onBurn ? (
              <button
                className={"ct-main-button" + (this.state.actionMessage === NFTMessages.BURN ? " ct-active" : "")}
                onClick={() =>
                  this.state.actionMessage === NFTMessages.BURN
                    ? this.setState({ actionMessage: null })
                    : this.setState({ actionMessage: NFTMessages.BURN })
                }
              >
                {this.state.actionMessage === NFTMessages.BURN ? <span className="fas fa-times"></span> : <span className="fas fa-trash"></span>}
              </button>
            ) : (
              " "
            )}
            {this.props.onSelect ? (
              <button className="ct-main-button" onClick={() => (this.props.onSelect ? this.props.onSelect(this.props.nft) : null)}>
                <span className={"fas " + (this.props.selectionIcon ? this.props.selectionIcon : "fa-hand-pointer")}></span>
              </button>
            ) : (
              ""
            )}
            {this.props.onUpgrade && this.props.nft.isMiniEmployee && !this.props.withGameData ? (
              <button
                className="ct-main-button"
                disabled={this.props.nft.necessaryXP > this.props.nft.xp}
                onClick={() => (this.props.onUpgrade ? this.props.onUpgrade(this.props.nft) : null)}
              >
                <span className="fas fa-level-up-alt"></span>
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="ct-action-message">{selectedMessage}</div>
        </div>
      </div>
    );
  }
}
