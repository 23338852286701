import React from "react";
import { BlockChain } from "../../../../core/chain";
import { EmployeeNFT } from "../../../../core/nfts/employee";

import { UtilsHelpers } from "../../../../core/helpers/utils";
import { EmployeesController } from "../../../../core/modules/employees";
import { AppData } from "../../../../core/types";
import { BuilderEmployeesGrid } from "../employees/grid";

import "./actions.styles.css";

export interface FactoryBuilderActionsProps {
  appData: AppData;
  blockChain: BlockChain;
  factoryDeployerAllowance: number;
  onMint?: (employees: number[]) => void;
  onToggleFilter?: () => void;
  onApprove: () => void;
}

export interface FactoryBuilderActionsState {
  selectedEmployees: Array<EmployeeNFT | null>;
  employeeSelection: boolean;
  employeeSelectionLoader: boolean;
  employees: EmployeesController | null;
  employeeSelectionPage: number;
}

export class FactoryBuilderActions extends React.Component<
  FactoryBuilderActionsProps,
  FactoryBuilderActionsState
> {
  constructor(props: FactoryBuilderActionsProps) {
    super(props);

    this.state = {
      employees: null,
      selectedEmployees: [null, null, null, null, null],
      employeeSelection: false,
      employeeSelectionLoader: true,
      employeeSelectionPage: 0,
    };
  }

  async openEmployeeSelection() {
    this.setState({ employeeSelectionLoader: true, employeeSelection: true });
    const employees = await this.loadEmployeesController();

    if (employees) {
      this.setState({ employees, employeeSelectionLoader: false });
    }
  }

  async loadEmployeesController() {
    let employees = null;

    if (
      this.props.blockChain.employees &&
      this.props.blockChain.miniEmployees &&
      this.props.blockChain.miniEmployeesDeployer &&
      this.props.blockChain.multiEmployees &&
      this.props.blockChain.nftBridgeStorage &&
      this.props.blockChain.employeesTeam &&
      this.props.blockChain.employeesExpanded
    ) {
      employees = new EmployeesController(
        this.props.blockChain.employees,
        this.props.blockChain.employeesExpanded,
        this.props.blockChain.miniEmployees,
        this.props.blockChain.miniEmployeesDeployer,
        this.props.blockChain.multiEmployees,
        this.props.blockChain.employeesTeam,
        this.props.blockChain.nftBridgeStorage,
        this.props.appData
      );

      await employees.loadFullEmployeesData(true);
    }

    return employees;
  }

  resetSelectedEmployees() {
    this.setState({ selectedEmployees: [null, null, null, null, null] });
  }

  removeEmployee(index: number) {
    const selectedEmployees = [...this.state.selectedEmployees];
    selectedEmployees[index] = null;
    this.setState({ selectedEmployees });
  }

  selectEmployee(employee: EmployeeNFT) {
    const selectedEmployees = [...this.state.selectedEmployees];

    for (let i = 0; i < selectedEmployees.length; i++) {
      if (selectedEmployees[i] === null) {
        selectedEmployees[i] = employee;
        break;
      }
    }

    this.setState({ selectedEmployees, employeeSelection: false });
  }

  getSelected() {
    const selected: EmployeeNFT[] = [];

    for (let i = 0; i < this.state.selectedEmployees.length; i++) {
      const employee: EmployeeNFT | null = this.state.selectedEmployees[i];
      if (employee !== null) selected.push(employee);
    }

    return selected;
  }

  getTotalPoints() {
    return this.getSelected().reduce(
      (total, portion) => (total += portion.points),
      0
    );
  }

  getPointsPerType() {
    const selected = this.getSelected();
    const types: { [type: string]: number } = {};

    for (let i = 0; i < selected.length; i++) {
      if (types[selected[i].head]) {
        types[selected[i].head] += selected[i].points;
      } else types[selected[i].head] = selected[i].points;
    }

    return types;
  }

  render() {
    const selectedEmployees = this.getSelected();
    const totalPoints = this.getTotalPoints();
    const pointsPerType = this.getPointsPerType();

    return (
      <React.Fragment>
        <div className="ct-builder-actions">
          <div className="ct-minting">
            <div className="ct-info">
              <h1>Mint NFTs</h1>
              <small>Build your NFT empire.</small>
              <small>Generate Factory.</small>
              <small>Next factory points: {totalPoints}</small>
              {Object.keys(pointsPerType).map((type, index) => {
                return (
                  <small key={index}>
                    <strong>{UtilsHelpers.getTypeName(Number(type))}</strong>{" "}
                    Factory: {pointsPerType[type]} / {totalPoints} ={" "}
                    {((pointsPerType[type] / totalPoints) * 100).toFixed(1)}%
                  </small>
                );
              })}
              {selectedEmployees.length <
              this.state.selectedEmployees.length ? (
                <strong>Add more employees to mint your factory</strong>
              ) : (
                ""
              )}
            </div>
            <div className="ct-actions">
              <a
                href="https://docs.businessbuilders.city/ecosystems/polygon/building"
                target="_blank"
                rel="noopener noreferrer"
                className="ct-main-button"
              >
                <span className="fas fa-file-alt"></span>Documentation
              </a>
              {selectedEmployees.length ? (
                <button
                  onClick={() => this.resetSelectedEmployees()}
                  className="ct-main-button"
                >
                  Remove selected employees
                </button>
              ) : (
                ""
              )}
              {selectedEmployees.length ===
                this.state.selectedEmployees.length && this.props.onMint ? (
                this.props.factoryDeployerAllowance >=
                this.props.appData.factoriesData.factoryPrice ? (
                  <button
                    onClick={() => {
                      if (this.props.onMint) {
                        this.props.onMint(
                          selectedEmployees.map((employee) => employee.id)
                        );
                        this.resetSelectedEmployees();
                      }
                    }}
                    className="ct-main-button"
                  >
                    Mint factory (5000 FTB)
                  </button>
                ) : (
                  <button
                    className="ct-main-button"
                    onClick={() => this.props.onApprove()}
                  >
                    Approve Mint (5000 FTB)
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="ct-selector">
            {!this.state.employeeSelection ? (
              <div className="ct-factory-types">
                {this.state.selectedEmployees.map(
                  (employee: EmployeeNFT | null, index: number) => {
                    return (
                      <div
                        key={index}
                        className={
                          "ct-mint-factory-type" +
                          (employee === null ? " ct-invalid" : "")
                        }
                      >
                        <h4>
                          {employee
                            ? UtilsHelpers.getTypeName(Number(employee.head))
                            : "None"}
                        </h4>
                        <div className="ct-selected-employee">
                          {employee ? (
                            <div className="ct-selected">
                              <img
                                src={employee.uri + "&onlyImage=true"}
                                alt=""
                              />
                              <span>ID: # {employee.id}</span>
                              <span>
                                Type:
                                {UtilsHelpers.getTypeName(employee.head)}
                              </span>
                              <span>Points: {employee.points}</span>
                            </div>
                          ) : (
                            <div className="ct-unselected">
                              <span>
                                Select employee seed to build your factory
                              </span>{" "}
                            </div>
                          )}
                        </div>
                        {employee ? (
                          <button
                            onClick={() => this.removeEmployee(index)}
                            className="ct-main-button"
                          >
                            Remove employee
                          </button>
                        ) : (
                          <button
                            className="ct-employee-selection ct-main-button"
                            onClick={() => this.openEmployeeSelection()}
                          >
                            Select employee seed
                          </button>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              <div className="ct-employees">
                <div className="ct-employees-grid">
                  {this.state.employees &&
                  this.props.blockChain.miniEmployees ? (
                    <BuilderEmployeesGrid
                      employeesLoader={this.state.employeeSelectionLoader}
                      page={this.state.employeeSelectionPage}
                      title={"Select Employee seed"}
                      subtitle={"Your employee will be burned"}
                      nftsPerPage={8}
                      appData={this.props.appData}
                      employees={this.state.employees}
                      small={true}
                      withoutMultiEmployees
                      onUpdate={() => this.openEmployeeSelection()}
                      onChangeEmployeesPage={(page: number) => {
                        this.setState({ employeeSelectionPage: page });
                      }}
                      onClose={() => {
                        this.setState({ employeeSelection: false });
                      }}
                      miniEmployeesContract={
                        this.props.blockChain.miniEmployees
                      }
                      removeSelection={selectedEmployees}
                      fullWith={true}
                      onSelect={(employee: EmployeeNFT) => {
                        this.selectEmployee(employee);
                      }}
                    />
                  ) : (
                    <div className="ct-employees-selector-loader">
                      <div className="spinner"></div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
