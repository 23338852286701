import React from "react";
import { AppErrorCode } from "../../../../core/app";
import { FactoryNFT } from "../../../../core/nfts/factories";
import { UtilsHelpers } from "../../../../core/helpers/utils";
import { FactoriesController } from "../../../../core/modules/factories";
import { TokenController } from "../../../../core/modules/token";

import "./factories.styles.css";

export interface StaticNFTProps {
  small?: boolean;
  deactivated?: boolean;
  selected?: boolean;
  ultraSmall?: boolean;
  nft: FactoryNFT;
  className?: string;
  staticImage?: boolean;
  token?: TokenController;
  factories?: FactoriesController;
  onSell?: (price: number) => void;
  onClick?: (factory: FactoryNFT) => void;
}

export interface StaticFactoriesNFTState {
  actionMessage: null | string;
  sellPrice: string;
  loader: boolean;
  hasRelation: boolean;
  error: AppErrorCode | null;
}

enum NFTMessages {
  SELL = "sell",
}

export class StaticFactoryNFT extends React.Component<
  StaticNFTProps,
  StaticFactoriesNFTState
> {
  constructor(props: StaticNFTProps) {
    super(props);

    this.state = {
      actionMessage: null,
      sellPrice: "",
      loader: false,
      error: null,
      hasRelation: false,
    };
  }

  componentDidMount() {
    this.reloadFactoryData();
  }

  async reloadFactoryData() {
    let hasRelation = false;

    if (this.props.factories) {
      hasRelation = await this.props.factories.storage.getFactoryState(
        this.props.nft.id
      );
    }

    this.setState({
      hasRelation,
      loader: false,
    });
  }

  render() {
    let selectedMessage = null;

    switch (this.state.actionMessage) {
      default:
        selectedMessage = null;
        break;
    }

    return (
      <div
        onClick={() =>
          this.props.onClick ? this.props.onClick(this.props.nft) : null
        }
        className={
          "ct-nft" +
          (this.props.className ? this.props.className : "") +
          (this.props.small ? " ct-small" : "") +
          (this.props.ultraSmall ? " ct-ultra-small" : "") +
          (this.props.onClick ? " ct-click" : "") +
          (this.props.deactivated ? " ct-un-click" : "") +
          (this.props.selected ? " ct-selected" : "") +
          (this.props.nft.isStaked ? " ct-staked" : "") +
          (this.state.hasRelation ? " ct-has-relation" : "")
        }
      >
        {this.state.loader ? (
          <div className="ct-loader">
            <div className="spinner"></div>
          </div>
        ) : (
          ""
        )}
        {this.props.nft.isStaked ? (
          <span className="ct-staked-icon fas fa-user-lock"></span>
        ) : (
          ""
        )}
        <div className="ct-image">
          <img
            src={
              this.props.staticImage
                ? this.props.nft.uri
                : this.props.nft.uri + "&onlyImage=true"
            }
            alt=""
          />
        </div>
        <div className="ct-data">
          <span>
            <strong>ID: #</strong> {this.props.nft.id}
          </span>
          <span>
            <strong>Type: </strong>{" "}
            {UtilsHelpers.getTypeName(this.props.nft.build)}
          </span>
          <span>
            <strong>Model: </strong> {this.props.nft.model}
          </span>
          {this.props.nft.points ? (
            <span>
              <strong>Points: </strong> {this.props.nft.points}
            </span>
          ) : (
            ""
          )}
          <span>
            <strong>City relation: </strong>{" "}
            {(!!this.state.hasRelation).toString()}
          </span>
        </div>
        <div className="ct-actions-container">
          <div className="ct-actions">
            {this.props.onSell ? (
              <button
                className={
                  "ct-main-button" +
                  (this.state.actionMessage === NFTMessages.SELL
                    ? " ct-active"
                    : "")
                }
                onClick={() =>
                  this.state.actionMessage === NFTMessages.SELL
                    ? this.setState({ actionMessage: null })
                    : this.setState({ actionMessage: NFTMessages.SELL })
                }
              >
                {this.state.actionMessage === NFTMessages.SELL ? (
                  <span className="fas fa-times"></span>
                ) : (
                  <span className="fas fa-store-alt"></span>
                )}
              </button>
            ) : (
              " "
            )}
          </div>
          <div className="ct-action-message">{selectedMessage}</div>
        </div>
      </div>
    );
  }
}
