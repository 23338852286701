import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractVoltLPRewards } from "../abstracts/voltLPRewards";

export class VoltLPRewards extends ContractBaseModel<AbstractVoltLPRewards> {
  getStakingData() {
    return this.contract.getStakingData().call(this.config);
  }

  participation(address: string) {
    return this.contract.participation(address).call(this.config);
  }

  requestMiniEmployeesWithLP(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .requestMiniEmployeesWithLP()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }
}
