import React from "react";
import { Link } from "react-router-dom";
import { navigationLinks } from "../../../core/navigation";

import "./left.styles.css";

interface LeftNavigationProps {
  open: boolean;
  onToggleLeftNavigation: () => void;
}

interface LeftNavigationState {}

export class LeftNavigation extends React.Component<
  LeftNavigationProps,
  LeftNavigationState
> {
  constructor(props: LeftNavigationProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className={"ct-left-nav" + (this.props.open ? " ct-open" : "")}>
        <div className="ct-left-nav-container">
          <div className="ct-links">
            {navigationLinks.map((parent, index) => {
              return (
                <div key={index} className="ct-link-parent">
                  <span>{parent.display}</span>
                  <div className="ct-parent-links">
                    {parent.links?.map((link, index) => {
                      if (link.type === "internal") {
                        if (
                          link.disabled ||
                          window.location.pathname === link.href
                        ) {
                          return (
                            <span
                              key={index}
                              className={
                                link.disabled ||
                                window.location.pathname === link.href
                                  ? "ct-disabled"
                                  : ""
                              }
                            >
                              <span>{link.display} </span>
                              <span className="fas fa-lock"></span>
                            </span>
                          );
                        } else {
                          return (
                            <Link
                              key={index}
                              onClick={() =>
                                this.props.onToggleLeftNavigation()
                              }
                              to={link.href}
                              className={link.disabled ? "ct-disabled" : ""}
                            >
                              <span>{link.display} </span>
                              <span className={link.icon}></span>
                            </Link>
                          );
                        }
                      } else {
                        return (
                          <a
                            key={index}
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={link.disabled ? "ct-disabled" : ""}
                          >
                            <span>{link.display}</span>
                            <span className={link.icon}></span>
                          </a>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ct-bottom">
            <div className="ct-tokens"></div>
            <div className="ct-social">
              <a href="https://instagram.com/businessbuildersnft">
                <span className="fab fa-instagram"></span>
              </a>
              <a href="https://twitter.com/nftbbuilders">
                <span className="fab fa-twitter"></span>
              </a>
              <a href="https://docs.businessbuilders.city">
                <span className="fas fa-passport"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
