import React from "react";
import Web3 from "web3";
import { AppErrorCode, Contract } from "../../core/app";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { UtilsHelpers } from "../../core/helpers/utils";
import { TeamLeader } from "../organisms/teamLeader";
import { EmployeeNFT } from "../../core/nfts/employee";
import { EmployeesController } from "../../core/modules/employees";
import { EmployeesTeamNFT } from "../../core/nfts/teams";
import { BuilderEmployeesGrid } from "../organisms/builder/employees/grid";
import { TeamGrid } from "../organisms/builder/teams/grid";

interface TeamBuilderComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface TeamBuilderComponentState {
  teams: null | EmployeesController;
  employees: null | EmployeesController;
  miniEmployees: null | EmployeesController;
  pageEmployees: number;
  pageMiniEmployees: number;
  pageTeams: number;
  approvedTokens: number;
  totalEmployeesInTeams: number;
  approvedMiniEmployees: boolean;
  approvedEmployees: boolean;
  selectedMiniEmployees: EmployeeNFT[];
  selectedEmployees: EmployeeNFT[];
  selectedTeam: EmployeesTeamNFT | null;
  createFee: string;
}

export class TeamBuilderPage extends React.PureComponent<TeamBuilderComponentProps, TeamBuilderComponentState> {
  constructor(props: TeamBuilderComponentProps) {
    super(props);

    this.state = {
      teams: null,
      employees: null,
      miniEmployees: null,
      pageEmployees: 0,
      pageMiniEmployees: 0,
      pageTeams: 0,
      approvedTokens: 0,
      totalEmployeesInTeams: 0,
      approvedMiniEmployees: false,
      approvedEmployees: false,
      selectedMiniEmployees: [],
      selectedEmployees: [],
      selectedTeam: null,
      createFee: "",
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetEmployeeTeamsController();
    this.loadAndSetMiniEmployeesController();
    this.loadAndSetEmployeesController();
    this.props.onToggleLoader(false);
  }

  async preloadControllers(closeLoader: boolean = false) {
    let approvedTokens = 0;
    let approvedMiniEmployees = false;
    let approvedEmployees = false;
    let createFee = "";
    let totalEmployeesInTeams = 0;

    if (
      this.props.blockChain.controller?.token &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.blockChain.controller.citiesDeployer
    ) {
      approvedTokens = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token.allowance(
            this.props.blockChain.controller.selectedAccount,
            this.props.blockChain.controller.citiesDeployer?.address
          ),
          "ether"
        )
      );
    }

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.blockChain.controller.teamBuilder &&
      this.props.appState.appData?.contractsAddress
    ) {
      approvedMiniEmployees = await this.props.blockChain.controller.miniEmployees.isApprovedForAll(
        this.props.appState.appData?.contractsAddress[Contract.TEAM_BUILDER]
      );

      approvedEmployees = await this.props.blockChain.controller.employees.isApprovedForAll(
        this.props.appState.appData?.contractsAddress[Contract.TEAM_BUILDER]
      );

      createFee = await this.props.blockChain.controller.teamBuilder.createTeamFee();

      totalEmployeesInTeams = await this.props.blockChain.controller.employeesTeam?.getTotalEmployeesOfCustomer();
    }

    this.setState(
      {
        approvedTokens,
        createFee,
        approvedMiniEmployees,
        approvedEmployees,
        totalEmployeesInTeams,
      },
      () => {
        if (closeLoader) this.props.onToggleLoader(false);
      }
    );
  }

  async loadAndSetEmployeeTeamsController() {
    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      const teams = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await teams.loadTeamsData();

      this.setState({ teams });
    }
  }

  async loadAndSetMiniEmployeesController() {
    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      const miniEmployees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await miniEmployees.loadMiniEmployeesData();

      this.setState({ miniEmployees });
    }
  }

  async loadAndSetEmployeesController() {
    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      const employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employees.loadFullEmployeesData();

      this.setState({ employees });
    }
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.props.onLoadCustomerData(true);
    this.preloadControllers();
  }

  render() {
    let miniEmployeesPoints = 0;
    let employeesPoints = 0;

    let miniEmployeesParts: { [key: string]: number } = {
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
    };

    let employeesParts: { [key: string]: number } = {
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
    };

    if (this.state.selectedMiniEmployees.length > 0) {
      for (let i = 0; i < this.state.selectedMiniEmployees.length; i++) {
        miniEmployeesPoints += this.state.selectedMiniEmployees[i].points;
        miniEmployeesParts[this.state.selectedMiniEmployees[i].hands.toString()] += 1;
        miniEmployeesParts[this.state.selectedMiniEmployees[i].legs.toString()] += 1;
        miniEmployeesParts[this.state.selectedMiniEmployees[i].body.toString()] += 1;
        miniEmployeesParts[this.state.selectedMiniEmployees[i].head.toString()] += 1;
      }
    }

    if (this.state.selectedEmployees.length > 0) {
      for (let i = 0; i < this.state.selectedEmployees.length; i++) {
        employeesPoints += this.state.selectedEmployees[i].points;
        employeesParts[this.state.selectedEmployees[i].hands.toString()] += 1;
        employeesParts[this.state.selectedEmployees[i].legs.toString()] += 1;
        employeesParts[this.state.selectedEmployees[i].body.toString()] += 1;
        employeesParts[this.state.selectedEmployees[i].head.toString()] += 1;
      }
    }

    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} /> : ""}
        <div className="ct-max-container ct-builder-page">
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>EMPLOYEES TEAM</h1>
                {this.state.selectedMiniEmployees.length > 0 ? (
                  <React.Fragment>
                    <div className="ct-mini-employees-team">
                      <h4>New Mini Employees Team</h4>
                      <span>
                        <strong>Total Points: </strong>
                        {miniEmployeesPoints}
                      </span>
                      {miniEmployeesParts && Object.keys(miniEmployeesParts).length > 0
                        ? Object.keys(miniEmployeesParts).map((type, index) => {
                            return (
                              <span key={index}>
                                <strong>{UtilsHelpers.getTypeName(Number(type))}: </strong>
                                {miniEmployeesParts[type] +
                                  "/" +
                                  this.state.selectedMiniEmployees.length * 4 +
                                  " = " +
                                  ((miniEmployeesParts[type] / (this.state.selectedMiniEmployees.length * 4)) * 100).toFixed(1) +
                                  "%"}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}

                {this.state.selectedEmployees.length > 0 ? (
                  <React.Fragment>
                    <div className="ct-mini-employees-team">
                      <h4>New Employees Team</h4>
                      <span>
                        <strong>Total Points: </strong>
                        {employeesPoints}
                      </span>
                      {employeesParts && Object.keys(employeesParts).length > 0
                        ? Object.keys(employeesParts).map((type, index) => {
                            return (
                              <span key={index}>
                                <strong>{UtilsHelpers.getTypeName(Number(type))}: </strong>
                                {employeesParts[type] +
                                  "/" +
                                  this.state.selectedEmployees.length * 4 +
                                  " = " +
                                  ((employeesParts[type] / (this.state.selectedEmployees.length * 4)) * 100).toFixed(1) +
                                  "%"}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}

                {this.state.selectedTeam ? (
                  <React.Fragment>
                    <div className="ct-mini-employees-team">
                      <h4>Selected {this.state.selectedTeam.isMini ? "Mini Employees Team" : "Employees Team"}</h4>
                      <span>
                        <strong>Mini:</strong> {this.state.selectedTeam.isMini.toString()}
                      </span>
                      <span>
                        <strong>Total Points:</strong> {this.state.selectedTeam.totalPoints}
                      </span>
                      <span>
                        <strong>Total Employees:</strong> {this.state.selectedTeam.totalEmployees}
                      </span>
                      <span>
                        <strong>Total Specials:</strong> {this.state.selectedTeam.totalSpecial}
                      </span>
                      <span>
                        <strong>Is Special:</strong>{" "}
                        {((this.state.selectedTeam.totalSpecial * 100) / this.state.selectedTeam.totalEmployees >= 50).toString()}
                      </span>
                      {this.state.selectedTeam.typesPart?.length > 0
                        ? this.state.selectedTeam.typesPart.map((part, index) => {
                            return (
                              <span key={index}>
                                <strong>{UtilsHelpers.getTypeName(index + 1)}:</strong> {part + " / " + this.state.selectedTeam?.totalEmployees}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}

                <a href="https://docs.businessbuilders.city/the-game/cities/minting" target="_blank" rel="noopener noreferrer">
                  Team documentation
                </a>
                <small className="ct-mt-5">Save more than 90% on network commissions when your team has more than 10 employees</small>
                <small className="ct-mt-5">Your team will be special when 50% o more employees in the team be special</small>
              </div>
              <div className="ct-actions">
                {this.state.selectedMiniEmployees.length > 0 ? (
                  this.state.approvedMiniEmployees ? (
                    <button
                      className="ct-main-button"
                      onClick={async () => {
                        if (this.props.blockChain.controller?.teamBuilder) {
                          this.props.blockChain.controller.teamBuilder.createMiniEmployeesTeam(
                            this.state.selectedMiniEmployees.map((nft) => nft.id),
                            this.state.createFee,
                            (error) => {
                              this.setState({ selectedMiniEmployees: [] }, () => {
                                this.onUpdateData(error);
                              });
                            }
                          );
                        }
                      }}
                    >
                      Create mini employees team
                    </button>
                  ) : (
                    <button
                      className="ct-main-button"
                      onClick={() => {
                        if (this.props.blockChain.controller?.miniEmployees && this.props.appState.appData?.contractsAddress) {
                          this.props.blockChain.controller.miniEmployees.setApprovalForAll(
                            this.props.appState.appData?.contractsAddress[Contract.TEAM_BUILDER],
                            (error) => {
                              this.onUpdateData(error);
                            }
                          );
                        }
                      }}
                    >
                      Approve mini employees
                    </button>
                  )
                ) : (
                  ""
                )}

                {this.state.selectedEmployees.length > 0 ? (
                  this.state.approvedEmployees ? (
                    <button
                      className="ct-main-button"
                      onClick={async () => {
                        if (this.props.blockChain.controller?.teamBuilder) {
                          this.props.blockChain.controller.teamBuilder.createEmployeesTeam(
                            this.state.selectedEmployees.map((nft) => nft.id),
                            this.state.createFee,
                            (error) => {
                              this.setState({ selectedEmployees: [] }, () => {
                                this.onUpdateData(error);
                              });
                            }
                          );
                        }
                      }}
                    >
                      Create employees team
                    </button>
                  ) : (
                    <button
                      className="ct-main-button"
                      onClick={() => {
                        if (this.props.blockChain.controller?.employees && this.props.appState.appData?.contractsAddress) {
                          this.props.blockChain.controller.employees.setApprovalForAll(
                            this.props.appState.appData?.contractsAddress[Contract.TEAM_BUILDER],
                            (error) => {
                              this.onUpdateData(error);
                            }
                          );
                        }
                      }}
                    >
                      Approve employees
                    </button>
                  )
                ) : (
                  ""
                )}

                {this.state.selectedTeam ? (
                  <button
                    className="ct-main-button"
                    onClick={() => {
                      if (this.props.blockChain.controller?.teamBuilder && this.state.selectedTeam) {
                        this.props.blockChain.controller.teamBuilder.brokeTeam(this.state.selectedTeam?.id, this.state.createFee, (error) => {
                          this.setState({ selectedTeam: null }, () => {
                            this.onUpdateData(error);
                          });
                        });
                      }
                    }}
                  >
                    Broke selected Team
                  </button>
                ) : (
                  ""
                )}

                {this.state.selectedEmployees.length > 0 ? (
                  this.state.approvedEmployees ? (
                    <button
                      className="ct-main-button"
                      onClick={async () => {
                        if (this.props.blockChain.controller?.teamBuilder && this.state.selectedTeam?.id) {
                          this.props.blockChain.controller.teamBuilder.updateEmployeesTeam(
                            this.state.selectedTeam?.id,
                            this.state.selectedEmployees.map((nft) => nft.id),
                            this.state.createFee,
                            (error) => {
                              this.setState(
                                {
                                  selectedEmployees: [],
                                  selectedTeam: null,
                                },
                                () => {
                                  this.onUpdateData(error);
                                }
                              );
                            }
                          );
                        }
                      }}
                    >
                      Ugrade employees team
                    </button>
                  ) : (
                    <button
                      className="ct-main-button"
                      onClick={() => {
                        if (this.props.blockChain.controller?.employees && this.props.appState.appData?.contractsAddress) {
                          this.props.blockChain.controller.employees.setApprovalForAll(
                            this.props.appState.appData?.contractsAddress[Contract.TEAM_BUILDER],
                            (error) => {
                              this.onUpdateData(error);
                            }
                          );
                        }
                      }}
                    >
                      Approve employees
                    </button>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="ct-mini-employees">
            {this.state.miniEmployees && this.props.appState.appData ? (
              <BuilderEmployeesGrid
                nftsPerPage={8}
                employeesLoader={false}
                employees={this.state.miniEmployees}
                appData={this.props.appState.appData}
                page={this.state.pageMiniEmployees}
                onlyMiniEmployees={true}
                miniEmployeesContract={this.state.miniEmployees.miniEmployees}
                onChangeEmployeesPage={(page: number) => this.setState({ pageMiniEmployees: page })}
                gameSelection={this.state.selectedMiniEmployees}
                onClick={(employee) => {
                  const searched = this.state.selectedMiniEmployees.findIndex((nft) => nft.id === employee.id);

                  if (searched === -1) {
                    const newState = [...this.state.selectedMiniEmployees];
                    newState.push(employee);
                    this.setState({ selectedMiniEmployees: newState });
                  } else {
                    const newState = [...this.state.selectedMiniEmployees];
                    newState.splice(searched, 1);
                    this.setState({ selectedMiniEmployees: newState });
                  }
                }}
                title="Mini Employees"
                subtitle={
                  "Select your mini Employees and compress it to create mini employees team. Selected: " + this.state.selectedMiniEmployees.length
                }
                onUpdate={() => this.loadAndSetMiniEmployeesController()}
              />
            ) : (
              ""
            )}
          </div>
          <div className="ct-employees">
            {this.state.employees && this.props.appState.appData ? (
              <BuilderEmployeesGrid
                nftsPerPage={8}
                employeesLoader={false}
                employees={this.state.employees}
                appData={this.props.appState.appData}
                page={this.state.pageEmployees}
                onChangeEmployeesPage={(page: number) => this.setState({ pageEmployees: page })}
                withoutMultiEmployees
                gameSelection={this.state.selectedEmployees}
                onClick={(employee) => {
                  const searched = this.state.selectedEmployees.findIndex((nft) => nft.id === employee.id);

                  if (searched === -1) {
                    const newState = [...this.state.selectedEmployees];
                    newState.push(employee);
                    this.setState({ selectedEmployees: newState });
                  } else {
                    const newState = [...this.state.selectedEmployees];
                    newState.splice(searched, 1);
                    this.setState({ selectedEmployees: newState });
                  }
                }}
                title="Employees"
                subtitle={"Select your Employees and compress it to create employees team. Selected: " + this.state.selectedEmployees.length}
                onUpdate={() => this.loadAndSetEmployeesController()}
              />
            ) : (
              ""
            )}
          </div>
          <div className="ct-teams">
            {this.state.teams && this.props.appState.appData ? (
              <TeamGrid
                nftsPerPage={8}
                loader={false}
                teams={this.state.teams}
                appData={this.props.appState.appData}
                page={this.state.pageTeams}
                onChangePage={(page: number) => this.setState({ pageTeams: page })}
                selection={this.state.selectedTeam ? [this.state.selectedTeam] : []}
                onClick={(team) => {
                  console.log(team);
                  this.setState({ selectedTeam: team });
                }}
                subtitle={"Select your team and improve it."}
                onUpdate={() => this.loadAndSetEmployeeTeamsController()}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
