import { ContractBaseModel } from "../abstracts/contract";
import { AbstractTeamBuilder } from "../abstracts/teamBuilder";
import { AbstractTeamBuilderStorage } from "../abstracts/teamBuilderStorage";
import { appConfig, AppErrorCode } from "../app";

export class TeamBuilderStorage extends ContractBaseModel<AbstractTeamBuilderStorage> {
  getPackageData(id: number) {
    return this.contract.getPackageData(id).call(this.config);
  }
}
