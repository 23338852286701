import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractMultiEmployees } from "../abstracts/multiEmployees";

export class MultiEmployees extends ContractBaseModel<AbstractMultiEmployees> {
  name() {
    return this.contract.name().call(this.config);
  }

  symbol() {
    return this.contract.symbol().call(this.config);
  }

  totalSupply() {
    return this.contract.totalSupply().call(this.config);
  }

  balanceOf(owner: string) {
    return this.contract.balanceOf(owner).call(this.config);
  }

  ownerOf(id: number) {
    return this.contract.ownerOf(id).call(this.config);
  }

  tokenURI(id: number) {
    return this.contract.tokenURI(id).call(this.config);
  }

  getApproved(id: number) {
    return this.contract.getApproved(id).call(this.config);
  }

  getCustomerEmployees() {
    return this.contract.getCustomerEmployees(this.selectedAccount).call();
  }

  safeTransferFrom(
    from: string,
    to: string,
    id: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .safeTransferFrom(from, to, id)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.SAFE_TRANSFER_FROM, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.SAFE_TRANSFER_FROM, error, callback)
      );
  }

  getEmployee(id: number) {
    return this.contract.getEmployee(id).call();
  }

  isApprovedForAll(operator: string) {
    return this.contract
      .isApprovedForAll(this.selectedAccount, operator)
      .call(this.config);
  }

  setApprovalForAll(
    operator: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .setApprovalForAll(operator, true)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.BURN_EMPLOYEE, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.BURN_EMPLOYEE, error, callback)
      );
  }

  approve(
    to: string,
    id: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .approve(to, id)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.APPROVE_EMPLOYEE, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.APPROVE_EMPLOYEE, error, callback)
      );
  }

  burn(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .burn(id)
      .send(this.config)
      .on("receipt", (receipt: any) =>
        this.defaultReceipt(AppErrorCode.BURN_EMPLOYEE, receipt, callback)
      )
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.BURN_EMPLOYEE, error, callback)
      );
  }
}
