import { AppErrorCode } from "../app";
import { MultiEmployeeDeployer } from "../contracts/multiEmployeeDeployer";
import { UtilsHelpers } from "../helpers/utils";

interface DeployerData {
  deployerPrice: number;
  points: number;
  deployerUSDPrice: number;
  specialToken: string;
}

export class MultiEmployeeDeployerController {
  public data: DeployerData | null = null;

  constructor(public contract: MultiEmployeeDeployer) {}

  public async loadDeployerData() {
    const data = await this.contract.getEmployeesData();

    if (data) {
      this.data = {
        deployerPrice: UtilsHelpers.normalizeWei(data.deployerPrice),
        points: Number(data.points),
        deployerUSDPrice: Number(data.deployerUSDPrice) / 1000000,
        specialToken: data.specialToken,
      };
    }
  }

  public mintEmployee(callback?: (error: AppErrorCode | null) => void) {
    UtilsHelpers.debugger("Mint Employee");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger("Try Mint NFT (" + this.contract.selectedAccount + ")");

      if (callback) this.contract.mintEmployee(callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }
}
