import React from "react";
import { BlockChain } from "../../../../core/chain";
import { AppData } from "../../../../core/types";
import { FactoryNFT } from "../../../../core/nfts/factories";
import { FactoriesController } from "../../../../core/modules/factories";
import { BuilderFactoriesGrid } from "../factories/grid";

import { UtilsHelpers } from "../../../../core/helpers/utils";
import { Contract } from "../../../../core/app";

import "./actions.styles.css";

export interface CityBuilderActionsProps {
  appData: AppData;
  blockChain: BlockChain;
  onMint?: (name: string, factories: number[]) => void;
  onToggleFilter?: () => void;
}

export interface CityBuilderActionsState {
  selectedFactories: Array<FactoryNFT>;
  factories: FactoriesController | null;
  factorySelection: boolean;
  factorySelectionLoader: boolean;
  factorySelectionPage: number;
  deployerAllowance: number;
  cityName: string;
}

export class CityBuilderActions extends React.Component<
  CityBuilderActionsProps,
  CityBuilderActionsState
> {
  constructor(props: CityBuilderActionsProps) {
    super(props);

    this.state = {
      selectedFactories: [],
      factories: null,
      factorySelection: false,
      factorySelectionLoader: false,
      factorySelectionPage: 0,
      deployerAllowance: 0,
      cityName: "",
    };
  }

  async componentDidMount() {
    let factories = null;
    let deployerAllowance = 0;

    if (
      this.props.blockChain.token &&
      this.props.appData.contractsAddress[Contract.CITIES_DEPLOYER]
    ) {
      deployerAllowance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.token.allowance(
          this.props.blockChain.token.selectedAccount,
          this.props.appData.contractsAddress[Contract.CITIES_DEPLOYER]
        )
      );
    }

    if (
      this.props.blockChain.factories &&
      this.props.blockChain.cityRelationsStorage
    ) {
      factories = new FactoriesController(
        this.props.blockChain.factories,
        this.props.blockChain.cityRelationsStorage,
        this.props.appData
      );

      await factories.loadFactoriesData();
    }

    this.setState({ deployerAllowance, factories });
  }

  resetSelectedFactories() {
    this.setState({ selectedFactories: [] });
  }

  removeFactory(factory: FactoryNFT) {
    const selectedFactories = [...this.state.selectedFactories];

    selectedFactories.splice(
      selectedFactories.findIndex((nft) => nft.id === factory.id),
      1
    );

    this.setState({ selectedFactories });
  }

  selectFactory(factory: FactoryNFT) {
    const selectedFactories = [...this.state.selectedFactories];

    const searchFactory = selectedFactories.find(
      (nft) => nft.id === factory.id
    );

    if (!searchFactory) selectedFactories.push(factory);

    this.setState({ selectedFactories });
  }

  getTotalPoints() {
    return this.state.selectedFactories.reduce(
      (total, portion) => (total += portion.points),
      0
    );
  }

  async openFactorySelection() {
    this.setState(
      { factorySelectionLoader: true, factorySelectionPage: 0 },
      async () => {
        await this.state.factories?.loadFactoriesData();
        this.setState({
          factorySelection: true,
          factorySelectionLoader: false,
        });
      }
    );
  }

  render() {
    const totalPoints = this.getTotalPoints();

    return (
      <React.Fragment>
        <div className="ct-builder-actions">
          <div className="ct-minting">
            <div className="ct-info">
              <h1>Mint City NFTs</h1>
              <small>Build your NFT empire.</small>
              <small>Generate City.</small>
              <small>Next city points: {totalPoints}</small>
              <small>Next city lands: {Math.floor(totalPoints / 20)}</small>
              {this.state.selectedFactories.length <= 0 ? (
                <strong>Add more factories to mint your city</strong>
              ) : (
                ""
              )}
            </div>
            <div className="ct-actions">
              <a
                href="https://docs.businessbuilders.city/ecosystems/polygon/building"
                target="_blank"
                rel="noopener noreferrer"
                className="ct-main-button"
              >
                <span className="fas fa-file-alt"></span>Documentation
              </a>
              {this.state.selectedFactories.length ? (
                <button
                  onClick={() => this.resetSelectedFactories()}
                  className="ct-main-button"
                >
                  Remove selected factories
                </button>
              ) : (
                ""
              )}
              {this.state.selectedFactories.length > 0 && this.props.onMint ? (
                <div className="ct-cities-minting">
                  <input
                    type="text"
                    placeholder="City name"
                    value={this.state.cityName}
                    onChange={(e) =>
                      this.setState({ cityName: e.target.value })
                    }
                  />
                  <button
                    disabled={!this.state.cityName}
                    onClick={() => {
                      if (this.props.onMint) {
                        this.props.onMint(
                          this.state.cityName,
                          this.state.selectedFactories.map(
                            (factory) => factory.id
                          )
                        );
                        this.resetSelectedFactories();
                      }
                    }}
                    className="ct-main-button"
                  >
                    Mint City
                  </button>
                  {!this.state.cityName ? (
                    <small>Your city needs a name</small>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="ct-selector">
            <div className="ct-employees">
              <div className="ct-employees-grid">
                {this.state.factories ? (
                  <BuilderFactoriesGrid
                    factoriesLoader={this.state.factorySelectionLoader}
                    page={this.state.factorySelectionPage}
                    title={"Select Factory seed"}
                    subtitle={"Your factories will be burned"}
                    nftsPerPage={8}
                    fullWidth
                    appData={this.props.appData}
                    factories={this.state.factories}
                    onUpdate={() => this.openFactorySelection()}
                    onChangeFactoriesPage={(page: number) => {
                      this.setState({ factorySelectionPage: page });
                    }}
                    gameSelection={this.state.selectedFactories}
                    onClick={async (factory: FactoryNFT) => {
                      const hasRelation =
                        await this.props.blockChain?.cityRelationsStorage?.getFactoryState(
                          factory.id
                        );

                      if (!hasRelation) {
                        if (
                          !!this.state.selectedFactories.find(
                            (nft) => nft.id === factory.id
                          )
                        ) {
                          this.removeFactory(factory);
                        } else this.selectFactory(factory);
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
