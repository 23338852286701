import React from "react";
import Web3 from "web3";
import { AppErrorCode, Contract } from "../../core/app";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { UtilsHelpers } from "../../core/helpers/utils";
import { TeamLeader } from "../organisms/teamLeader";
import { EmployeeNFT } from "../../core/nfts/employee";
import { EmployeesController } from "../../core/modules/employees";
import { EmployeesTeamNFT } from "../../core/nfts/teams";
import { BuilderEmployeesGrid } from "../organisms/builder/employees/grid";
import { TeamGrid } from "../organisms/builder/teams/grid";

interface TeamViewerComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface TeamViewerComponentState {
  teams: null | EmployeesController;
  employees: null | EmployeesController;
  miniEmployees: null | EmployeesController;
  pageEmployees: number;
  pageMiniEmployees: number;
  pageTeams: number;
  approvedTokens: number;
  totalEmployeesInTeams: number;
  approvedMiniEmployees: boolean;
  approvedEmployees: boolean;
  selectedMiniEmployees: EmployeeNFT[];
  selectedEmployees: EmployeeNFT[];
  selectedTeam: EmployeesTeamNFT | null;
  createFee: string;
  teamId: number | null;
  teamInput: string;
  loader: boolean;
}

export class TeamViewerPage extends React.PureComponent<TeamViewerComponentProps, TeamViewerComponentState> {
  constructor(props: TeamViewerComponentProps) {
    super(props);

    this.state = {
      teams: null,
      employees: null,
      miniEmployees: null,
      pageEmployees: 0,
      pageMiniEmployees: 0,
      pageTeams: 0,
      approvedTokens: 0,
      totalEmployeesInTeams: 0,
      teamId: null,
      loader: false,
      approvedMiniEmployees: false,
      approvedEmployees: false,
      selectedMiniEmployees: [],
      selectedEmployees: [],
      selectedTeam: null,
      createFee: "",
      teamInput: "1",
    };
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search).get("teamId");
    let teamId = null;

    if (urlParams) teamId = Number(urlParams);

    this.loadAndSetEmployeeTeamsController(teamId || 1);

    this.props.onToggleLoader(false);
  }

  async loadAndSetEmployeeTeamsController(id?: number) {
    let teamId = id ? id : null;

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.blockChain.controller.teamStorage &&
      this.props.appState.appData
    ) {
      const teams = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData,
        this.props.blockChain.controller.teamStorage
      );

      if (teamId) await teams.loadTeamData(teamId);

      this.setState({ teams, loader: false, teamInput: (teamId || 1)?.toString() });
    }
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.props.onLoadCustomerData(true);
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-team-searcher-header ct-max-container">
          <h4>team members</h4>
          <small>write the id of your team and you will see the team members in the bottom.</small>
          <div className="ct-input">
            <input type="text" placeholder="team id" value={this.state.teamInput} onChange={(e) => this.setState({ teamInput: e.target.value })} />
            <button
              className="ct-main-button"
              onClick={() => {
                if (!isNaN(Number(this.state.teamInput))) {
                  this.setState({ loader: true }, () => {
                    this.loadAndSetEmployeeTeamsController(Number(this.state.teamInput));
                  });
                }
              }}
            >
              <span className="fas fa-search"></span>
            </button>
          </div>
        </div>
        <div className="ct-max-container ct-builder-page">
          {this.state.teams?.miniEmployeesData ? (
            this.state.teams?.miniEmployeesData?.length > 0 ? (
              <div className="ct-mini-employees">
                {this.state.teams && this.props.appState.appData ? (
                  <BuilderEmployeesGrid
                    nftsPerPage={8}
                    employeesLoader={this.state.loader}
                    employees={this.state.teams}
                    appData={this.props.appState.appData}
                    page={this.state.pageMiniEmployees}
                    onlyMiniEmployees={true}
                    miniEmployeesContract={this.state.teams.miniEmployees}
                    onChangeEmployeesPage={(page: number) => this.setState({ pageMiniEmployees: page })}
                    title="Mini Employees"
                    subtitle={
                      "Select your mini Employees and compress it to create mini employees team. Selected: " + this.state.selectedMiniEmployees.length
                    }
                    onUpdate={() => this.loadAndSetEmployeeTeamsController()}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {this.state.teams?.employeesData && this.state.teams.employeesData.length > 0 ? (
            <div className="ct-employees">
              {this.state.teams && this.props.appState.appData ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={false}
                  employees={this.state.teams}
                  appData={this.props.appState.appData}
                  page={this.state.pageEmployees}
                  onChangeEmployeesPage={(page: number) => this.setState({ pageEmployees: page })}
                  withoutMultiEmployees
                  title="Employees"
                  subtitle={"Select your Employees and compress it to create employees team. Selected: " + this.state.selectedEmployees.length}
                  onUpdate={() => this.loadAndSetEmployeeTeamsController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
