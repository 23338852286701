import React from "react";
import {
  FactoryNFT,
  FactoryProperties,
  FactoryProperty,
} from "../../../../core/nfts/factories";
import { FactoriesController } from "../../../../core/modules/factories";
import { TokenController } from "../../../../core/modules/token";
import { AppData } from "../../../../core/types";
import { StaticFactoryNFT } from "../../../molecules/nft/factories/factories";

import "./grid.styles.css";

export interface BuilderFactoriesGridProps {
  page: number;
  nftsPerPage: number;
  appData: AppData;
  token?: TokenController;
  factories: FactoriesController;
  factoriesLoader: boolean;
  ultraSmall?: boolean;
  selected?: FactoryNFT;
  title?: string;
  subtitle?: string;
  fullWidth?: boolean;
  gameSelection?: FactoryNFT[];
  action?: { onClick: () => void; icon: string };
  actions?: { onClick: () => void; icon?: string; image?: string }[];
  onClick?: (factory: FactoryNFT) => void;
  onUpdate?: () => void;
  onApprove?: (address: string, amount: number) => void;
  onTransfer?: (to: string) => void;
  onChangeFactoriesPage?: (page: number) => void;
  onSell?: (id: number, pirce: number) => void;
  onSendToStaking?: (id: number) => void;
  onRemoveFromStake?: (nft: FactoryNFT) => void;
  onGrow?: (nft: FactoryNFT) => void;
}

export interface BuilderFactoriesGridState {
  properties: FactoryProperties;
  filter: boolean;
  points: boolean;
  id: boolean;
}

export class BuilderFactoriesGrid extends React.Component<
  BuilderFactoriesGridProps,
  BuilderFactoriesGridState
> {
  constructor(props: BuilderFactoriesGridProps) {
    super(props);

    this.state = {
      properties: {
        build: null,
        model: null,
        points: null,
        id: null,
      },
      filter: false,
      points: false,
      id: true,
    };
  }

  filteredNFTs() {
    return this.props.factories.factoriesData.filter((nft: FactoryNFT) => {
      return Object.keys(nft.properties).every((propertyKey: string) => {
        return (
          this.state.properties[propertyKey as FactoryProperty] === null ||
          this.state.properties[propertyKey as FactoryProperty]?.toString() ===
            nft.properties[propertyKey as FactoryProperty]?.toString()
        );
      });
    });
  }

  render() {
    const filteredNFTs = this.filteredNFTs();
    const totalPages = Math.ceil(filteredNFTs.length / this.props.nftsPerPage);
    const selectedNFTs = [];

    if (this.state.points && !this.state.id) {
      filteredNFTs.sort((a, b) => {
        if (a.points < b.points) return 1;
        if (a.points > b.points) return -1;
        return 0;
      });
    }

    if (this.state.id && !this.state.points) {
      filteredNFTs.sort((a, b) => {
        if (a.id < b.id) return 1;
        if (a.id > b.id) return -1;
        return 0;
      });
    }

    for (let i = 0; i < totalPages; i++) {
      if (i === this.props.page) {
        for (
          let j = i * this.props.nftsPerPage;
          j < this.props.nftsPerPage * i + this.props.nftsPerPage;
          j++
        ) {
          if (filteredNFTs[j]) selectedNFTs.push(filteredNFTs[j]);
        }
      }
    }

    return (
      <div
        className={
          "ct-builder-grid ct-factories-grid" +
          (this.props.fullWidth ? " ct-full" : "")
        }
      >
        <div className={"ct-sub-container"}>
          <div className="ct-title">
            <div className="ct-text">
              <h1>
                {this.props.title ? this.props.title : "Factories (NFTs)"}
              </h1>
              <small>{this.props.subtitle ? this.props.subtitle : ""}</small>
            </div>
            <div className="ct-actions">
              {this.props.actions && this.props.actions.length > 0
                ? this.props.actions.map((action, index) => {
                    if (action.image) {
                      return (
                        <img
                          key={index}
                          src={action.image}
                          onClick={() =>
                            action?.onClick ? action.onClick() : null
                          }
                          alt="block chain logo"
                        ></img>
                      );
                    } else if (action.icon) {
                      return (
                        <span
                          key={index}
                          className={action.icon}
                          onClick={() =>
                            action?.onClick ? action.onClick() : null
                          }
                        ></span>
                      );
                    } else {
                      return "";
                    }
                  })
                : ""}
              {this.props.action &&
              this.props.action.icon &&
              this.props.action.onClick ? (
                <span
                  className={this.props.action.icon}
                  onClick={() =>
                    this.props.action?.onClick
                      ? this.props.action.onClick()
                      : null
                  }
                ></span>
              ) : (
                ""
              )}
              {this.props.onUpdate ? (
                <span
                  onClick={() =>
                    this.props.onUpdate ? this.props.onUpdate() : null
                  }
                  className="fas fa-sync-alt"
                ></span>
              ) : (
                ""
              )}

              {this.state.filter ? (
                <span
                  onClick={() => this.setState({ filter: !this.state.filter })}
                  className="fas fa-times"
                ></span>
              ) : (
                <span
                  onClick={() => this.setState({ filter: !this.state.filter })}
                  className="fas fa-search"
                ></span>
              )}
            </div>
          </div>
          {!this.props.factoriesLoader ? (
            !this.state.filter ? (
              totalPages > 0 ? (
                <div className="ct-grid-container">
                  {selectedNFTs.map((nft, index) => {
                    return (
                      <StaticFactoryNFT
                        nft={nft}
                        key={index + "-" + nft.id}
                        token={this.props.token}
                        factories={this.props.factories}
                        ultraSmall={this.props.ultraSmall}
                        selected={
                          !!this.props.gameSelection?.find(
                            (employee) => employee.id === nft.id
                          )
                        }
                        onClick={
                          this.props.onClick
                            ? (nft: FactoryNFT) =>
                                this.props.onClick
                                  ? this.props.onClick(nft)
                                  : null
                            : undefined
                        }
                      />
                    );
                  })}
                  <div className="ct-pagination">
                    {this.props.page > 0 ? (
                      <span
                        className="fas fa-chevron-left"
                        onClick={() => {
                          let momentPage: number = this.props.page;
                          if (momentPage > 0) momentPage--;
                          if (this.props.onChangeFactoriesPage) {
                            this.props.onChangeFactoriesPage(momentPage);
                          }
                        }}
                      ></span>
                    ) : (
                      ""
                    )}
                    <span>{this.props.page + 1}</span>
                    {this.props.page < totalPages - 1 ? (
                      <span
                        className="fas fa-chevron-right"
                        onClick={() => {
                          let momentPage: number = this.props.page;
                          if (momentPage < totalPages - 1) momentPage++;
                          if (this.props.onChangeFactoriesPage) {
                            this.props.onChangeFactoriesPage(momentPage);
                          }
                        }}
                      ></span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="ct-without-data">
                  <div className="spinner"></div>
                  {totalPages === 0 ? <p>You dont have Factories.</p> : ""}
                </div>
              )
            ) : (
              <div className="ct-filters">
                <h5>Filters</h5>
                <div className="ct-filter">
                  <label htmlFor="points">Points</label>
                  <input
                    type="number"
                    onChange={(e) =>
                      this.setState({
                        properties: {
                          ...this.state.properties,
                          points: e.target.value !== "" ? e.target.value : null,
                        },
                      })
                    }
                  />
                </div>
                <div className="ct-filter ct-sort-filter">
                  <label htmlFor="sort-by">Sort by</label>
                  <label htmlFor="points">
                    <input
                      type="radio"
                      checked={this.state.points}
                      name="points-sort"
                      onChange={(e) =>
                        this.setState({
                          points: e.target.checked,
                          id: !e.target.checked,
                        })
                      }
                    />
                    Points
                  </label>
                  <label htmlFor="id-sort">
                    <input
                      type="radio"
                      checked={this.state.id}
                      name="id-sort"
                      onChange={(e) =>
                        this.setState({
                          id: e.target.checked,
                          points: !e.target.checked,
                        })
                      }
                    />
                    ID
                  </label>
                </div>
                <div className="ct-buttons">
                  <button
                    onClick={() =>
                      this.setState({ filter: !this.state.filter })
                    }
                    className="ct-main-button"
                  >
                    Search (Total NFTs: {filteredNFTs.length})
                  </button>
                  <button
                    className="ct-main-button"
                    onClick={() =>
                      this.setState({
                        properties: {
                          build: null,
                          model: null,
                          points: null,
                          id: null,
                        },
                      })
                    }
                  >
                    Reset
                  </button>
                </div>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
