import React from "react";
import { AppErrorCode, Contract } from "../../core/app";
import { Token } from "../../core/contracts/token";
import { BlockChainHelpers } from "../../core/helpers/chain";
import { UtilsHelpers } from "../../core/helpers/utils";
import { BuildersController } from "../../core/modules/builders";
import { EmployeesController } from "../../core/modules/employees";
import { MultiEmployeeDeployerController } from "../../core/modules/multiEmployeeDeployer";
import { EmployeeNFT } from "../../core/nfts/employee";
import { ApplicationState } from "../../storage/state/app/state";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { BuilderEmployeesGrid } from "../organisms/builder/employees/grid";
import { TeamLeader } from "../organisms/teamLeader";
import IERC20 from "../../assets/static/ERC20.json";
import Web3 from "web3";
import { NavWindow } from "../types";

interface MultiEmployeesComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface MultiEmployeesComponentState {
  buildersController: BuildersController | null;
  employees: EmployeesController | null;
  multiEmployeesDeployer: MultiEmployeeDeployerController | null;
  token: Token | null;
  maticAllowance: number;
  tokenAllowance: number;
  confirmState: boolean;
  page: number;
  deployerAllowance: number;
  employeesBalance: number;
}

export class MultiEmployeesPage extends React.Component<MultiEmployeesComponentProps, MultiEmployeesComponentState> {
  constructor(props: MultiEmployeesComponentProps) {
    super(props);

    this.state = {
      buildersController: null,
      multiEmployeesDeployer: null,
      token: null,
      employees: null,
      maticAllowance: 0,
      tokenAllowance: 0,
      confirmState: false,
      page: 0,
      deployerAllowance: 0,
      employeesBalance: 0,
    };
  }

  async componentDidMount() {
    await this.preloadData();
    this.props.onToggleLoader(false);
  }

  async preloadEmployeeController() {
    let employees = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employees.loadMultiEmployeesData();
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return employees;
  }

  async preloadData() {
    let provider = await BlockChainHelpers.getProvider();
    let employees = null;
    let token = null;
    let deployerAllowance = 0;
    let employeesData = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.blockChain.controller?.multiEmployeesDeployer &&
      this.props.appState.appData
    ) {
      employeesData = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employeesData.loadMultiEmployeesData();

      employees = new MultiEmployeeDeployerController(this.props.blockChain.controller.multiEmployeesDeployer);

      await employees.loadDeployerData();

      if (
        provider &&
        this.props.blockChain.controller?.token &&
        this.props.appState.appData?.contractsAddress &&
        this.props.blockChain.controller.selectedAccount
      ) {
        token = new Token(
          new provider.eth.Contract(IERC20.abi as any, employees.data?.specialToken),
          new Web3((window as NavWindow).ethereum),
          this.props.blockChain.controller.selectedAccount
        );

        deployerAllowance = UtilsHelpers.normalizeWei(
          await token.allowance(
            this.props.blockChain.controller.token.selectedAccount,
            this.props.appState.appData?.contractsAddress[Contract.MULTI_EMPLOYEE_D]
          )
        );
      }
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    this.setState({
      multiEmployeesDeployer: employees,
      deployerAllowance,
      token,
      employees: employeesData,
    });
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (!error) {
      await this.preloadData();
      this.props.onLoadCustomerData(true);
    } else this.props.onSetBlockChainError(error);
  }

  render() {
    const employeePrice = this.state.multiEmployeesDeployer?.data?.deployerPrice || 0;

    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} /> : ""}
        <div className="ct-max-container ct-multi-employees-page">
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>Multi Employees</h1>
                <small>
                  Build your NFT empire. This employee is an initiative to improve our ecosystem. Give it value in the NFTs market and ensure our
                  growth. It will works with all of your teams. 100% of the payment will be used to buy and burn FTB.
                </small>
                <small className="ct-mt-10">
                  <strong>Employee points: {this.state.multiEmployeesDeployer?.data?.points} / 20</strong>. You will earn one random employee when you
                  reach 20 points.
                </small>
                <small className="ct-mt-10">
                  <strong>Powered by: </strong>
                  <a href="https://feeds.witnet.io/meter" target="_blank" rel="noopener noreferrer">
                    Witnet oracles
                  </a>{" "}
                </small>
                <a href="https://docs.businessbuilders.city/ecosystems/polygon/employees-nfts/children" target="_blank" rel="noopener noreferrer">
                  MultiEmployee documentation
                </a>
                <small>read before buying your employee</small>
              </div>
              {this.props.blockChain.controller?.multiEmployeesDeployer &&
              this.props.blockChain.customer?.tokensData &&
              this.props.appState.appData &&
              this.props.appState.appData.customerToken &&
              this.state.token ? (
                <div className="ct-actions">
                  {this.state.confirmState ? (
                    <button
                      className="ct-main-button"
                      onClick={() =>
                        this.setState({
                          confirmState: false,
                        })
                      }
                    >
                      <span className="fas fa-times"></span>
                    </button>
                  ) : (
                    ""
                  )}
                  {!this.state.confirmState ? (
                    <button
                      onClick={() =>
                        this.setState({
                          confirmState: true,
                        })
                      }
                      className="ct-main-button"
                    >
                      Mint Multi Employee
                    </button>
                  ) : (
                    ""
                  )}
                  {this.state.confirmState ? (
                    <div className="ct-payed-confirm">
                      <span>
                        <strong>
                          Necessary MTRG: {this.state.multiEmployeesDeployer?.data?.deployerPrice.toFixed(2)}
                          {" MTRG"}
                        </strong>
                      </span>
                      <span>
                        <strong>
                          Price in USD: {this.state.multiEmployeesDeployer?.data?.deployerUSDPrice}
                          {" USD"}
                        </strong>
                      </span>

                      {this.state.deployerAllowance >= employeePrice ? (
                        <button
                          className="ct-main-button"
                          onClick={() => {
                            this.props.blockChain.controller?.multiEmployeesDeployer?.mintEmployee((error: AppErrorCode | null) => {
                              this.onUpdateData(error);
                            });
                          }}
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          className="ct-main-button"
                          onClick={() => {
                            if (this.props.appState.appData) {
                              this.state.token?.approve(
                                this.props.appState.appData?.contractsAddress[Contract.MULTI_EMPLOYEE_D],
                                employeePrice + employeePrice * 0.1,
                                (error: AppErrorCode | null) => this.onUpdateData(error)
                              );
                            }
                          }}
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {(this.state.employees?.multiCustomerEmployees?.length || 0) > 0 ? (
                    <button
                      onClick={() => {
                        if (this.props.blockChain.controller?.multiEmployeesDeployer) {
                          this.props.blockChain.controller.multiEmployeesDeployer.randomize((error) => {
                            this.onUpdateData(error);
                          });
                        }
                      }}
                      className="ct-main-button"
                    >
                      Randomize
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {this.state.employees && this.props.appState.appData && this.state.token ? (
            <BuilderEmployeesGrid
              nftsPerPage={8}
              // token={this.state.token}
              employees={this.state.employees}
              appData={this.props.appState.appData}
              page={this.state.page}
              onUpdate={() => this.preloadData()}
              onlyMultiEmployees
              onChangeEmployeesPage={(page: number) => this.setState({ page })}
            />
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
