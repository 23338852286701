import { AbstractCityGetters } from "../abstracts/cityGetters";
import { ContractBaseModel } from "../abstracts/contract";

export class CityGetters extends ContractBaseModel<AbstractCityGetters> {
  getOwnerRelationRewards(city: number, factory: number, owner: string) {
    return this.contract
      .getOwnerRelationRewards(city, factory, owner)
      .call(this.config);
  }
}
