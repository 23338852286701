import { AppErrorCode } from "../app";
import { EmployeeDeployer } from "../contracts/employeeDeployer";
import { UtilsHelpers } from "../helpers/utils";

export class EmployeeDeployerController {
  constructor(
    public contract: EmployeeDeployer // public multiEmployees: MultiEmployeeDeployer
  ) {}

  public mintEmployee(
    type: number,
    multi: boolean,
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Mint Employee");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger(
        "Try Mint NFT (" + this.contract.selectedAccount + ")"
      );

      if (callback) {
        if (multi) {
          // this.multiEmployees.mintEmployee(callback);
        } else this.contract.mintEmployee(type, callback);
      }
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }
}
