import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { TeamLeaderNFT } from "../../../core/types";

import "./styles.css";

interface TeamLeaderProps {
  teamLeader: TeamLeaderNFT;
}

export const TeamLeader = (props: TeamLeaderProps) => {
  const initParticles = async (main: any) => {
    await loadFull(main);
  };

  let maxXP = 0;
  let xpPercentage = 0;

  if (props.teamLeader) {
    maxXP =
      props.teamLeader?.teamLeader.leader.nextLevelXP -
      props.teamLeader?.teamLeader.leader.nextLevelXP / 2;

    xpPercentage =
      ((props.teamLeader.teamLeader.leader.xp - maxXP) * 100) / maxXP;
  }

  return (
    <div className="ct-team-leader-container">
      <Particles
        id="tsparticles"
        init={(main) => initParticles(main)}
        style={{ width: xpPercentage + "% !important" }}
        options={{
          background: {
            color: {
              value: "rgba(255,255,255,0)",
            },
            opacity: 0,
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: false,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "rgb(27, 191, 27)",
            },
            links: {
              enable: false,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: "none",
              enable: true,
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 100,
              },
              value: 150,
            },
            opacity: {
              value: 0.4,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 2, max: 4 },
            },
          },
          fullScreen: false,
          detectRetina: false,
        }}
      ></Particles>
      <div
        className="ct-xp-bar"
        style={{
          width: xpPercentage + "%",
        }}
      ></div>
      <div className="ct-text">
        <strong>
          Level: {props.teamLeader.teamLeader.leader.level} -{" "}
          {xpPercentage.toFixed(0)} %
        </strong>
      </div>
    </div>
  );
};
