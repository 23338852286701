import React from "react";
import Web3 from "web3";
import { appConfig, AppErrorCode } from "../../core/app";
import { ApiHelpers } from "../../core/helpers/api";
import { ApplicationState } from "../../storage/state/app/state";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { FactoryRelation } from "../organisms/relations/factoryRelation";
import { TeamLeader } from "../organisms/teamLeader";

interface RelationsProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

enum FilterOption {
  ACTIVE_RELATIONS = "active-relations",
  CITIES_LOGS = "cities-logs",
  FACTORIES_LOGS = "factories-logs",
}

interface RelationState {
  relationRewards: number;
  factoriesRewards: number;
  citiesRewards: number;
  miniRewards: number;
  citiesXP: number;
  lastBlock: number;
  oldFactoriesRewards: number;
  createdCityRelations: any[];
  removedCityRelations: any[];
  createdFactoryRelations: any[];
  removedFactoryRelations: any[];
  activeRelations: any[];
  factoryRelations: any[];
  selectedFilterOption: FilterOption;
}

export class RelationsPage extends React.Component<RelationsProps, RelationState> {
  constructor(props: RelationsProps) {
    super(props);

    this.state = {
      relationRewards: 0,
      factoriesRewards: 0,
      citiesRewards: 0,
      citiesXP: 0,
      miniRewards: 0,
      oldFactoriesRewards: 0,
      lastBlock: 0,
      selectedFilterOption: FilterOption.ACTIVE_RELATIONS,
      createdCityRelations: [],
      removedCityRelations: [],
      createdFactoryRelations: [],
      removedFactoryRelations: [],
      activeRelations: [],
      factoryRelations: [],
    };
  }

  async componentDidMount() {
    this.props.onToggleLoader(false);
    this._getCustomerActiveRelations();
    this._getCustomerFactoryRelations();
    this._getCustomerRewards();
  }

  private _selectFilter(filter: FilterOption) {
    this.setState({ selectedFilterOption: filter }, async () => {
      this._getCustomerActiveRelations();
      this.getCustomerLogs();
    });
  }

  private async getCustomerLogs() {
    if (this.props.blockChain.controller?.selectedAccount) {
      const userLogs = await ApiHelpers.getUserLogs(this.props.blockChain.controller?.selectedAccount);

      if (
        userLogs &&
        userLogs.createCityRelations &&
        userLogs.createFactoryRelations &&
        userLogs.removeCityRelations &&
        userLogs.removeFactoryRelations
      ) {
        this.setState({
          createdCityRelations: userLogs.createCityRelations.map((event: any) => {
            return { ...event, event: "CityRelation" };
          }),
          createdFactoryRelations: userLogs.createFactoryRelations.map((event: any) => {
            return { ...event, event: "FactoryAddition" };
          }),
          removedCityRelations: userLogs.removeCityRelations.map((event: any) => {
            return { ...event, event: "RemoveCityRelation" };
          }),
          removedFactoryRelations: userLogs.removeFactoryRelations.map((event: any) => {
            return { ...event, event: "RemoveFactoryAddition" };
          }),
        });
      }
    }
  }

  private async _getCustomerActiveRelations() {
    if (
      this.props.blockChain.controller?.cityRelationsStorage &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.blockChain.controller.cityGetters
    ) {
      const relations = await this.props.blockChain.controller.cityRelationsStorage.getCustomerRelations(
        this.props.blockChain.controller.selectedAccount
      );

      const ownerRelations = await ApiHelpers.preloadFactoryRelations(this.props.blockChain.controller.selectedAccount, relations);

      if (ownerRelations && Array.isArray(ownerRelations)) {
        const batch = new this.props.blockChain.controller.cityRelationsStorage.web3.eth.BatchRequest();

        for (let i = 0; i < ownerRelations.length; i++) {
          batch.add(
            this.props.blockChain.controller?.universities?.contract
              .getFactoryRelationInfo(ownerRelations[i]._city, ownerRelations[i]._factory, 0, 0, this.props.blockChain.controller.selectedAccount)
              .call.request({ from: this.props.blockChain.controller.selectedAccount }, (e: any, data: any) => {
                const newState = [...this.state.activeRelations];

                const relationIndex = newState.findIndex(
                  (relation) => Number(relation._city) === Number(data.relation.city) && Number(relation._factory) === Number(data.relation.factory)
                );

                newState[relationIndex] = {
                  ...newState[relationIndex],
                  addition: data.addition,
                  relation: data.relation,
                  fees: data.fees,
                  relationRewards: data.relationRewards,
                  relationTime: data.relationTime,
                  timeToGetAll: data.timeToGetAll,
                };

                this.setState({ activeRelations: newState });
              })
          );
        }

        this.setState({ activeRelations: ownerRelations }, () => {
          batch.execute();
        });
      }
    }
  }

  private async _getCustomerRewards() {
    if (this.props.blockChain.controller?.universities) {
      const rewards = await this.props.blockChain.controller.universities.getUserRewards();

      if (rewards) {
        this.setState(
          {
            relationRewards: Number(Web3.utils.fromWei((rewards.relationsRewards || 0).toString(), "ether")),
            factoriesRewards: Number(Web3.utils.fromWei((rewards.factoriesRewards || 0).toString(), "ether")),
            oldFactoriesRewards: Number(Web3.utils.fromWei((rewards.factoryRewards || 0).toString(), "ether")),
            citiesRewards: Number(Web3.utils.fromWei((rewards.cityRewards || 0).toString(), "ether")),
            miniRewards: Number(Web3.utils.fromWei((rewards.miniEmployeeRewards || 0).toString(), "ether")),
            citiesXP: Number(rewards.universitiesXP),
          },
          () => {
            console.log(this.state);
          }
        );
      }
    }
  }

  private async _getCustomerFactoryRelations() {
    if (this.props.blockChain.controller?.selectedAccount) {
      const relationsByOwner = await ApiHelpers.getFactoryRelationsByOwner(this.props.blockChain.controller?.selectedAccount);
      if (relationsByOwner?.relation && Array.isArray(relationsByOwner?.relation)) this.setState({ factoryRelations: relationsByOwner.relation });
    }
  }

  private _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this._getCustomerRewards();
    this._getCustomerActiveRelations();
    this._getCustomerFactoryRelations();
    this.props.onLoadCustomerData(true);
  }

  render() {
    console.log(this.state.factoryRelations);
    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} /> : ""}
        <div className="ct-max-container ct-relations-page">
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>Relations</h1>
                <small>Grow and earn FTB with your NFT empire.</small>
                <small>Total rewards: {(this.state.citiesRewards + this.state.factoriesRewards + this.state.relationRewards).toFixed(1)} FTB</small>
              </div>
              <div className="ct-actions"></div>
            </div>
          </div>
          <div className="ct-withdraw-rewards">
            <div className="ct-container">
              <div>
                <span>
                  <strong>Cities rewards:</strong>
                </span>
                <span>{this.state.citiesRewards?.toFixed(1)} FTB</span>
                <button
                  onClick={() => {
                    if (this.props.blockChain.controller?.universities) {
                      this.props.blockChain.controller.universities.withdrawCityRewards((error: AppErrorCode | null) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw rewards
                </button>
              </div>
              <div>
                <span>
                  <strong>Factories rewards:</strong>
                </span>
                <span>{this.state.factoriesRewards?.toFixed(1)} FTB</span>
                <button
                  onClick={() => {
                    if (this.props.blockChain.controller?.universities) {
                      this.props.blockChain.controller.universities.withdrawFactoryRewards((error: AppErrorCode | null) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw rewards
                </button>
              </div>
              <div>
                <span>
                  <strong>Relations rewards:</strong>
                </span>
                <span>{this.state.relationRewards?.toFixed(1)} FTB</span>
                <button
                  onClick={() => {
                    if (this.props.blockChain.controller?.universities) {
                      this.props.blockChain.controller.universities.withdrawRelationsRewards((error: AppErrorCode | null) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw rewards
                </button>
              </div>
              <div>
                <span>
                  <strong>Mini Employee Rewards:</strong>
                </span>
                <span>{this.state.miniRewards?.toFixed(1)} FTB</span>
                <button
                  onClick={() => {
                    if (this.props.blockChain.controller?.universities) {
                      this.props.blockChain.controller.universities.withdrawMiniEmployeesRewards((error: AppErrorCode | null) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw rewards
                </button>
              </div>
            </div>
          </div>
          {this.state.selectedFilterOption === FilterOption.ACTIVE_RELATIONS ? (
            <div className="ct-city-relations">
              <div className="ct-relations">
                {[...this.state.activeRelations].map((relation, index) => {
                  return (
                    <FactoryRelation
                      onUpdateData={(error) => this._onUpdateData(error)}
                      blockChain={this.props.blockChain}
                      key={index}
                      relation={relation}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="ct-city-relations ct-factories">
            <div className="ct-events">
              <h4>Factories</h4>
              {[...this.state.factoryRelations].map((relation, index) => {
                return (
                  <div className="ct-event" key={index}>
                    <div className="ct-image">
                      <img
                        src={
                          "https://art.businessbuilders.city/factories?a=build:" +
                          relation._type +
                          ";model:" +
                          relation._model +
                          ";points:0;id:3&onlyImage=true"
                        }
                        alt=""
                      />
                    </div>
                    <div className="ct-info">
                      <p>
                        <strong>City:</strong> {relation._city}
                      </p>
                      <p>
                        <strong>Factory:</strong> {relation._factory}
                      </p>
                      <p>
                        <strong>X:</strong> {relation._x}
                      </p>
                      <p>
                        <strong>Y:</strong> {relation._y}
                      </p>
                      <a
                        href={appConfig.universeUrl + "?world=82&city=" + relation._city + "&factory=" + relation._factory}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fas fa-plane"></span>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
