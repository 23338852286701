import { Cities } from "../contracts/cities";
import { CityRelationsStorage } from "../contracts/cityRelationsStorage";
import { CityNFT } from "../nfts/cities";
import { AppData } from "../types";

export class CitiesController {
  public citiesData: CityNFT[] = [];

  constructor(
    public contract: Cities,
    public relationStorage: CityRelationsStorage,
    public appData: AppData
  ) {}

  public async loadCity(id: number) {
    const cityData = await this.contract.getData(id);

    this.citiesData.push(
      CityNFT.createWithContractTemplate({
        ...cityData,
        id,
        appData: this.appData,
      })
    );
  }

  public async loadCitiesData() {
    this.citiesData = [];

    const cities = await this.contract.getCustomerCities();

    if (Array.isArray(cities)) {
      for (let i = 0; i < cities.length; i++) {
        await this.loadCity(Number(cities[i]));
      }
    }
  }
}
