import React from "react";
import { AppErrorCode, Contract } from "../../core/app";
import { EmployeeNFT } from "../../core/nfts/employee";
import { BuilderEmployeesGrid } from "../organisms/builder/employees/grid";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { EmployeesController } from "../../core/modules/employees";
import { UtilsHelpers } from "../../core/helpers/utils";
import { MiniEmployeeDeployerController } from "../../core/modules/miniEmployeeDeployer";
import { TeamLeader } from "../organisms/teamLeader";

interface UpgradeEmployeesComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface UpgradeEmployeesComponentState {
  employeeDeployer: null | MiniEmployeeDeployerController;
  employees: null | EmployeesController;
  miniEmployees: null | EmployeesController;
  token: null | TokenController;
  openEmployeeNFTModal: boolean;
  employeeNewNFTs: EmployeeNFT[];
  employeesLoader: boolean;
  miniEmployeesLoader: boolean;
  payedEmployeeConfirm: boolean;
  pointsEmployeeConfirm: boolean;
  package: boolean;
  deployerAllowance: number;
  page: number;
  miniPage: number;
  max: boolean;
  limitation: number;
  selectedMen: EmployeeNFT | null;
  selectedWoman: EmployeeNFT | null;
}

export class UpgradeEmployeesPage extends React.PureComponent<
  UpgradeEmployeesComponentProps,
  UpgradeEmployeesComponentState
> {
  constructor(props: UpgradeEmployeesComponentProps) {
    super(props);

    this.state = {
      employeeDeployer: null,
      token: null,
      employees: null,
      miniEmployees: null,
      openEmployeeNFTModal: false,
      employeeNewNFTs: [],
      employeesLoader: false,
      miniEmployeesLoader: false,
      payedEmployeeConfirm: false,
      pointsEmployeeConfirm: false,
      package: false,
      deployerAllowance: 0,
      max: false,
      limitation: 0,
      page: 0,
      miniPage: 0,
      selectedMen: null,
      selectedWoman: null,
    };
  }

  async componentDidMount() {
    await this.preloadControllers();
    this.props.onToggleLoader(false);
  }

  async preloadControllers(closeLoader: boolean = false) {
    let token = null;
    let deployerAllowance = 0;

    const miniEmployees = await this.preloadMiniEmployeeController();
    const employees = await this.preloadEmployeeController();
    const employeeDeployer = await this.preloadEmployeeDeployerController();

    if (
      this.props.blockChain.controller?.token &&
      this.props.appState.appData
    ) {
      token = new TokenController(this.props.blockChain.controller.token);

      deployerAllowance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.controller.token.allowance(
          this.props.blockChain.controller.token.selectedAccount,
          this.props.appState.appData?.contractsAddress[
            Contract.MINI_EMPLOYEES_D
          ]
        )
      );
    }

    this.setState(
      {
        miniEmployees,
        employees,
        token,
        employeeDeployer,
        employeesLoader: false,
        miniEmployeesLoader: false,
        deployerAllowance,
      },
      () => {
        if (closeLoader) this.props.onToggleLoader(false);
      }
    );
  }

  async preloadMiniEmployeeController() {
    let employees = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employees.loadMiniEmployeesData();
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return employees;
  }

  async preloadEmployeeController() {
    let employees = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.appState.appData
    ) {
      employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employees.loadFullEmployeesData(true);
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return employees;
  }

  async preloadEmployeeDeployerController() {
    let controller = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.appState.appData
    ) {
      controller = new MiniEmployeeDeployerController(
        this.props.blockChain.controller.miniEmployeesDeployer
      );

      await controller.loadDeployerData();
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return controller;
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    await this.preloadControllers();
    this.props.onLoadCustomerData(true);
  }

  async loadAndSetEmployeesController() {
    this.setState({ employeesLoader: true });
    const employees = await this.preloadEmployeeController();
    this.setState({ employees, employeesLoader: false });
  }

  async loadAndSetMiniEmployeesController() {
    this.setState({ miniEmployeesLoader: true });
    const miniEmployees = await this.preloadMiniEmployeeController();
    this.setState({ miniEmployees, miniEmployeesLoader: false });
  }

  render() {
    const employeePrice = this.state.employeeDeployer?.data?.employeePrice || 0;

    const employeeAddress =
      this.props.appState.appData?.contractsAddress[
        Contract.MINI_EMPLOYEES_D
      ] || "";

    let selected: EmployeeNFT[] = [];
    let selection: EmployeeNFT[] = [];

    if (this.state.employeeDeployer?.data?.maxMerge && this.state.employees) {
      selected = this.state.employees?.employeesData.filter(
        (employee) =>
          employee.merges >=
          (this.state.employeeDeployer?.data?.maxMerge
            ? this.state.employeeDeployer?.data?.maxMerge
            : 10)
      );
    }

    if (this.state.selectedMen) selection.push(this.state.selectedMen);
    if (this.state.selectedWoman) selection.push(this.state.selectedWoman);

    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? (
          <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} />
        ) : (
          ""
        )}
        <div className="ct-max-container ct-builder-page">
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>EMPLOYEE BREEDING</h1>
                <small>
                  Use your employees, have children and educate them until they
                  contribute to your business
                </small>
                <a
                  href="https://docs.businessbuilders.city/ecosystems/polygon/employees-nfts/children"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Childrens documentation
                </a>
                <small>read before merge your employee</small>
              </div>
            </div>
          </div>
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>CREATE YOUR CHILDREN</h1>
                <span>Head probability</span>
                {this.state.selectedWoman && this.state.selectedMen ? (
                  this.state.selectedMen.head ===
                  this.state.selectedWoman.head ? (
                    <strong>
                      100%{" "}
                      {UtilsHelpers.getTypeName(this.state.selectedWoman.head)}
                    </strong>
                  ) : (
                    <strong>
                      <span>
                        50%{" "}
                        {UtilsHelpers.getTypeName(
                          this.state.selectedWoman.head
                        )}
                      </span>
                      <span>
                        50%{" "}
                        {UtilsHelpers.getTypeName(this.state.selectedMen.head)}
                      </span>
                    </strong>
                  )
                ) : (
                  ""
                )}
                <strong>
                  You need:{" "}
                  {this.state.limitation === 0
                    ? employeePrice
                    : employeePrice * this.state.limitation}{" "}
                  {this.props.appState.appData?.customerToken.loadTokenSymbol}
                </strong>

                <strong>
                  Selected merges:{" "}
                  {this.state.limitation === 0 ? 1 : this.state.limitation}
                </strong>

                <a
                  href="https://docs.businessbuilders.city/ecosystems/polygon/employees-nfts/children"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Childrens documentation
                </a>

                <small>read before merge your employee</small>
              </div>
              <div className="ct-actions">
                {this.state.selectedMen && this.state.selectedWoman ? (
                  <label htmlFor="use-max" className="ct-max-selector">
                    Use max
                    <input
                      onChange={async (e) => {
                        if (
                          this.state.selectedMen &&
                          this.state.selectedWoman
                        ) {
                          if (e.target.checked) {
                            const womanMerges =
                              10 -
                              ((await this.props.blockChain.controller?.employeesExpanded?.getMergeRecord(
                                this.state.selectedWoman.id
                              )) || 10);

                            const menMerges =
                              10 -
                              ((await this.props.blockChain.controller?.employeesExpanded?.getMergeRecord(
                                this.state.selectedMen.id
                              )) || 10);

                            if (womanMerges > 0 && menMerges > 0) {
                              this.setState({
                                limitation:
                                  menMerges < womanMerges
                                    ? menMerges
                                    : womanMerges,
                              });
                            }
                          } else {
                            this.setState({ limitation: 0 });
                          }
                        }
                      }}
                      id="use-max"
                      name="use-max"
                      type="checkbox"
                      checked={this.state.limitation > 0}
                    />
                  </label>
                ) : (
                  ""
                )}
                {this.state.deployerAllowance >=
                  (this.state.limitation === 0
                    ? employeePrice
                    : employeePrice * this.state.limitation) &&
                this.props.appState.appData?.customerToken &&
                this.state.selectedMen &&
                this.state.selectedWoman ? (
                  <React.Fragment>
                    <button
                      onClick={() => {
                        if (
                          this.state.selectedWoman &&
                          this.state.selectedMen
                        ) {
                          this.props.onToggleLoader(true);

                          if (this.state.limitation === 0) {
                            this.props.blockChain.controller?.miniEmployeesDeployer?.mergeTwoEmployees(
                              this.state.selectedMen?.id,
                              this.state.selectedWoman.id,
                              (error: AppErrorCode | null) => {
                                this.setState(
                                  { selectedMen: null, selectedWoman: null },
                                  () => this.onUpdateData(error)
                                );
                              }
                            );
                          } else {
                            this.props.blockChain.controller?.miniEmployeesDeployer?.mergeTwoEmployeesManyTimes(
                              this.state.selectedMen.id,
                              this.state.selectedWoman.id,
                              this.state.limitation,
                              (error: AppErrorCode | null) => {
                                this.setState(
                                  {
                                    selectedMen: null,
                                    selectedWoman: null,
                                    limitation: 0,
                                  },
                                  () => this.onUpdateData(error)
                                );
                              }
                            );
                          }
                        }
                      }}
                      className="ct-main-button"
                      disabled={
                        employeePrice >
                        this.props.appState.appData?.customerToken
                          .loadTokenBalance
                      }
                    >
                      Merge
                    </button>
                  </React.Fragment>
                ) : this.state.selectedMen && this.state.selectedWoman ? (
                  <button
                    className="ct-main-button"
                    onClick={() => {
                      this.props.onToggleLoader(true);
                      this.state.token?.approveTokenSpend(
                        employeeAddress,
                        this.state.limitation === 0
                          ? employeePrice
                          : this.state.limitation * employeePrice,
                        (error: AppErrorCode | null) => {
                          this.onUpdateData(error);
                        }
                      );
                    }}
                  >
                    Approve token
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="ct-employees">
            {this.state.employees &&
            this.props.appState.appData &&
            this.state.employeeDeployer &&
            this.state.token ? (
              <BuilderEmployeesGrid
                nftsPerPage={8}
                employeesLoader={this.state.employeesLoader}
                token={this.state.token}
                employees={this.state.employees}
                appData={this.props.appState.appData}
                page={this.state.page}
                title="Employees (NFT)"
                subtitle={
                  "Select two employees " +
                  (this.state.selectedWoman && this.state.selectedMen
                    ? "2"
                    : this.state.selectedMen || this.state.selectedWoman
                    ? "1"
                    : "0") +
                  "/ 2"
                }
                gameSelection={selection}
                deactivatedSelection={selected}
                withMiniEmployeesData
                miniEmployeesDeployer={this.state.employeeDeployer}
                onChangeEmployeesPage={(page: number) =>
                  this.setState({ page })
                }
                onSelect={
                  this.state.employeeDeployer?.data?.maxMerge
                    ? () => {}
                    : undefined
                }
                onClick={(employee) => {
                  if (employee.id === this.state.selectedMen?.id) {
                    this.setState({ selectedMen: null });
                  } else if (employee.id === this.state.selectedWoman?.id) {
                    this.setState({ selectedWoman: null });
                  } else {
                    if (!this.state.selectedMen) {
                      this.setState({ selectedMen: employee });
                    } else if (!this.state.selectedWoman) {
                      this.setState({ selectedWoman: employee });
                    }
                  }
                }}
                onUpdate={() => this.loadAndSetEmployeesController()}
              />
            ) : (
              ""
            )}
          </div>

          <div className="ct-employees">
            {this.state.miniEmployees &&
            this.props.appState.appData &&
            this.state.employeeDeployer &&
            this.state.token ? (
              <BuilderEmployeesGrid
                nftsPerPage={8}
                employeesLoader={this.state.miniEmployeesLoader}
                token={this.state.token}
                employees={this.state.miniEmployees}
                appData={this.props.appState.appData}
                page={this.state.miniPage}
                onlyMiniEmployees={true}
                miniEmployeesDeployer={this.state.employeeDeployer}
                title="Mini Employees (NFT)"
                onUpgrade={(nft: EmployeeNFT) => {
                  this.props.blockChain.controller?.miniEmployeesDeployer?.upgradeEmployee(
                    nft.id,
                    (error: AppErrorCode | null) => {
                      this.onUpdateData(error);
                    }
                  );
                }}
                onChangeEmployeesPage={(miniPage: number) =>
                  this.setState({ miniPage })
                }
                onUpdate={() => this.loadAndSetMiniEmployeesController()}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
