import { AppErrorCode } from "../app";
import { FactoryDeployer } from "../contracts/factoryDeployer";
import { UtilsHelpers } from "../helpers/utils";

export class FactoryDeployerController {
  constructor(public contract: FactoryDeployer) {}

  public mintFactory(
    employees: number[],
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Mint Factory");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger(
        "Try Mint NFT (" + this.contract.selectedAccount + ")"
      );

      if (callback) this.contract.mintFactory(employees, callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }
}
