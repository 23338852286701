export const navigationLinks = [
  {
    display: "NFTs",
    links: [
      {
        href: "/",
        display: "1. User Profile",
        icon: "fas fa-user",
        type: "internal",
        disabled: false,
      },
      {
        href: "/mini-employees",
        display: "2. Mini Employees",
        icon: "fas fa-user-cog",
        type: "internal",
        disabled: false,
      },
      {
        href: "/employees",
        display: "3. Employees",
        icon: "fas fa-user-cog",
        type: "internal",
        disabled: false,
      },
      {
        href: "/factories",
        display: "4. Factories",
        icon: "fas fa-landmark",
        type: "internal",
        disabled: false,
      },
      {
        href: "/cities",
        display: "5. Cities",
        icon: "fas fa-landmark",
        type: "internal",
        disabled: false,
      },
      {
        href: "/team",
        display: "6. Team members",
        icon: "fas fa-users",
        type: "internal",
        disabled: false,
      },
      {
        href: "/worlds",
        display: "7. Worlds",
        icon: "fas fa-landmark",
        type: "internal",
        disabled: true,
      },
      {
        href: "/resources",
        display: "8. Resources",
        icon: "fas fa-landmark",
        type: "internal",
        disabled: true,
      },
    ],
  },
  {
    display: "Marketplace",
    links: [
      {
        href: "https://tofunft.com/meter",
        display: "Marketplace",
        icon: "fas fa-store-alt",
        type: "external",
        disabled: false,
      },
    ],
  },
  {
    display: "Upgrade",
    links: [
      {
        href: "/team-building",
        display: "Employees team",
        icon: "fas fa-user-cog",
        type: "internal",
        disabled: false,
      },
      {
        href: "/merge-employees",
        display: "Employees Breeding",
        icon: "fas fa-user-cog",
        type: "internal",
        disabled: false,
      },
      {
        href: "/cities-upgrade",
        display: "Upgrade cities",
        icon: "fas fa-user-cog",
        type: "internal",
        disabled: false,
      },
    ],
  },
  {
    display: "Play To Earn",
    links: [
      {
        href: "https://universe.businessbuilders.city",
        display: "P2E Universe",
        icon: "fas fa-globe",
        type: "external",
        disabled: false,
      },
      {
        href: "/relations",
        display: "My Relations",
        icon: "fas fa-globe",
        type: "internal",
        disabled: false,
      },
    ],
  },
  {
    display: "Services",
    links: [
      {
        href: "https://statistics.businessbuilders.city/",
        display: "System statistics",
        icon: "fas fa-database",
        type: "external",
        disabled: false,
      },
      {
        href: "/nft-bridge",
        display: "NFT Ecosystem bridge",
        icon: "fas fa-exchange-alt",
        type: "internal",
        disabled: false,
      },
      {
        href: "/nft-builders",
        display: "NFT Builder",
        icon: "fas fa-user-cog",
        type: "internal",
        disabled: true,
      },
      {
        href: "/p2e-pools",
        display: "P2E pools",
        icon: "fas fa-user-cog",
        type: "internal",
        disabled: true,
      },
    ],
  },
];
