import { AppErrorCode } from "../app";
import { Factories } from "../contracts/factories";
import { FactoryNFT } from "../nfts/factories";
import { ApiHelpers } from "../helpers/api";
import { UtilsHelpers } from "../helpers/utils";
import { AppData } from "../types";
import { CityRelationsStorage } from "../contracts/cityRelationsStorage";

interface FactoryBasisData {
  _id: number;
  _model: number;
  _build: number;
  _network: string;
  _nftID: number;
  _points: number;
}

export class FactoriesController {
  public factoriesData: FactoryNFT[] = [];

  constructor(
    public contract: Factories,
    public storage: CityRelationsStorage,
    public appData: AppData
  ) {}

  private parseChainFactoryData(data: any) {
    return {
      build: data[0]["build"],
      model: data[0]["model"],
      points: data[0]["points"],
      uri: data[1],
    };
  }

  private parseDatabaseFactoryData(data: any) {
    return {
      build: data._build,
      model: data._model,
      points: data._points,
      uri: data._uri,
    };
  }

  public async loadFactory(id: number, factoryData: FactoryBasisData) {
    const factory =
      factoryData === null
        ? this.parseChainFactoryData(await this.contract.getFactory(id))
        : this.parseDatabaseFactoryData(factoryData);

    this.factoriesData.push(
      FactoryNFT.createWithContractTemplate({
        ...factory,
        id,
        appData: this.appData,
      })
    );
  }

  public async loadFactoriesData() {
    this.factoriesData = [];

    const customerFactories = await this.contract.getCustomerFactories();

    if (Array.isArray(customerFactories)) {
      let preloadedNFTs = await ApiHelpers.preloadEmployees(
        this.contract.address,
        customerFactories
      );

      if (preloadedNFTs && Array.isArray(preloadedNFTs)) {
        preloadedNFTs = preloadedNFTs.filter(
          (nft: any, index: number) =>
            preloadedNFTs.findIndex(
              (searched: any) => searched._nftID === nft._nftID
            ) === index
        );

        for (let i = 0; i < preloadedNFTs.length; i++) {
          await this.loadFactory(
            Number(preloadedNFTs[i]._nftID),
            preloadedNFTs[i]
          );
        }
      }
    }
  }

  public async loadSpecificFactoriesata(factories: FactoryBasisData[]) {
    this.factoriesData = [];

    if (Array.isArray(factories)) {
      for (let i = 0; i < factories.length; i++) {
        await this.loadFactory(Number(factories[i]._nftID), factories[i]);
      }
    }
  }

  public approveFactory(
    to: string,
    factory: number,
    callback?: (error: AppErrorCode | null) => void
  ) {
    UtilsHelpers.debugger("Approve factory to" + to);

    UtilsHelpers.debugger(
      "Try to to approve token spend (" + this.contract.selectedAccount + ")"
    );

    if (callback) this.contract?.approve(to, factory, callback);
  }
}
