import { AppErrorCode } from "../app";
import { BlockChain } from "../chain";
import { UtilsHelpers } from "../helpers/utils";
import { AppData } from "../types";

export interface EmployeeNFTTemplate {
  id: number;
  head: number;
  body: number;
  legs: number;
  hands: number;
  points: number;
  xp: number;
  uri: string;
  inJob: boolean;
  canPlay: boolean;
  appData: AppData;
  playTime: number;
  merges: number;
  isStaked: boolean;
  necessaryXP: number;
  isMiniEmployee: boolean;
  isMultiEmployee: boolean;
  employeePrice: number;
  hireEmployeePrice: number;
  isMarketApproved: boolean;
  isStakingApproved: boolean;
}

export enum EmployeeProperty {
  ID = "id",
  HEAD = "head",
  BODY = "body",
  LEGS = "legs",
  HANDS = "hands",
  POINTS = "points",
}

export type EmployeeProperties = {
  [key in EmployeeProperty]: string | null;
};

export class EmployeeNFT implements EmployeeNFTTemplate {
  public uri: string;
  public id: number;
  public head: number;
  public body: number;
  public legs: number;
  public hands: number;
  public xp: number;
  public points: number;
  public inJob: boolean;
  public canPlay: boolean;
  public appData: AppData;
  public playTime: number;
  public merges: number;
  public isStaked: boolean;
  public isMiniEmployee: boolean;
  public isMultiEmployee: boolean;
  public employeePrice: number;
  public necessaryXP: number;
  public hireEmployeePrice: number;
  public isMarketApproved: boolean;
  public isStakingApproved: boolean;

  constructor(private _template: EmployeeNFTTemplate) {
    this.id = this._template.id;
    this.appData = this._template.appData;
    this.head = Number(this._template.head);
    this.body = Number(this._template.body);
    this.legs = Number(this._template.legs);
    this.xp = Number(this._template.xp);
    this.hands = Number(this._template.hands);
    this.inJob = Boolean(this._template.inJob);
    this.points = Number(this._template.points);
    this.canPlay = Boolean(this._template.canPlay);
    this.merges = Number(this._template.merges);
    this.playTime = Number(this._template.playTime);
    this.isStaked = Boolean(this._template.isStaked);
    this.necessaryXP = this.points * 100;
    this.isMiniEmployee = Boolean(this._template.isMiniEmployee);
    this.isMultiEmployee = Boolean(this._template.isMultiEmployee);
    this.employeePrice = Number(this._template.employeePrice);
    this.isMarketApproved = Boolean(this._template.isMarketApproved);
    this.hireEmployeePrice = Number(this._template.hireEmployeePrice);
    this.isStakingApproved = Boolean(this._template.isStakingApproved);
    this.uri = this._template.uri || "";
  }

  static createWithContractTemplate(contractTemplate: { [id: string]: any }) {
    return new EmployeeNFT({
      id: contractTemplate.id,
      head: contractTemplate.head,
      body: contractTemplate.body,
      legs: contractTemplate.legs,
      hands: contractTemplate.hands,
      points: contractTemplate.points,
      uri: contractTemplate.uri,
      inJob: contractTemplate.inJob,
      appData: contractTemplate.appData,
      isStaked: contractTemplate.isStaked,
      employeePrice: contractTemplate.employeePrice,
      isMarketApproved: contractTemplate.isMarketApproved,
      isStakingApproved: contractTemplate.isStakingApproved,
      hireEmployeePrice: contractTemplate.hireEmployeePrice,
      canPlay: contractTemplate.canPlay,
      playTime: contractTemplate.playTime,
      isMiniEmployee: false,
      isMultiEmployee: contractTemplate.isMultiEmployee,
      xp: 0,
      necessaryXP: 0,
      merges: contractTemplate.merges,
    });
  }

  static createMiniWithContractTemplate(contractTemplate: {
    [id: string]: any;
  }) {
    return new EmployeeNFT({
      id: contractTemplate.id,
      head: contractTemplate.head,
      body: contractTemplate.body,
      legs: contractTemplate.legs,
      hands: contractTemplate.hands,
      points: contractTemplate.points,
      uri: contractTemplate.uri,
      xp: contractTemplate.xp,
      inJob: contractTemplate.inJob,
      appData: contractTemplate.appData,
      isStaked: contractTemplate.isStaked,
      employeePrice: contractTemplate.employeePrice,
      isMarketApproved: contractTemplate.isMarketApproved,
      isStakingApproved: contractTemplate.isStakingApproved,
      hireEmployeePrice: contractTemplate.hireEmployeePrice,
      canPlay: contractTemplate.canPlay,
      playTime: contractTemplate.playTime,
      isMiniEmployee: true,
      isMultiEmployee: false,
      necessaryXP: contractTemplate.necessaryXP,
      merges: 0,
    });
  }

  get properties(): EmployeeProperties {
    return {
      id: this.id.toString(),
      head: this.head.toString(),
      body: this.body.toString(),
      legs: this.legs.toString(),
      hands: this.hands.toString(),
      points: this.points.toString(),
    };
  }

  get parts() {
    return [this.head, this.body, this.legs, this.hands];
  }

  public static approveEmployee(
    to: string,
    employee: number,
    blockChain: BlockChain,
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Approve employee staking");

    if (
      blockChain.selectedAccount &&
      blockChain.employees &&
      blockChain.token
    ) {
      UtilsHelpers.debugger(
        "Try to to approve token spend (" + blockChain.selectedAccount + ")"
      );

      if (callback) blockChain.employees?.approve(to, employee, callback);
    } else {
      UtilsHelpers.debugger("Approve is not available.");
      if (callback) callback(AppErrorCode.APPROVE_EMPLOYEE);
    }
  }

  public static getDifferentEmployees(
    prevEmployees: EmployeeNFT[],
    nextEmployees: EmployeeNFT[]
  ) {
    let newEmployeeNFTs: EmployeeNFT[] = [];

    if (prevEmployees.length < nextEmployees.length) {
      for (let i = 0; i < nextEmployees.length; i++) {
        let inPrevArray = false;

        for (let j = 0; j < prevEmployees.length; j++) {
          let prevNFT = prevEmployees[j];
          let nextNFT = nextEmployees[i];

          if (prevNFT.id === nextNFT.id) {
            inPrevArray = true;
            break;
          }
        }

        if (!inPrevArray) {
          newEmployeeNFTs.push(nextEmployees[i]);
        }
      }
    }

    return newEmployeeNFTs;
  }
}
