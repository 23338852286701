import { AppData } from "../types";

export interface CityNFTTemplate {
  id: number;
  points: number;
  lands: number;
  name: string;
  world: number;
  university: number;
  townHall: number;
  uri: string;
  appData: AppData;
}

export enum CityProperty {
  ID = "id",
  POINTS = "points",
}

export type CityProperties = {
  [key in CityProperty]: string | null;
};

export class CityNFT implements CityNFTTemplate {
  public uri: string;
  public id: number;
  public points: number;
  public lands: number;
  public name: string;
  public world: number;
  public university: number;
  public townHall: number;
  public appData: AppData;

  constructor(private _template: CityNFTTemplate) {
    this.id = this._template.id;
    this.appData = this._template.appData;
    this.points = Number(this._template.points);
    this.lands = Number(this._template.lands);
    this.world = Number(this._template.world);
    this.name = String(this._template.name);
    this.university = Number(this._template.university);
    this.townHall = Number(this._template.townHall);
    this.uri = this._template.uri || "";
  }

  static createWithContractTemplate(contractTemplate: { [id: string]: any }) {
    const city = contractTemplate[0];
    return new CityNFT({
      id: contractTemplate.id,
      lands: city.lands,
      points: city.factoryPoints,
      townHall: city.townHall,
      university: city.university,
      name: city.name,
      world: city.world,
      uri: contractTemplate[1],
      appData: contractTemplate.appData,
    });
  }

  get properties(): CityProperties {
    return {
      id: this.id.toString(),
      points: this.points.toString(),
    };
  }
}
