import { ContractBaseModel } from "../abstracts/contract";
import { appConfig, AppErrorCode } from "../app";
import { AbstractMiniEmployeeDeployer } from "../abstracts/miniEmployeeDeployer";

export class MiniEmployeeDeployer extends ContractBaseModel<AbstractMiniEmployeeDeployer> {
  getEmployeesData() {
    return this.contract.getEmployeesData().call(this.config);
  }

  getSpecialEmployeesData() {
    return this.contract.getSpecialEmployeesData().call(this.config);
  }

  getNecessaryXP(employee: number) {
    return this.contract.getNecessaryXP(employee).call(this.config);
  }

  getMiniEmployeeExpanded(id: number) {
    return this.contract.getMiniEmployeeExpanded(id).call(this.config);
  }

  mintSpecialEmployee(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintSpecialEmployee()
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  mintPayedEmployee(
    multiple: boolean,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mintPayedEmployee(multiple)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  upgradeEmployee(
    employee: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .upgradeEmployee(employee)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  mintWithStakingPoints(
    multiple: boolean,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mintWithStakingPoints(multiple)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  mergeTwoEmployees(
    men: number,
    woman: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mergeTwoEmployees(men, woman)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  mergeTwoEmployeesManyTimes(
    men: number,
    woman: number,
    times: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mergeTwoEmployeesManyTimes(men, woman, times)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }
}
