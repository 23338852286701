import { AbstractCitiesDeployer } from "../abstracts/citiesDeployer";
import { ContractBaseModel } from "../abstracts/contract";
import { appConfig, AppErrorCode } from "../app";

export class CitiesDeployer extends ContractBaseModel<AbstractCitiesDeployer> {
  pointsPerLand() {
    return this.contract.pointsPerLand().call(this.config);
  }

  creatorFee() {
    return this.contract.creatorFee().call(this.config);
  }

  playToEarnFee() {
    return this.contract.playToEarnFee().call(this.config);
  }

  getData() {
    return this.contract.getData().call(this.config);
  }

  mintCity(
    name: string,
    factories: number[],
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mintCity(name.toLocaleUpperCase(), factories)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  addFactoryPoints(
    city: number,
    factories: number[],
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .addFactoryPoints(city, factories)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  addUniversity(city: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addUniversity(city)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  addTownHall(city: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addTownHall(city)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  changeCityName(
    city: number,
    name: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .changeCityName(city, name)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  mergeCities(
    city: number,
    burned: number,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .mergeCities(city, burned)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }
}
