import { AbstractCityRelationsStorage } from "../abstracts/cityRelationsStorage";
import { ContractBaseModel } from "../abstracts/contract";

export class CityRelationsStorage extends ContractBaseModel<AbstractCityRelationsStorage> {
  getFactoryState(_factory: number) {
    return this.contract.getFactoryState(_factory).call(this.config);
  }

  getFactoryAdditions(_factory: number) {
    return this.contract.getFactoryAdditions(_factory).call(this.config);
  }

  getCustomerRelations(owner: string) {
    return this.contract.getCustomerRelations(owner).call(this.config);
  }

  getRelationMultiplicator(_city: number, _factory: number) {
    return this.contract
      .getRelationMultiplicator(_city, _factory)
      .call(this.config);
  }

  getFactoryAddition(_factory: number, _agregator: string) {
    return this.contract
      .getFactoryAddition(_factory, _agregator)
      .call(this.config);
  }

  getTotalCityRelations(city: number) {
    return this.contract.getTotalCityRelations(city).call(this.config);
  }
}
